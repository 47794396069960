.input-with-button {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 100%;
  //margin-bottom: 17px;
  &__inputError {
    font-weight: 500;
    font-size: 11px;
    color: var(--ui-color-red);
    line-height: 13px;
    width: 270px;
    position: absolute;
    display: flex;
    left: 350px;
    top: 35px;
    @media screen and(max-width:1260px) {
      left: auto;
      top: 67px;
    }
    padding: 0 10px;
  }
  .inputGrayError {
    font-weight: 500;
    font-size: 11px;
    color: var(--ui-color-gray-medium) !important;
    line-height: 13px;
    width: 270px;
    position: absolute;
    display: grid;
    left: 350px;
    top: 0px;
    padding: 0 10px;
  }
  &__input_container {
    display: flex;
    align-items: center;
    outline: none;
    color: inherit;
    width: 100%;
    background: #ffffff;
    border: 1px solid var(--ui-color-gray-pale2);
    box-sizing: border-box;
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 0px;
    max-width: 100%;
    padding: 10px 22px !important;
    padding-right: 90px !important;
  }
  &__inputDefaultWidth {
    width: 340px;
    @media screen and(max-width:400px) {
      width: 300px;
    }
    @media screen and(max-width:360px) {
      width: 260px;
    }
  }
  &__redStyle {
    border-color: var(--ui-color-red) !important;
    padding: 10px 22px !important;
  }
  &__input_class {
    border: 0;
    width: 100%;

    &:focus {
      outline-color: var(--ui-color-red) !important;
    }
  }
  &__valError {
    position: relative;
    width: 200px;
    height: 13px;
    padding-top: 2px;
    font-family: "roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.4px;
    margin-left: 10px;
    color: var(--ui-color-red);
    margin-bottom: 4px;
  }
  input {
    &[type="email"] {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
    &::-webkit-input-placeholder {
      color: var(--ui-color-gray-pale);
    }
    color: var(--ui-color-gray-dark1);
  }
  &__button_styles {
    position: absolute !important;
    top: 0px;
    right: 00px;
  }
  .small {
    text-align: center;
  }
}
@media screen and (max-width: 422px) {
  .inputError {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 6px;
    width: 100%;
  }
}
.input_disable {
  background-color: var(--ui-color-gray-pale1);
}
