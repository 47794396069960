.popup {
  /* background-color: var(--ui-color-white); */
  background-color: #fff;
  min-width: 120px;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 5px rgba(30, 30, 30, 0.1);
  padding: 15px;
  position: absolute;
  z-index: 90000 !important;
  height: auto;
  top: 10px;
  left: 0;
  transform: translateX(18%);
  overflow: hidden;
  transition: all 0.1s ease-in;
  transform-origin: 0 0;
  animation: open 0.1s;
   
}


@keyframes open {
  0% {
    transform: translateX(18%) scaleY(0.2);
  }

  100% {
    transform: translateX(18%) scaleY(1);
  }
}

.popup_title {
  color: var(--ui-color-gray-medium);
  text-transform: uppercase;
}

.menu_items {
  margin: 20px 0 5px 0;
  text-align: left;
}

.menu_items div {
  color: var(--ui-color-gray-medium);
  margin-top: 15px;
  text-transform: capitalize;
  font-size: 13px;
}

.menu_items_selected{
  color: var(--ui-color-primary-green-dark2)!important;
  font-weight: bold;
}

.menu_items div:hover,
.menu_items div:focus {
  color: var(--ui-color-gray-dark4);
}

@media screen and (max-width:600px) {
  .popup {
    left: -24px;
    /* min-width: 110px; */
     
  }
  .popup_title {
      font-size: 11px;
  }
  .menu_items div {
    font-size: 11px;
  }
}
@media screen and (max-width:400px) {
  .popup {
    left: -34px;
    min-width: 120px;
  }
  
}