.traffic-lights-container {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: -18px;
  display: flex;
  justify-content: space-between;
  @media only screen and(max-width:768px) {
    margin-left: 12px;
    margin-top: 20px;
  }

  .option-container {
    @media only screen and(max-width:768px) {
    }
  }

  .incre-decre-icons {
    margin-top: 20px;
    color: var(--ui-color-gray-dark3);
    display: flex;
    .no-of-points {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: var(--ui-color-gray-dark1);
      margin-left: 10px;
      margin-top: 4px;
    }
    .remove,
    .add {
      cursor: pointer;
      margin-left: 10px;
    }
  }
}

.traffic-light {
  cursor: pointer;
  margin-left: calc(50% / 2);
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background: var(--ui-color-gray-pale2);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  padding: 10px;

  @media only screen and(max-width:500px) {
    width: 60px;
    height: 60px;
  }

  .highlighter {
    height: 54px;
    width: 54px;
    border-radius: 50%;

    @media only screen and(max-width:500px) {
      height: 40px;
      width: 40px;
    }
  }
  .red {
    background-color: #f36f56;
  }
  .yellow {
    background-color: #fcb148;
  }
  .green {
    background-color: #63baa4;
  }

  .text {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: var(--ui-color-white);
    position: relative;
    top: 22px;

    @media only screen and(max-width:500px) {
      top: 16px;
      font-weight: 500;
      font-size: 8px;
    }
  }
}

.traffic-light.redActive {
  background-color: #ff9078;
}

.traffic-light.yellowActive {
  background-color: #ffc86a;
}

.traffic-light.grenActive {
  background-color: #70d8c3;
}
