.help-container {

  position: absolute;
  max-width: 850px;
  left: 400px;
  overflow: hidden;
  padding-bottom: 300px;

  &__parents {
    font-size: 12px;
    position: absolute;
    color: #ababab;
  }

  ul {
    list-style: initial;
    padding-left: 20px;
    margin-bottom: 6px;
    li {

    }
  }

  img {
    margin-bottom: 15px;
  }

  img, iframe {
    margin-top: 7px;
    box-shadow: 0 0 10px 0 rgba(128,128,128,0.2);
  }

  iframe {
    width: 800px;
    height: 480px;
  }

  @media screen and (max-width: 860px) {
    iframe {
      width: 400px;
      height: 260px;
    }
  }


  p {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 2em;
  }

  a,
  a:hover,
  a:active,
  a:visited {
    color: dodgerblue;
    text-decoration: underline;
    cursor: pointer;
  }

  &__caption {
    font-size: 14px;
    position: relative;
    color: gray;
    font-style: italic;
    padding-bottom: 15px;
  }

  &__chapter {

    position: relative;
    padding-left: 20px;
    padding-bottom: 12px;
    max-width: calc(100% - 20px);

    h2, h3 {
      padding-top: 20px;
      padding-bottom: 5px;
      font-style: normal;
      font-weight: 600;
    }

    h2 {
      font-size: 32px;
      color: #1367b7;
    }

    h3 {
      font-size: 24px;
      padding-bottom: 0;
      color: dodgerblue;
    }

    .sub-sub {
      //font-size: 18px;
      //color: #61adfe;
      font-size: 18px;
      font-weight: 400;
    }

    &__section {

      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;

      &__content {
        padding-bottom: 20px;
      }

    }

  }

}


@media screen and (max-width: 1268px) {
  .help-container {
    position: relative;
    left: auto;
  }
}
