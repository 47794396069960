.notata-menu {

  &__main {
    max-width: 206px;
    position: relative;
    z-index: 2000;
    @media screen and (max-width: 768px) {
      position: absolute;
      width: 0;
      font-weight: 700;
    }
  }
  &__container {
    position: relative;
    user-select: none;
    background: white;
    overflow-y: scroll;
    overflow: hidden;
    width: 206px;
    box-shadow: 0px 3px 12px rgba(41, 70, 103, 0.2);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    left: 0;
    top: 0px;
    height: 100vh;
    z-index: 100773;
    transition: left 450ms ease-in-out;
    height: 100%;
    @media screen and (max-width: 768px) {
      position: fixed;
      left: -290px;
      width: 240px;
      transition: all;
      transition-duration: 0.5s;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(214, 214, 214);
      border-radius: 10px;
    }
    & a {
      position: relative;
      text-decoration: none;
      color: var(--ui-color-gray-medium);
    }
    & .profile_item {
      display: none;
      @media screen and (max-width: 769px) {
        display: block !important;
        .name {
          width: 25px;
          height: 25px;
          margin-top: -1px;
          text-align: center;
          padding-top: 3px;
          justify-content: space-around;
          text-transform: capitalize;
          font-size: 14px;
          border-radius: 50%;
          overflow: hidden;
          color: white;
          background: linear-gradient(
            152.3deg,
            var(--ui-color-primary-orange-medium) 9.47%,
            var(--ui-color-primary-orange-dark1) 93.11%
          );
        }
      }
    }
    & .logo {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 24px;
      top: 18px;
      border-radius: 50%;
      background: linear-gradient(152.3deg, #ffb28c 9.47%, #ff8c78 93.11%);
      @media screen and (max-width: 769px) {
        width: 30px;
        height: 30px;
      }
      &__container {
        @media screen and (max-width: 769px) {
          width: 80%;
          height: 79px;
          margin: auto;
          border-bottom: 1px solid #bfbfbf;
        }
      }
    }
    & .brand {
      position: absolute;
      width: 72px;
      height: 23px;
      left: 77px;
      top: 27px;
      font-style: normal;
      font-weight: 300;
      font-size: 23px;
      line-height: 23px;
      letter-spacing: 0.08em;
      text-transform: lowercase;
      color: var(--ui-color-gray-medium);
    }
    & .list {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: var(--ui-color-gray-medium);
    }
    & .menu_lists {
      padding-top: 174px;
      width: 80%;
      margin: auto;
      transition: left 0.35s ease-in-out;
      @media screen and (max-width: 769px) {
        padding-top: 108px !important;
      }
    }
    & .menu_list {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      margin-bottom: 35px;
      position: relative;
      width: 100%;
      & a {
        display: flex;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    & i {
      color: #bfbfbf;
    }
    & .icons {
      color: #bfbfbf;
      margin-right: 15px;
      height: 25px;
      margin-top: -3px;
      & img {
        width: 25px;
        height: 25px;
        margin-top: -8px;
        display: inline;
        padding-bottom: 2px;
      }
      & i {
        font-size: 18px;
      }
    }
  }
  &__containerInner {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: 206px;
    transition: all 400ms;
    @media screen and (max-width: 769px) {
      position: relative !important;
    }
  }
  &__container1 {
    position: relative;
    user-select: none;
    background: #fafafa;
    overflow-y: scroll;
    overflow: hidden;
    width: 79px;
    left: 0;
    top: 0px;
    height: 100vh;
    z-index: 1003;
    transition: all 450ms;
    & .lasthr {
      position: absolute;
      height: 1px;
      background-color: #d7d7d7;
      width: 100%;
      top: -30px;
    }
    & i {
      color: #bfbfbf;
    }
    &__m {

      position: relative;


      @media screen and (max-width: 769px) {
        width: 80%;
        height: 79px;
        margin: auto;
        margin-bottom: -77px;
        margin-top: 0px;
        border-bottom: 1px solid #bfbfbf;
      }
      & .logo {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 18px;
        top: 18px;
        border-radius: 50%;
        background: linear-gradient(152.3deg, #ffb28c 9.47%, #ff8c78 93.11%);
      }
    }
    & .brand,
    & .list {
      display: none;
      transition: all 450ms;
    }
    & .menu_list {
      font-style: normal;
      font-weight: normal;
      margin-bottom: 35px;
      position: relative;
      width: 100%;
      text-align: center;
    }
    & .menu_lists {
      padding-top: 174px;
      transition: all 450ms;
      width: 80px;
      text-align: center;
    }
    & .icons {
      color: #bfbfbf;
      width: 100%;
      text-align: center;
      & img {
        padding-bottom: 2px;
      }
      & i {
        font-size: 20px;
      }
    }
  }
  &__container1 &__containerInner {
    height: 100vh;
    position: fixed;
    left: 0px !important;
    top: 0;
    width: 80px;
    background-color: #fafafa;

    
    .menu_listSettings {
      border-top: 1px solid #bfbfbf;
      width: 50%;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(214, 214, 214);
      border-radius: 10px;
    }
  }
  &__active {
    left: 0px !important;
  }
}
.mobile {
  &__toggler {
    position: fixed;
    top: 13px;
    left: 30px;
    display: none;
    z-index: 8;
    font-size: 21px;
    @media screen and (max-width: 769px) {
      display: block;
    }
  }
  &__togglerInSidebar {
    position: absolute;
    right: -10px;
    top: 27px;
    display: none;
    i {
      font-weight: 700;
      font-size: 17px;
    }
    @media screen and (max-width: 769px) {
      display: block;
    }
  }
}
.menu_last {
  margin-top: 100px;
}
@media screen and (max-width: 769px) {
  .desktop_Collapse {
    display: none;
  }
}
.active {
  &__open {
    & .icons {
      color: #ff8166 !important;
      .image {
        #itm_1 {
          fill: #ff8166;
        }
      }
    }
    & .list {
      color: #ff8166 !important;
      font-weight: 600 !important;
    }
  }
  &__close {
    & i {
      color: var(--ui-color-gray-medium) !important;
      &::after {
        content: "";
        padding-top: 10px;
        display: block;
        margin: 0 auto;
        width: 26px;
        border-radius: 2px;
        border-bottom: 4px solid #ff8166 !important;
      }
    }
    .image {
      border-radius: 2px;
      border-bottom: 4px #ff8775 solid;
      padding-bottom: 7px !important;
      #itm_1 {
        fill: #969ba3;
      }
    }
    & img {
      border-radius: 2px;
      border-bottom: 2px #ff8775 solid;
      padding-bottom: 7px !important;
    }
  }
}
.left_icon,
.right_icon {
  font-size: 20px;
  cursor: pointer;
}
.right_icon i {
  position: absolute;
  top: 95px;
  left: 34px;
  color: var(--ui-color-gray-medium);
  font-weight: 600;
}
.left_icon i {
  position: absolute;
  top: 95px;
  left: 172px;
  color: var(--ui-color-gray-medium);
  font-weight: 600;
  transition: all 450ms;
}
.menu_list--settings {
  border-top: 1px solid #bfbfbf !important;
  padding-top: 39px;
  margin: 0 auto;
  margin-top: 40px;
  @media screen and (max-width: 769px) {
    margin-top: 0 !important;
    margin-bottom: 32px !important;
  }
  & i {
    margin-top: 5px;
  }
}
@media (min-width: 770px) {
  // .floating-button,
  .floating-button--hide {
    display: none !important;
  }
  .mobile-container--hide {
    left: 0px !important;
  }
}
.floating-button {
  display: block;

  &--hide {
    display: none;
  }
}
.mobile-container {
  left: 0px;

  &--hide {
    left: -290px;
  }
}
@media (max-width: 768px) {
  .floating-button-display {
    display: none;
  }
  .floating-button-hide {
    display: block;
    margin-left: -18px;
  }
}
