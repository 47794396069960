.login-container {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 17px;
  margin-left: -4px;
  margin-right: -4px;

  &__inputError {
    font-weight: 500;
    font-size: 11px;
    color: var(--ui-color-red);
    line-height: 13px;
    width: 270px;
    position: absolute;
    display: flex;
    left: 350px;
    top: 35px;
    @media screen and(max-width:1260px) {
      left: auto;
      top: 67px;
    }
    padding: 0 10px;
    i {
      color: var(--ui-color-red) !important;
      margin-top: 6px;
      margin-right: 5px;
      @media screen and(max-width:1260px) {
        display: none;
      }
    }
  }
  .inputGrayError {
    font-weight: 500;
    font-size: 11px;
    color: var(--ui-color-gray-medium) !important;
    line-height: 13px;
    width: 270px;
    position: absolute;
    display: grid;
    left: 350px;
    top: 0px;
    padding: 0 10px;
  }
  &__input_label {
    color: var(--ui-color-gray-medium);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-left: 4px;
    margin-bottom: 7px;
    display: block;
    input {
      color: var(--ui-color-gray-dark1) !important;
      max-width: 100%;
      color: var(--color-red);
    }
  }
  &__input_container {
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    font-size: inherit;
    color: inherit;
    width: 100%;
    background: #ffffff;
    border: 1px solid var(--ui-color-gray-pale2);
    box-sizing: border-box;
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 22px !important;
    margin-top: 0px;
    max-width: 100%;
  }
  &__inputDefaultWidth {
    width: 340px;
    @media screen and(max-width:400px) {
      width: 300px;
    }
    @media screen and(max-width:360px) {
      width: 260px;
    }
  }
  &__redStyle {
    border-color: var(--ui-color-red) !important;
    padding: 10px 22px !important;
  }
  &__input_class {
    border: 0;
    width: 100%;
    &:focus {
      outline-color: var(--ui-color-red) !important;
    }
  }
  &__valError {
    position: relative;
    width: 200px;
    height: 13px;
    padding-top: 2px;
    font-family: "roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.4px;
    margin-left: 10px;
    color: var(--ui-color-red);
    margin-bottom: 4px;
  }
  input {
    &[type="text"] {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
    &[type="url"] {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
    &::-webkit-input-placeholder {
      color: var(--ui-color-gray-pale);
    }
  }
  textarea::placeholder {
    color: var(--ui-color-gray-dark8) !important;
    background-color: var(--ui-color-red);
  }
  .i-color {
    color: var(--ui-color-red);
    background-color: var(--ui-color-red);
  }
  .red-color {
    color: var(--ui-color-red);
  }
}
@media screen and (max-width: 422px) {
  .inputError {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 6px;
    width: 100%;
  }
}

//.login-container {
//  span {
//    margin-top: 5px !important;
//  }
//}

.input_pass {
  font-weight: 900;
  color: var(--ui-color-gray-medium) !important;
  letter-spacing: 0.4rem;
}

.input_disable {
  background-color: var(--ui-color-gray-pale1);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
