.group-individual-container {
  .fade-me {
    color: #dddddd !important;
  }
  .border {
    border-bottom: 1px solid var(--ui-color-gray-pale2);
    max-width: 99%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .shared_border {
    border-bottom: 1px solid var(--ui-color-gray-pale2);
    @media (min-width: 991px) and (max-width: 1200px) {
      max-width: 86%;
    }
  }
  .share-container {
    padding-bottom: 39px;
    padding-top: 35px;
    @media (min-width: 1500px) {
    }
    @media (max-width: 1360px) {
      margin-right: 0;
    }
    @media (max-width: 1420px) {
      width: 100%;
    }
    @media (max-width: 1360px) {
      width: 96%;
    }
    @media (max-width: 1290px) {
      width: 94%;
    }
    @media (max-width: 1180px) {
      width: 88%;
    }
    @media (max-width: 1140px) {
      width: 86%;
    }
    @media (max-width: 1075px) {
      width: 85%;
    }
    @media (max-width: 1060px) {
      width: 85%;
    }
    @media (max-width: 992px) {
      width: 81%;
    }
    @media (max-width: 991px) {
      width: 98%;
    }
    @media (max-width: 881px) {
      width: 87%;
    }
    @media (max-width: 885px) {
      width: 95%;
    }

    @media (max-width: 850px) {
      width: 90%;
    }
    @media (max-width: 810px) {
      width: 89%;
    }
    @media (max-width: 780px) {
      width: 86%;
    }
    @media (max-width: 768px) {
      width: 99%;
    }

    .shared-date-time {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: var(--ui-color-gray-dark1);
      margin-top: 5px;
    }
    .last-opepned {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      color: var(--ui-color-primary-green-dark3);
      margin-top: 5px;
    }
    .shared-user-name {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: var(--ui-color-gray-dark1);
      display: flex;
      word-break: break-word;
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
    .shared-user-name-mobile {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: var(--ui-color-gray-dark1);
      @media screen and (min-width: 500px) {
        display: none;
      }
    }
    .evaluation-template {
      &_color {
        color: #dddddd;
      }
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      width: 110%;
      line-height: 18px;
      color: var(--ui-color-gray-dark1);
      @media only screen and(max-width:768px) {
        padding-left: 0px !important;
        margin-top: 10px;
      }
      &__inrequest-uncomplete {
        font-family: "Proxima Nova";
        font-style: normal;
        font-size: 10px;
        line-height: 12px;
        color: red;
        margin-top: 5px;
      }
      &__inrequest-complete {
        font-family: "Proxima Nova";
        font-style: normal;
        font-size: 10px;
        line-height: 12px;
        color: var(--ui-color-primary-green-dark3);
        margin-top: 5px;
      }
    }
    .share {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: var(--ui-color-gray-dark1);
      @media only screen and(max-width:768px) {
        margin-left: 5px;
        margin-top: 10px;
      }
    }
    .sharing-options {
      display: inline-block;
      justify-content: flex-end;
    }
    .sharing-options button {
      @media only screen and(max-width:768px) {
        margin-top: 10px;
      }
      margin-bottom: 5px;
    }
  }
  .group-container {
    padding-bottom: 39px;
    margin-top: 35px;

    .evaluations {
      @media only screen and(min-width:768px) {
        border-left: 1px solid var(--ui-color-gray-pale2);
        padding-left: 25px;
      }
    }
    .group-name {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: var(--ui-color-gray-dark1);
      display: flex;
      word-break: break-all;
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
    .group-name-mobile {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: var(--ui-color-gray-dark1);
      @media screen and (min-width: 500px) {
        display: none;
      }
    }
    .members {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      margin-top: 5px;
      color: var(--ui-color-gray-medium);
    }
    .remove-from-group {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      margin-top: 5px;
      color: var(--color-primary);
      cursor: pointer;
    }
    .evaluation-template {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: var(--ui-color-gray-dark1);
    }
    .templates {
      margin-top: 5px;
      .template {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        color: var(--ui-color-gray-dark1);
      }
    }
    .share {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: var(--ui-color-gray-dark1);
      @media only screen and(max-width:768px) {
        margin-left: 5px;
      }
    }
    .subjective-score {
      @media only screen and(max-width:768px) {
        margin-top: 15px;
        padding-left: 0px;
      }
    }
    .sharing-options {
      display: flex;
      justify-content: flex-end;
      @media (max-width: 766px) {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
      }
      button {
        background: var(--ui-color-primary-green-dark2);
        height: 30px;
        .material-icons {
          font-size: 12px !important;
          line-height: 15px;
        }
        @media (min-width: 991px) and (max-width: 1113px) {
          font-size: 10px !important;
        }
        @media (min-width: 768px) and (max-width: 781px) {
          font-size: 11px !important;
        }
        @media (min-width: 1010px) and (max-width: 1031px) {
          font-size: 9px !important;
        }
      }
    }
  }
  .see-full-list {
    margin-top: 15px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    padding-right: 55px;
    color: var(--ui-color-primary-green-dark4);
    i {
      padding-left: 10px;
    }
  }
  .create-new-group {
    text-align: left;
    @media only screen and(min-width:768px) {
      margin-top: 16px;
    }
    button {
      background: var(--ui-color-primary-green-dark2);
      height: 30px;
      margin-bottom: 15px;
      .MuiButton-label,
      .material-icons {
        font-size: 12px !important;
        line-height: 15px;
      }
      @media only screen and(max-width:768px) {
        margin-top: 25px;
      }
    }
  }
}
