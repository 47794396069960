.settingsTags-container1 {
  display: flex;
  justify-content: center;
  min-height: 90vh;
  &__card {
    max-width: 700px;
  }

  &__tag {
    padding-bottom: 10px;
    &__heading {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 17px;
      color: var(--ui-color-gray-dark1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        cursor: pointer;
        color: var(--ui-color-gray-pale2);
        &:hover {
          color: var(--ui-color-gray-dark5) !important;
        }
      }
      &__dropdown {
        position: absolute;
        background: var(--ui-color-white);
        box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
        border-radius: 5px;
        text-align: left;
        text-align: initial;
        margin-left: 33px;
        margin-top: 0px;
        cursor: context-menu;
      }
    }
    &__available-tags {
      margin-top: 20px;
      &__tag-name {
        margin-top: 5px;
        display: inline-block;
        background: var(--ui-color-gray-dark1);
        color: var(--ui-color-white);
        padding: 5px 10px;
        border-radius: 12px;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        margin-right: 10px;
      }
    }
    &__add-new-tag {
      margin-top: 25px;
      .heading {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-transform: capitalize;
        color: var(--ui-color-gray-medium);
      }
      &__input {
        margin-top: 10px;
        input {
          background: var(--ui-color-white);
          border: 1px solid var(--ui-color-gray-pale2);
          box-sizing: border-box;
          border-radius: 20px;
          height: 30px;
          padding-left: 15px;
          width: 35%;
        }
        i {
          color: var(--ui-color-primary-green-dark2);
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
  &__marginToptext {
    margin-top: 20px;
  }
}
