.mb1 { margin-bottom: 8px }

.m2  { margin:        16px }
.mt2 { margin-top:    16px }
.mb2 { margin-bottom: 16px }
.ml2 { margin-left: 16px }

.mt3 { margin-top:    24px }
.mb3 { margin-bottom: 24px }

.mt4 { margin-top:    32px }
