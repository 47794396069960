.highest-score {
        font-family: "Proxima Nova";
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: var(--ui-color-primary-green-dark4);
        margin-left: 25px;
        margin-top: -15px;
        @media only screen and (min-width: 768px) {
          position: absolute;
          top: 30px;
          left: 125px;
          margin-left: 0px;
          margin-top: 0px;
        }
        .highest {
          font-weight: normal;
          font-size: 10px;
          line-height: 10px;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.4);
        }
      }
.lowest-score {
    font-family: "Proxima Nova";
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--ui-color-primary-orange-dark1);
    margin-left: 5px;
    margin-top: 5px;
    @media only screen and (min-width: 768px) {
        position: absolute;
        top: 56px;
        left: 125px;
        margin-left: 0px;
        margin-top: 0px;
    }
    .lowest {
        font-weight: normal;
        font-size: 10px;
        line-height: 10px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.4);
    }
}