// GroupPage
.groups-container {

  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 1100px;
  margin: auto;

  &__list {

    &__left-icons {
      padding-right: 0px !important;
      padding-left: 7px !important;
      text-align: right;
    }

    &__group-name {
      color: var(--ui-color-gray-dark1);
      overflow-wrap: anywhere;
    }

    &__group-members {
      text-align: right;
      min-width: 150px;
      @media screen and (max-width: 800px) {
        display: none;
      }
    }

    &__group-startups {
      text-align: right;
      min-width: 150px;
      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    &__group-settings-icon {

    }

  }

  &__no-group {
    color: #767c84;
    margin-bottom: 3%;
    padding-left: 5px;
    &__header {
      font-size: 18px;
      padding-bottom: 15px;
    }
    &__body {
      font-size: 14px;
    }
  }

  &__separator {
    border-bottom: 1px solid var(--ui-color-gray-pale1);
    width: 90%;
    margin-left: 5%;
    padding-top: 0px;
  }

  .card {
    padding-top: 10px;
    padding: 30px;
    @media only screen and(min-width:500px) and (max-width: 768px) {
      min-width: 450px;
    }
    @media only screen and(max-width:500px) {
      min-width: 330px;
    }
    @media only screen and(max-width:350px) {
      min-width: 305px;
    }

    .MuiTabs-root {
      position: relative;
      @media only screen and(min-width:768px) {
        top: -40px;
        margin-left: auto;
        width: 330px;
      }
      @media only screen and(max-width:768px) {
        top: 20px;
        margin-left: calc(calc(100% - 223px) / 2);
        width: 223px;
      }
    }
    .MuiButtonBase-root {
      font-family: "Proxima Nova";
      font-style: normal;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--ui-color-gray-medium);
    }
    .Mui-selected {
      font-weight: bolder;
      font-family: "proxima_nova_altbold";
    }
    .MuiTabs-indicator {
      border-bottom: 4px solid var(--ui-color-gray-medium);
      border-radius: 2px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
  .separator {
    padding-top: 20px;
    @media only screen and(max-width:768px) {
      padding-top: 10px;
    }
  }
  .sortby-dropdown {
    position: absolute;
    background: var(--ui-color-white);
    box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
    border-radius: 5px;
    padding: 4px 17px;
    z-index: 999;
    margin-top: 7px;
    text-align: initial;
    &__item {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      padding-top: 8px;
      padding-bottom: 8px;
      color: var(--ui-color-gray-dark1);
      cursor: pointer;
    }
  }
  .sort-by {
    @media only screen and(max-width:768px) {
      text-align: right;
      margin-top: 60px !important;
    }
    margin-top: 30px;
    margin-left: 25px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: var(--ui-color-primary-green-dark2);
    .sort-by-dropdown-icon {
      margin-left: 5px;
      cursor: pointer;
    }
    .fa-arrow-up {
      margin-left: 15px;
      margin-right: 2px;
      color: var(--ui-color-gray-medium);
      cursor: pointer;
    }
    .fa-arrow-down {
      color: var(--ui-color-gray-medium);
      cursor: pointer;
    }
  }
  .create-group-container {
    margin-bottom: 20px;
    text-align: right;
    .large {
      @media (max-width: 360px) {
        width: 150px;
        height: 30px;
        border-radius: 16px !important;
        font-size: 10px !important;
      }
    }
  }




  .data {
    padding: 15px 50px 15px 35px;
    position: relative;
    &__members,
    &__startups {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: var(--ui-color-gray-medium);
    }
    @media only screen and(max-width:900px) {
      &__members,
      &__startups {
        display: none;
      }
    }

    &__startups {
      @media only screen and(max-width: 768px) {
        margin-top: -15px;
      }
    }
    &__browse {
      color: var(--ui-color-gray-dark3);
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 15px;
      &:hover {
        color: #000;
      }
      .selected {
        color: #000;
      }
      &__dropdown {
        position: absolute;
        z-index: 999;
        text-align: left;
        text-align: initial;
        margin-top: -1px;
        margin-left: 32px;
        @media only screen and(max-width:769px) {
          margin-left: 41px;
        }
      }
    }

    &__name {
      a {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 24px;
        text-decoration: underline;
        color: var(--ui-color-gray-dark1);
        cursor: pointer;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
      i {
        position: absolute;
        color: var(--ui-color-gray-dark3);
        font-size: 18px;
        left: -14px;

        top: 4px;
        &__admin {
          color: red !important;
        }
        &__member {
          color: green;
        }
      }
    }
  }
  .data:nth-child(odd) {
    background: rgba(223, 233, 245, 0.2);
  }
}
