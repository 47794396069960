// GroupSettings Modal
.create-new-group-modal-container {
  .MuiButtonBase-root {
    font-family: "Proxima Nova";
    font-style: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--ui-color-gray-medium);
  }
  .Mui-selected {
    font-weight: bolder;
    font-family: "proxima_nova_altbold";
  }
  .MuiTabs-indicator {
    border-bottom: 4px solid var(--ui-color-gray-medium);
    border-radius: 2px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .MuiTab-root {
    min-width: 80px;
  }
}
.genral-contianer {
  margin-top: 30px;
  margin-left: 12px !important;
  margin-right: 12px !important;
  .group-name {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    margin-left: 0.15rem;
    color: var(--ui-color-gray-medium);
  }
  .text {
    margin-top: 10px;
    .text-box {
      height: 32px;
      @media only screen and(max-width:768px) {
        height: 30px;
      }
    }
  }
  .description {
    margin-top: 30px;
  }
  textarea {
    background: white;
    border: 1px solid var(--ui-color-gray-pale2);
    box-sizing: border-box;
    border-radius: 20px;
    margin: 10px 0px 0px;
    width: 100%;
    height: 143px;
    padding: 10px;
    resize: none;
  }
}
.settings-containers {
  padding-left: 5px;
  padding-right: 2px;
  .header {
    padding-top: 50px;
    padding-left: 10px;
    padding-bottom: 10px;

    .material-icons {
      font-size: 30px !important;
      color: var(--ui-color-gray-dark7);
      top: 8px;
      font-weight: bold;
      position: relative;
    }
    .heading {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 19px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-medium);
      margin-left: 15px;
    }
  }
  .options {
    margin-left: 55px;
    margin-right: 55px;
    .option {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 35px;
      margin-top: 15px;
      color: var(--ui-color-gray-medium);
      i {
        margin-left: 10px;
      }

      .radio-button-container {
        margin-left: -2px;
        margin-bottom: 0px;
      }

      .radio-button-label {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 15px;
        margin-top: 5px;
        color: var(--ui-color-gray-medium);
      }
    }
  }
}
.startup-container {
  .add-startups {
    @media only screen and(max-width:768px) {
      height: 90px;
    }
    background-color: #c1c1c130;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: 10px;
    padding: 10px;
    padding-left: 27px;
    padding-right: 27px;
    .add-text {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-dark1);
      padding-top: 10px;
      padding-left: 20px;
    }
    .drop-down {
      width: 161px !important;
      margin-left: 25px;
      @media only screen and(max-width:768px) {
        margin-left: 0px;
        margin-top: 5px;
      }
    }
  }
  .search {
    margin-left: 19px;
    margin-right: 19px;
    .material-icons {
      position: absolute;
      left: 7px;
      color: var(--ui-color-gray-pale2);
      top: 15px;
    }
    .search-box {
      height: 40px;
      border: 1px solid var(--ui-color-gray-pale2);
      box-sizing: border-box;
      border-radius: 20px;
      width: 100%;
      max-width: 340px;
      padding-left: 30px;
      margin-left: -10px;
      margin-bottom: 5px;
    }
  }
  .startup-list {
    margin-left: 15px;
    @media only screen and(max-width:378px) {
      margin-left: 0px;
    }
    margin-bottom: 30px;
    .startup {
      margin-top: 15px;
      .name,
      .email {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        color: var(--ui-color-gray-dark1);
        .fa-times {
          background-color: var(--ui-color-primary-orange-dark1);
          border-radius: 50%;
          height: 18px;
          width: 18px;
          text-align: center;
          font-size: 10px;
          padding-top: 4px;
          color: var(--ui-color-white);
          margin-right: 6px;
        }
      }
      .email {
        text-decoration: none;
        font-size: 12px;
        line-height: 15px;
      }
      .button {
        height: 18px;
        width: 62px;
        background: var(--ui-color-primary-green-dark2);
        border-radius: 12px;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: var(--ui-color-white);
        cursor: pointer;
      }
      .invite-button {
        width: 70px;
      }
    }
  }
}
.main_content {
  overflow-y: inherit !important;
}

//// GroupSettings Modals
//.create-new-group-modal-container {
//  .MuiButtonBase-root {
//    font-family: "Proxima Nova";
//    font-style: normal;
//    font-size: 10px;
//    line-height: 16px;
//    letter-spacing: 0.08em;
//    text-transform: uppercase;
//    color: var(--ui-color-gray-medium);
//  }
//  .Mui-selected {
//    font-weight: bolder;
//    font-family: "proxima_nova_altbold";
//  }
//  .MuiTabs-indicator {
//    border-bottom: 4px solid var(--ui-color-gray-medium);
//    border-radius: 2px;
//    transform: matrix(-1, 0, 0, 1, 0, 0);
//  }
//  .MuiTab-root {
//    min-width: 80px;
//  }
//}
//.genral-contianer {
//  margin-top: 30px;
//  margin-left: 12px !important;
//  margin-right: 12px !important;
//  .group-name {
//    font-family: "Proxima Nova";
//    font-style: normal;
//    font-weight: 600;
//    font-size: 16px;
//    line-height: 19px;
//    text-transform: capitalize;
//    margin-left: 0.15rem;
//    color: var(--ui-color-gray-medium);
//  }
//  .text {
//    margin-top: 10px;
//    .text-box {
//      height: 32px;
//      @media only screen and(max-width:768px) {
//        height: 30px;
//      }
//    }
//  }
//  .description {
//    margin-top: 30px;
//  }
//  textarea {
//    background: white;
//    border: 1px solid var(--ui-color-gray-pale2);
//    box-sizing: border-box;
//    border-radius: 20px;
//    margin: 10px 0px 0px;
//    width: 100%;
//    height: 143px;
//    padding: 10px;
//    resize: none;
//  }
//}
//.settings-containers {
//  padding-left: 5px;
//  padding-right: 2px;
//  .header {
//    padding-top: 50px;
//    padding-left: 10px;
//    padding-bottom: 10px;
//
//    .material-icons {
//      font-size: 30px !important;
//      color: var(--ui-color-gray-dark7);
//      top: 8px;
//      font-weight: bold;
//      position: relative;
//    }
//
//    .heading {
//      font-family: "Proxima Nova";
//      font-style: normal;
//      font-weight: bold;
//      font-size: 18px;
//      line-height: 19px;
//      letter-spacing: 0.03em;
//      color: var(--ui-color-gray-medium);
//      margin-left: 15px;
//    }
//  }
//  .options {
//    margin-left: 55px;
//    margin-right: 55px;
//    .option {
//      font-family: "Proxima Nova";
//      font-style: normal;
//      font-weight: normal;
//      font-size: 16px;
//      line-height: 35px;
//      margin-top: 15px;
//      color: var(--ui-color-gray-medium);
//      i {
//        margin-left: 10px;
//      }
//
//      .radio-button-container {
//        margin-left: -2px;
//        margin-bottom: 0px;
//      }
//
//      .radio-button-label {
//        font-family: "Proxima Nova";
//        font-style: normal;
//        font-weight: 600;
//        font-size: 16px;
//        line-height: 15px;
//        margin-top: 5px;
//        color: var(--ui-color-gray-medium);
//      }
//    }
//  }
//}
//.startup-container {
//  .add-startups {
//    @media only screen and(max-width:768px) {
//      height: 90px;
//    }
//    background-color: #c1c1c130;
//    margin-left: -30px;
//    margin-right: -30px;
//    margin-top: 10px;
//    padding: 10px;
//    padding-left: 27px;
//    padding-right: 27px;
//    .add-text {
//      font-family: "Proxima Nova";
//      font-style: normal;
//      font-weight: 600;
//      font-size: 14px;
//      line-height: 17px;
//      letter-spacing: 0.03em;
//      color: var(--ui-color-gray-dark1);
//      padding-top: 10px;
//      padding-left: 20px;
//    }
//    .drop-down {
//      width: 161px !important;
//      margin-left: 25px;
//      @media only screen and(max-width:768px) {
//        margin-left: 0px;
//        margin-top: 5px;
//      }
//    }
//  }
//  .search {
//    margin-left: 19px;
//    margin-right: 19px;
//    .material-icons {
//      position: absolute;
//      left: 7px;
//      color: var(--ui-color-gray-pale2);
//      top: 15px;
//    }
//    .search-box {
//      height: 40px;
//      border: 1px solid var(--ui-color-gray-pale2);
//      box-sizing: border-box;
//      border-radius: 20px;
//      width: 100%;
//      max-width: 340px;
//      padding-left: 30px;
//      margin-left: -10px;
//      margin-bottom: 5px;
//    }
//  }
//  .startup-list {
//    margin-left: 15px;
//    @media only screen and(max-width:378px) {
//      margin-left: 0px;
//    }
//    margin-bottom: 30px;
//    .startup {
//      margin-top: 15px;
//      .name,
//      .email {
//        font-family: "Proxima Nova";
//        font-style: normal;
//        font-weight: normal;
//        font-size: 14px;
//        line-height: 17px;
//        text-decoration-line: underline;
//        color: var(--ui-color-gray-dark1);
//        .fa-times {
//          background-color: var(--ui-color-primary-orange-dark1);
//          border-radius: 50%;
//          height: 18px;
//          width: 18px;
//          text-align: center;
//          font-size: 10px;
//          padding-top: 4px;
//          color: var(--ui-color-white);
//          margin-right: 6px;
//        }
//      }
//      .email {
//        text-decoration: none;
//        font-size: 12px;
//        line-height: 15px;
//      }
//      .button {
//        height: 18px;
//        width: 62px;
//        background: var(--ui-color-primary-green-dark2);
//        border-radius: 12px;
//        font-family: "Proxima Nova";
//        font-style: normal;
//        font-weight: bold;
//        font-size: 10px;
//        line-height: 12px;
//        display: flex;
//        align-items: center;
//        text-align: center;
//        letter-spacing: 0.03em;
//        text-transform: uppercase;
//        color: var(--ui-color-white);
//        cursor: pointer;
//      }
//      .invite-button {
//        width: 70px;
//      }
//    }
//  }
//}
//.main_content {
//  overflow-y: inherit !important;
//}
