.content-container {
  display: flex;
  flex-direction: column;
}

.buttons-container {
  display: flex;
  align-items: center;
}

.btn {
  margin-bottom: 12px;
}

.heading {
  &__h4 {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__h5 {
    font-size: 12px;
    color: var(--ui-color-gray-dark3);
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}

.component-container {
  margin-bottom: 20px;
}

.sub-container {
  display: flex;
  justify-content: space-between;
}
