.overview-container {
  &__subjective-scores {
    &__submission {
      padding-top: 5px;
      padding-bottom: 5px;
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      &:nth-child(2n) {
        background-color: rgba(223, 233, 245, 0.2);
      }
    }
  }

  .overview_bg_color {
    display: flex;
  }
  .score-name {
    font-family: "Proxima Nova";
    font-size: 16px;
    line-height: 18px;
    color: var(--ui-color-gray-medium);
    padding-left: 0px !important;
    padding-left: 15px !important;
  }
  .score_in_mobile {
    @media (max-width: 450px) {
      flex: 100%;
    }
  }
  .submissions {
    font-family: "Proxima Nova";
    font-size: 14px;
    line-height: 17px;
    color: var(--ui-color-gray-medium);
    padding-left: 0px !important;
    @media (max-width: 450px) {
      visibility: hidden;
    }
  }
  .scorePer {
    font-family: "Proxima Nova";
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: var(--ui-color-gray-medium);
    text-align: right;
    padding: 0;
    position: relative;
    padding-right: 4px;
  }
  .score-content {
    margin-right: 15px;
  }
}
