.checkbox,
.radioButton {
  margin-left: -7px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  cursor: pointer;

  input {
    height: 30px;
    width: 50px;
  }
  span {
    font-family: "Proxima Nova";
    font-size: 15px;
    line-height: 30px;
    margin-left: 5px;
  }
}
/* .checkbox1 {
  margin-left: 0px !important;
  margin-right: 0px !important;
} */

.checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
}


.textbox {
  input,
  textarea {
    background: var(--ui-color-white);
    border: 1px solid var(--ui-color-gray-pale2);
    box-sizing: border-box;
    border-radius: 8px;
    height: 120px;
    max-width: 280px;
    min-width: 100%;
    padding: 0 10px;
    font-family: "Proxima Nova" !important;
    font-size: 14px;
    line-height: 17px;
  }
  textarea {
    height: 120px;
    padding-top: 10px;
  }
}

.inputWrapper {
  position: relative;
  display: grid;
  align-items: center;
  width: 100%;
  //max-width: 400px;
  grid-template-columns: 1fr max-content;
}

.addIcon {
  display: flex;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
  margin-top: 2px;
  justify-content: center;
}

.noOfFounders {
  input {
    height: 30px;
    border-radius: 20px;
    max-width: 280px;
  }
  i {
    margin-left: 17px;
    background-color: var(--ui-color-primary-orange-dark1);
    border-radius: 50%;
    height: 18px;
    width: 18px;
    text-align: center;
    font-size: 10px;
    margin-top: 4px;
    position: absolute;
    padding-top: 4px;
    color: var(--ui-color-white);
  }
  .fa-plus {
    background-color: var(--ui-color-primary-green-dark2);
  }
}
