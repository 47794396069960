.header-routing {
  // margin-top: -35px;
  margin-top: -30px;
  z-index: 2000;
  position: fixed;
  display: flex;
  // width: 100%;
  align-items: center;
  color: #53cab2;
  @media only screen and(max-width:1100px) {
    width: 390px;
  }
  @media only screen and(max-width:900px) {
    width: 240px;
  }
  @media only screen and(max-width:768px) {
    display: none;
  }

  &__container {
    font-family: Proxima Nova;
    font-style: normal;
    font-size: 13px;
    text-overflow: ellipsis;
    direction: rtl;
    white-space: nowrap;
    overflow: hidden;
  }

  &__name {
    font-weight: normal;
    line-height: 17px;
    margin-right: 10px;
    letter-spacing: 0.03em;
    cursor: pointer;
  }
  &__highlight {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    cursor: pointer;
  }
  &__stilt {
    margin-right: 10px;
    margin-left: 5px;
    font-size: 10px;
  }
}

