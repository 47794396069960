.input-traffic {
  &__traffic_light {
    display: flex;

    span {
      position: absolute;
      text-align: center;
      width: 100%;
      top: 40px;
      font-size: 18px;
      @media screen and (max-width: 1101px) {
        top: 29px;
        font-size: 14px;
      }
    }
  }
  &__traffic_red {
    width: 110px;
    height: 110px;
    padding: 0px;
    margin: 20px;
    position: relative;
    cursor: pointer;
    color: var(--color-red);
    @media screen and (max-width: 1101px) {
      width: 80px;
      height: 80px;
      margin: 13px;
    }
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 100%;
      opacity: 0.3;
      background: var(--color-red);
    }
  }
  &__traffic_yellow {
    color: var(--color-yellow);
    width: 110px;
    height: 110px;
    padding: 0px;
    margin: 20px;
    position: relative;
    cursor: pointer;
    @media screen and (max-width: 1101px) {
      width: 80px;
      height: 80px;
      margin: 13px;
    }
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 100%;
      opacity: 0.3;
      background: var(--color-yellow);
    }
  }
  &__traffic_green {
    color: var(--color-green);
    width: 110px;
    height: 110px;
    padding: 0px;
    margin: 20px;
    position: relative;
    cursor: pointer;
    @media screen and (max-width: 1101px) {
      width: 80px;
      height: 80px;
      margin: 13px;
    }
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 100%;
      opacity: 0.3;
      background: var(--color-green);
    }
  }
  &__selected_traffic_light {
    .traffic_red {
      color: var(--ui-color-white);
      &:before {
        opacity: 1;
      }
    }
    .traffic_yellow {
      color: var(--ui-color-white);
      &:before {
        opacity: 1;
      }
    }
    .traffic_green {
      color: var(--ui-color-white);
      &:before {
        opacity: 1;
      }
    }
  }
}
