.qr-code {
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__card {
    max-width: 700px;
    display: flex;
    justify-content: center;
  }
  &-sub-container {
    text-align: center;
    max-width: 612px;
  }
  &__title {
    font-family: Roboto;
    font-weight: 500;
    font-size: 35px;
    color: #434343;
    margin-bottom: 40px;

    @media screen and (max-width: 600px) {
      font-size: 30px;
    }

    @media screen and (max-width: 400px) {
      font-size: 25px;
    }
  }
  &__qr {
    img {
      background: #fff;
      width: 70%;
      padding: 30px;
    }
  }
  &__pin-sequence {
    margin-top: 30px;
    span {
      font-family: Roboto;
      padding: 22px;
      background: #f3f3f3;
      font-size: 50px;
      font-weight: 700;
      width: 70px;
      height: 100px;
      position: relative;
      display: inline-block;
      text-align: center;
      line-height: 1.1;
      color: black;
      &:not(:last-child) {
        margin-right: 24px;
        @media screen and (max-width: 600px) {
          margin-right: 15px;
        }

        @media screen and (max-width: 400px) {
          margin-right: 10px;
        }
      }

      @media screen and (max-width: 600px) {
        font-size: 35px;
        width: 50px;
        height: 70px;
        padding: 15px;
      }

      @media screen and (max-width: 400px) {
        font-size: 30px;
        width: 40px;
        height: 55px;
        padding: 12px;
      }
    }
  }
}
