.settingsNotifications-container {
  display: flex;
  justify-content: center;
  min-height: 90vh;
  align-items: center;

  &__card {
    max-width: 700px;
  }

  .settingsNotifications {
    &__no-notifications {
      text-align: center;
      font-size: 14px;
      color: var(--ui-color-gray-medium);
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}
