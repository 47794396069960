/* Main component stylings */
.settingsFunnels-container {
  display: flex;
  justify-content: center;
  min-height: 80vh;
  align-items: center;
  &__card {
    max-width: 700px;
  }

  &__create-new-funnel-text {
    width: 100%;
    margin-top: 20px;
    padding: 5px 15px;
  }
  &__edit {
    font-size: 16px;
    position: relative;
    padding-left: 4px;
    color: var(--ui-color-primary-green-dark2);
    cursor: pointer;
    top: 3px;
  }

  &__funnels {
    &__funnel {
      position: relative;
      margin-bottom: 30px;
      @media only screen and(max-width:500px) {
        margin-top: 10px;
        margin-left: 0;
      }
      &__heading {
        font-weight: bold;
        font-size: 16px;
        line-height: 17px;
        color: var(--ui-color-gray-dark1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          cursor: pointer;
          color: var(--ui-color-gray-pale2);
          &:hover {
            color: var(--ui-color-gray-dark5) !important;
          }
        }
        &__dropdown {
          position: absolute;
          box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
          border-radius: 5px;
          z-index: 999;
          text-align: initial;
          margin-top: 1px;
          margin-left: 40px;
        }
      }
      .parts {
        margin-top: 20px;
        max-width: 400px;

        &__part {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          justify-content: space-between;
          margin-top: 5px;

          &__heading {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-transform: capitalize;
            color: var(--ui-color-gray-medium);
            width: 100%;

            @media only screen and(max-width:500px) {
              font-size: 12px;
            }
          }

          &__color_block {
            width: 180px;
            display: flex;
            justify-content: center;
            .gray {
              background: linear-gradient(
                180deg,
                var(--ui-color-gray-pale1) 0%,
                var(--ui-color-gray-pale2) 100%
              );
              height: 16px;
              border-radius: 10px;
            }
            .red {
              background: linear-gradient(
                180deg,
                var(--ui-color-primary-orange-dark2) 0%,
                var(--ui-color-primary-orange-medium) 100%
              );
              height: 16px;
              border-radius: 10px;
            }
            .blue {
              background: linear-gradient(
                180deg,
                var(--ui-color-primary-green-dark1) 0%,
                var(--ui-color-primary-green-medium) 100%
              );
              height: 16px;
              border-radius: 10px;
            }
            .purple {
              background: linear-gradient(180deg, #9e80dd 0%, #c7adff 100%);
              height: 16px;
              border-radius: 10px;
            }
            .orange {
              background: linear-gradient(
                180deg,
                var(--ui-color-secondary-yellow-dark) 0%,
                var(--ui-color-secondary-yellow) 102.82%,
                rgba(229, 232, 255, 0) 110.91%
              );
              height: 16px;
              border-radius: 10px;
            }
            .green {
              background: linear-gradient(180deg, #90e074 0%, #b3ff78 100%);
              height: 16px;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }

  /* EditFunnel Stylings */
  &__new-funnel {
    margin-top: 30px;
    margin-bottom: 30px;
    &__text {
      font-weight: bold;
      font-size: 16px;
      line-height: 17px;
      color: var(--ui-color-gray-dark1);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .settingsFunnelGroups {
      margin-top: 20px;
      &__group {
        display: grid;
        grid-template-columns: repeat(3, max-content);
        column-gap: 10px;
        row-gap: 15px;
        align-items: center;
        margin-top: 10px;

        @media only screen and(max-width:500px) {
          grid-template-columns: repeat(2, max-content);
        }

        @media only screen and(max-width:385px) {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(3, max-content);
        }
        .fa-plus-circle {
          color: var(--ui-color-primary-green-dark2);
          margin-left: 5px;
          cursor: pointer;
          @media only screen and(max-width:768px) {
            // margin-top: 10px;
          }
        }
        .fa-times-circle {
          color: var(--ui-color-primary-orange-dark2);
          margin-left: 5px;
          cursor: pointer;
        }
        &__heading {
          input {
            background: var(--ui-color-white);
            border: 1px solid var(--ui-color-gray-pale2);
            box-sizing: border-box;
            border-radius: 20px;
            padding-left: 15px;
            width: 150px;
            height: 23px;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0px;
            margin-right: -2px;
          }
          .input {
            height: 23px;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0px;
            margin-right: -2px;
          }
        }
        &__heading-text {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-transform: capitalize;
          color: var(--ui-color-gray-medium);
          width: 150px;
        }
        &__funnel {
          height: 15px;
          width: 100px;
          margin-left: 5px;
          border-radius: 8px;
          @media only screen and(max-width:385px) {
            margin-left: 0;
          }
        }
        .red {
          background: linear-gradient(
            180deg,
            var(--ui-color-primary-orange-dark2) 0%,
            var(--ui-color-primary-orange-medium) 100%
          );
        }
        .blue {
          background: linear-gradient(
            180deg,
            var(--ui-color-primary-green-dark2) 0%,
            var(--ui-color-primary-green-medium) 100%
          );
        }
        .purple {
          background: linear-gradient(180deg, #9e80dd 0%, #c7adff 100%);
        }
        .orange {
          background: linear-gradient(
            180deg,
            var(--ui-color-secondary-yellow-dark) 0%,
            var(--ui-color-secondary-yellow) 102.82%,
            rgba(229, 232, 255, 0) 110.91%
          );
        }
        .green {
          background: linear-gradient(180deg, #90e074 0%, #b3ff78 100%);
        }
        &__color-pallets {
          display: flex;
          align-items: center;
          @media only screen and(max-width:500px) {
            grid-column: 2/3;
            margin-left: 5px;
          }
          @media only screen and(max-width:385px) {
            grid-column: 1/2;
            margin-left: 0;
          }
          .color-pallet {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            margin-right: 5px;
            padding: 2px;
            cursor: pointer;
          }
          .selected {
            box-shadow: 0 0 0 1px hsl(0deg 0% 80%), 0 0 0 2px hsl(0deg 0% 90%);
          }
        }
      }
    }
  }
  &__create-new-funnel {
    margin-left: 50px !important;
    margin-top: 50px !important;
    background: var(--ui-color-primary-green-dark2) !important;
    height: 34px;
  }
  &__create-new-funnel.disabled {
    background: lightgrey !important;
    cursor: default;
    opacity: 0.5;
  }
}
