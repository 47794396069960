.archive_and_delete__container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
    @media screen and (max-width: 1050px) {
      justify-items: center;
    }
    @media screen and (max-width: 768px) {
      justify-items: start;
    }
    @media screen and (max-width: 600px) {
      justify-items: center;
    }
    .open-archive {
      justify-self: end;
      .open_archive_decoration{
        text-decoration: none;
    }
    @media screen and (max-width: 1050px) {
      justify-self: center;
    }
    @media screen and (max-width: 768px) {
      justify-self: end;
    }
    @media screen and (max-width: 600px) {
        justify-self: center;
      }
    }
}
.archive-modal-description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: var(--ui-color-gray-medium);
  margin-top: 25px;
}