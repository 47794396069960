.toc {
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 15px;
  width: 300px;
  height: 100%;
  background: #f5f5f5;
  overflow-y: scroll;
  z-index: 99999;

  h2, h3 {
    font-style: normal;
    //font-weight: 400;
    cursor: pointer;
    z-index: 50;
    position: relative;

    &:hover {
      &:after {
        content: "";
        background: #ebe9e9 !important;
        width: calc(100% + 15px) !important;
        height: 26px;
        left: -15px !important;
        top: -3px !important;
        position: absolute;
        z-index: 1;
      }
    }

  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: #1367b7;
  }

  h3 {
    font-size: 15px;
    padding-bottom: 0;
    color: dodgerblue;
  }

  h2 div,
  h3 div {
    z-index: 100;
    position: relative;
  }


  h2.active, h3.active {
    color: white !important;
    &:after {
      content: "";
      background: #1366b7 !important;
      width: calc(100% + 15px) !important;
      height: 26px;
      left: -15px !important;
      top: -3px !important;
      position: absolute;
      z-index: 1;
    }
  }


  &__chapter {

    padding-bottom: 20px;

    &__section_wrapper {

      padding-top: 5px;

      &__content {
        padding-top: 3px;
        padding-bottom: 3px;
        position: relative;
      }


    }

    .sub-sub {
      font-size: 13px;
      font-weight: 400;
      //color: #61adfe;
    }

    .active h2,
    .active h3 {
      color: white !important;
    }


  }


}

@media screen and (max-width: 1268px) {
  .toc {
    visibility: hidden;
  }
}

