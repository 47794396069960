.card {
  background: var(--ui-color-white);
  box-shadow: 0px 3px 12px rgba(41, 70, 103, 0.2);
  border-radius: 8px;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
  padding-bottom: 20px;
  font-family: "Proxima Nova";
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    margin-bottom: 20px;
    @media only screen and (max-width: 500px) {
      font-size: 22px;
    }

    &-right {
      color: var(--ui-color-gray-dark1);
      white-space: nowrap;
    }

    &-icon {
      padding-right: 10px;
    }

    //i {
    //  color: var(--ui-color-primary-green-dark2);
    //  cursor: pointer;
    //}

  }

  &__heading1 {
    display: flex;
    align-items: center;
    padding-right: 10px;
    // margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;

    i {
      padding-right: 10px;
      color: var(--ui-color-primary-green-dark2);
      cursor: pointer;
    }
  }

  &__collapse {
    display: none;
    &-icon {
      font-size: 18px !important;
    }
  }

  &__title {
    font-family: Proxima Nova;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: var(--ui-color-gray-dark1);
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    overflow: hidden;

    // Title color
    &--white {
      color: #fff;
    }

    // Title size
    &--large {
      font-size: 30px;
      @media only screen and (max-width: 500px) {
        font-size: 24px;
      }
    }
    &--medium {
      font-size: 24px;
      @media only screen and (max-width: 500px) {
        font-size: 22px;
      }
    }
    &--small {
      font-size: 20px;
    }

    &--underline {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__sub-heading {
    margin-bottom: 20px;
  }

  &__heading {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: var(--ui-color-gray-dark1);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }

  &__footer {
    margin-top: 20px;
  }
}

.small__card {
  padding: 20px !important;
}
.large__card {
  padding: 30px;

  @media only screen and (max-width: 500px) {
    padding: 25px;
  }
}

.padding-large {
  padding: 30px;
}
.padding-medium {
  padding: 25px;
}
.padding-small {
  padding: 20px;
}
