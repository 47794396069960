.import-section-container {
  padding-top: 35px;
  .my-template,
  .group-template {
    margin-left: auto;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--ui-color-white);
    padding: 1px 10px 2px 14px;
    background-color: var(--ui-color-secondary-green);
    border-radius: 8px;
    min-width: 91px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .group-template {
    background-color: #a88ae9;
  }
  .drop-down-heading {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: var(--ui-color-gray-medium);
    margin-bottom: 5px;
    @media only screen and(max-width:768px) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .desktop_sections {
    @media only screen and(max-width:768px) {
      display: none;
    }
  }
  .mobile_sections {
    @media only screen and(min-width:768px) {
      display: none;
    }
    .section {
      display: flex;
      margin-top: 10px;
      &__text {
        margin-left: 10px;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: var(--ui-color-gray-dark1);
      }
    }
  }
  .question-container {
    display: flex;
    margin-top: 10px;
    &__question {
      margin-left: 12px;
      &__text {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: var(--ui-color-gray-dark1);
      }
      &__type {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        color: var(--ui-color-gray-medium);
      }
      &__select-all {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;

        color: var(--ui-color-gray-dark1);
      }
    }
  }
}
// .inner{
//     max-width: 900px;
// }
