// GroupPage
.group {

  &__markdown {
    padding-top: 20px;
    font-size: 16px;
    img {
      max-width: 100%;
    }
    a {
      color: #337ab7 !important;
    }
    p {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h2 {
      padding: 20px 0px;
    }
  }

  .message--not-found {
    padding: 20px;
    padding-top: 30px;
    color: var(--color-primary)
  }


  &__member-admin-view{
    margin-top: 20px;
    display: flex;
    justify-content: end;
    margin-right: 15px;
    .unnselected{
      background-color: #D3D3D3!important;
    }
    button{
      font-family: "Roboto";
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }
    &__member{
      border-top-right-radius: 0px!important;
      border-bottom-right-radius: 0px!important;
      margin-right: 1px!important;
    }
    &__admin{
      border-top-left-radius: 0px!important;
      border-bottom-left-radius: 0px!important;
    }
  }
  // GroupPage
  &__header-row {
    display: flex;
    margin-bottom: 10px;
    .card {
      min-height: 100%;
    }
    .data__admin{
      color:#cecece ;
    }

    @media only screen and(max-width:991px) {
      display: block;
      margin-bottom: 0px;
    }
  }
  .card-message{
    margin-top: 27px;
    &__content {
      &__title{
        font-weight: 500;
        line-height: 27px;
        font-family: "Roboto";
        color: #6A6A6A;
        padding-bottom: 6px;
      }
      &__message {
        line-height: 27px;
        font-family: "Roboto";
        color: #6A6A6A;
      }
    }
  }
  // InfoSection

  &__card-heading {
    display: flex;
    align-items: center;
    .group-name {
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
    }
    .material-icons {
      color: var(--ui-color-gray-pale2);
    }
  }
  &__card-content {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.03em;
    margin-top: 37px;
    color: var(--ui-color-gray-medium);
    padding-bottom: 25px;
    display:flex;
    //word-break: break-all;
    border-bottom: 1px solid var(--ui-color-gray-pale1);
  }

  &__settings {
    position: absolute;
    right: 25px;
    cursor: pointer;
    top: 20px;
    &:hover {
      color: var(--ui-color-gray-dark1);
    }

    &__icon {
      color: var(--ui-color-gray-pale2);
      font-size: 25px;
    }

  }

  &__view-variants{
    display: flex;
    @media only screen and(max-width:500px) {
      flex-direction: column;
    }
    button{
      color: #C4C4C4!important;
      border-radius: 20px;
      margin-right: 10px;
      padding-right: 25px;
      padding-left: 15px;
      @media only screen and(max-width:500px) {
        max-width: 150px;
        margin-bottom: 15px;
      }
    }
    .selected{
      color: #6A6A6A!important;
      border-color: #6A6A6A!important;
    }
  }
  // ManageResources
  .manage-resources {
    &__actions{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 25px;
      button{
        margin-top:10px;
      }
      &__action{
        display: flex;
        align-items: center;
        width: 175px; // according to the size of button to make count towards left
        &__icon{
          margin-left: 12px;
        }
        &__count{
          margin-left: auto;
          font-family: Roboto;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          color: rgba(255, 255, 255, 0.45);
          margin-right: 8px;
        }
        &__text{
          margin-left: auto;
        }
      }
    }
    .mang-res {
      @media screen and (max-width: 380px) {
        padding-left: 10px;
      }
    }
    .MuiTabs-root {
      margin-left: auto;
      width: 250px;
      .MuiTab-root {
        min-width: 125px;
      }
    }
  }
  .MuiButtonBase-root {
    font-family: "Proxima Nova";
    font-style: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--ui-color-gray-medium);
  }
  .Mui-selected {
    font-weight: bolder;
    font-family: "proxima_nova_altbold";
  }
  .MuiTabs-indicator {
    border-bottom: 4px solid var(--ui-color-gray-medium);
    border-radius: 2px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .users-container {
    margin-top: 23px;
    &__user-count {
      border-bottom: 1px solid var(--ui-color-gray-pale1);
      padding-bottom: 12px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      &__name {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: var(--ui-color-gray-dark1);
        @media screen and (max-width: 380px) {
          font-size: 12px;
        }
      }
      &__action {
        margin-left: auto;
        cursor: pointer;
        display: flex;
        button{
          font-weight: 600;
          font-size: 12px!important;
          line-height: 15px!important;
          text-transform: none!important;
        }
        .admins-data {
          .admin {
            font-family: Proxima Nova;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            margin-top: 10px;
            .username {
              text-decoration-line: underline;
              color: var(--ui-color-gray-dark1);
            }
            .email {
              color: var(--ui-color-gray-medium);
            }
          }
        }
      }
    }
  }

  .startup-container {
    .startup-heading {
      border-bottom: 0px;
      @media only screen and(max-width:768px) {
        margin-left: 20px;
      }
    }
    &__startup-icon {
      background: linear-gradient(
                      152.3deg,
                      var(--ui-color-primary-orange-medium) 9.47%,
                      var(--ui-color-primary-orange-dark1) 93.11%
      );
      height: 28px;
      width: 28px;
      border-radius: 50%;
      color: var(--ui-color-white);
      font-size: 16px;
      display: flex;
      justify-content: center;
      margin-right: 10px;
    }
    &__description {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      margin-left: 90px;
      color: var(--ui-color-gray-medium);
      width: 60%;
      @media only screen and(max-width:768px) {
        margin-left: 20px;
        width: 90%;
      }
      margin-top: 5px;
    }

    .subjective-score-container {
      //margin-left: 60px;
      &__subjective-score {
        background-color: red;
        @media only screen and(max-width:768px) {
          border: none;
        }
        border-right: 1px solid var(--ui-color-gray-pale2);
      }
      @media only screen and(max-width:768px) {
        margin-left: 20px;
      }
      margin-top: 14px;
      &__heading {
        margin-top: 14px;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: var(--ui-color-gray-dark1);
        &__icon {
          position: absolute;
          left: -15px;
          top: 14px;
          color: var(--ui-color-primary-green-dark2);
          @media only screen and(max-width:768px) {
            left: -5px;
          }
        }
      }
      &__evaluations-heading {
        color: var(--ui-color-gray-medium);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &__score {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        margin-top: 5px;
        color: var(--ui-color-gray-dark1);
      }
      &__highest-lowest-score {
        @media only screen and(min-width:1210px) {
          position: absolute;
        }
        top: 37px;
        left: 85px;
        &__record {
          height: 18px;
        }
        &__score {
          font-family: "Proxima Nova";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
        }
        .highest {
          color: var(--ui-color-primary-green-dark2);
        }
        .lowest {
          color: var(--ui-color-red);
        }
        .south {
          font-size: 12px;
          color: var(--ui-color-red);
          margin-right: 5px;
        }
        .north {
          font-size: 12px;
          color: var(--ui-color-primary-green-dark2);
          margin-right: 5px;
        }
        &__type {
          font-family: Proxima Nova;
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 11px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          color: var(--ui-color-gray-medium);
          margin-left: 5px;
        }
      }
    }
    .subjective-score-evaluation-container {
      margin-top: 45px;
      //margin-left: 80px;
      border-bottom: 1px solid var(--ui-color-gray-pale);
      padding-bottom: 10px;
      //@media only screen and(max-width:768px) {
      //margin-left: 25px;
      //}
      //width: 80%;
      &__icon {
        position: absolute;
        color: var(--ui-color-primary-green-dark2);
        margin-left: -10px;
      }
      &__name {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--ui-color-gray-dark1);
        i {
          cursor: pointer;
          color: var(--ui-color-primary-green-dark2);
        }
      }
      &__submitions {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: var(--ui-color-gray-medium);
      }
      &__score {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--ui-color-gray-medium);
        @media only screen and(max-width:768px) {
          top: -15px;
        }
        .full-list {
          font-family: "Proxima Nova";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          text-decoration-line: underline;
          color: var(--ui-color-gray-medium);
          margin-left: 15px;
          cursor: pointer;
          @media only screen and(max-width:768px) {
            position: absolute;
            margin-left: -25px;
            top: 17px;
          }
        }
      }
      &__username {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        color: var(--ui-color-gray-dark1);
      }
    }
    .evaluation-container {
      //margin-left: 80px;
      //width: 80%;
      padding: 15px 0px;
      //@media only screen and(max-width:768px) {
      //  margin-left: 25px;
      //}
      &__heading {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.08em;
        color: var(--ui-color-gray-medium);
      }
    }
    .evaluation-list-container {
      //margin-left: 80px;
      //width: 80%;
      padding: 5px 0px;
      //@media only screen and(max-width:768px) {
      //  margin-left: 25px;
      //}
    }
    .evaluation-list-container:nth-child(even) {
      background-color: var(--color-gray-pale);
    }

    .requested-evaluations-container {
      //margin-top: 45px;
      //margin-left: 45px;
      //padding-bottom: 50px;
      padding-left: 45px;
      padding-right: 40px;
      //@media only screen and(max-width:768px) {
      //  margin-left: 5px;
      //}
    }

    .your-evaluations-container {
      margin-top: 45px;
      //margin-left: 45px;
      padding-bottom: 30px;
      //@media only screen and(max-width:768px) {
      //  margin-left: 5px;
      //}
      &__icon {
        position: absolute;
        color: var(--ui-color-primary-green-dark2);
        margin-left: -15px;
        @media only screen and(max-width:768px) {
          margin-left: -5px;
        }
      }
      &__heading {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        @media only screen and(max-width:768px) {
          font-size: 14px;
          line-height: 17px;
        }
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark1);
        //margin-left: 15px;
      }
      &__availability {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        @media only screen and(max-width:768px) {
          font-size: 14px;
          line-height: 17px;
        }
        color: var(--ui-color-gray-medium);
      }
      &__show-comments {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-left: 40px;
        margin-right: 5px;
        color: var(--ui-color-primary-green-dark4);
        margin-top: 3px;
        width: auto;
        position: absolute;
        i {
          margin-left: 10px;
        }
      }
      &__message-count {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        margin-left: 10px;
        color: var(--ui-color-gray-medium);
        @media only screen and(max-width:991px) {
          margin-left: 17px;
        }
      }
      &__icon {
        color: var(--ui-color-primary-green-dark2);
        font-size: 18px;
        cursor: pointer;
      }
      &__comment-section {
        display: flex;
        @media only screen and(min-width:992px) {
          position: absolute;
          left: 87%;
          width: 270px;
        }
        @media only screen and(max-width:991px) {
          margin-top: 20px;
        }
        right: 0px;
      }
      &__score {
        margin-top: 40px;
      }
      &__subjective {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: var(--ui-color-gray-dark1);
      }
      &__option-container {
        @media only screen and(min-width:1269px) {
          margin-left: 60px;
        }
        display: inline-block;
        @media only screen and(max-width:1268px) {
          margin-top: 30px;
          margin-left: 0px;
        }
      }
      &__share-with-group {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        margin-left: auto;
        line-height: 15px;
        color: var(--ui-color-white);
        background: var(--ui-color-primary-green-dark2);
        display: inline-block;
        padding: 7px 20px;
        border-radius: 14px;
        @media only screen and(max-width:1268px) {
          margin-top: 20px;
          margin-left: 0px;
        }
      }
      &__score-option,
      &__score-option-active {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        font-size: 14px;
        padding-top: 8px;
        border: 1px solid var(--ui-color-gray-dark1);
        color: var(--ui-color-gray-dark1);
        margin-right: 20px;
        cursor: pointer;
        @media only screen and(max-width:768px) {
          margin-bottom: 10px;
        }
      }
      &__score-option-active {
        background: #c4a9fc;
        border: 1px solid #c4a9fc;
        color: var(--ui-color-white);
      }

      &__details-heading {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.08em;
        color: var(--ui-color-gray-medium);
        text-transform: uppercase;
        margin-top: 40px;
      }
      &__record {
        margin-top: 20px;
        &__share-with-group {
          cursor: pointer;
          width: 140px;
          text-align: center;
          font-family: Proxima Nova;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: var(--ui-color-white);
          background: var(--ui-color-primary-green-dark2);
          padding: 7px 20px;
          border-radius: 14px;
          margin-left: auto;
          //   margin-top: -10px;
          @media only screen and(max-width:1268px) {
            margin-top: -5px;
            margin-bottom: 10px;
            margin-left: auto;
          }
          @media only screen and(max-width:768px) {
            margin-top: 10px;
          }
        }
      }
      .your-evaluation-open {
        margin-top: 30px;
      }
    }
    .comment-container {
      margin-top: 50px;
    }
    .comment {
      margin-top: 25px;
      &__username {
        font-family: "Roboto";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark1);
      }
      &__datetime {
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark7);
        margin-left: 10px;
      }
      &__comment {
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark1);
        margin-top: 10px;
        width: 95%;
      }
      &__write-comment {
        height: 73px;
        width: 95%;
        border-radius: 20px;
        border: none;
        background: var(--ui-color-gray-pale1);
        margin-top: 20px;
        padding-left: 10px;
        &:focus-visible {
          outline-width: 0px;
        }
      }
      &__send {
        font-size: 22px;
        right: calc(5% + 30px);
        position: absolute;
        top: 47px;
        color: var(--ui-color-gray-medium);
      }
    }
  }
  .sync-btn {
    @media screen and (max-width: 350px) {
      position: relative;
      margin-right: 20px;
      right: 25px;
      padding-right: 20px !important;
    }
    .big {
      @media screen and (max-width: 360px) {
        font-size: 10px !important;
        width: 155px;
      }
    }
  }
}
.group-dashboard-heading{




}
.group-dashar-top-content{
  display: flex !important;
  .group-dashboard-heading{
    margin-top: 4px;
    word-wrap: break-word;
    display: flex;
    width: 32%;
    display: -webkit-box;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width:350px) {
      width: 20%;
    }

  }
}
.inputTag {
  text-align: right;
  padding-right: -10px !important;
}

.invite-member-modal {
  max-width: 593px;
  .card-message{
    margin-top: 0px;
  }
  &__member-container{
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid #C1E6F7;
    border-radius: 4px;
    padding: 0px 10px 10px 10px;
    &__data{
      margin-top: 10px;
    }
    &__heading{
      font-family: "Roboto";
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #82D5FC;
      margin-top: -9px;
      background: white;
      position: absolute;
      padding-left: 8px;
      padding-right: 8px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
    &__member{
      display: flex;
      align-items: center;
      padding: 14px;
      &__invite-btn{
        margin-left: auto!important;
        text-transform: lowercase!important;
      }
      i{
        color: #FF937C;
        line-height: 17px;
        cursor: pointer;
      }
      .dissabled{
        color: rgba(200, 200, 200, 0.5);
      }
      &__info{
        margin-left: 10px;
        &__name{
          font-family: "Roboto";
          font-size: 16px;
          line-height: 18px;
          color: #6A6A6A;
        }
        &__email{
          font-family: "Roboto";
          font-size: 14px;
          line-height: 16px;
          margin-top: 3px;
          color: var(--ui-color-gray-medium);
        }
      }
      .member-btn {
        background: var(--ui-color-secondary-yellow);
        padding: 5px 15px 7px 15px;
        border-radius: 30px;
        font-family: "Proxima Nova";
        font-size: 12px;
        line-height: 15px;
        color: var(--ui-color-white);
        margin-left: auto;
        text-align: center;
        position: relative;
        width: 83px;
        cursor: pointer;
        .changerole_spinner {
          color: white;
          margin-left: 3px;
          margin-top: 2px;
        }
        @media screen and (max-width: 460px) {
          background: var(--ui-color-secondary-yellow);
          padding: 5px 5px 5px 5px;
          border-radius: 30px;
          font-family: "Proxima Nova";
          font-size: 9px;
          line-height: 15px;
          color: var(--ui-color-white);
          text-align: center;
          position: relative;
          width: 53px;
          margin-right: 0px;
        }
        @media screen and (max-width: 390px) {
          margin-right: -20px;
        }

        span {
          z-index: 10;
        }

        .member-role-slct {
          position: absolute;
          width: 100%;
          top: 27px;
          left: 0;
          border-radius: 8px;
          color: var(--ui-color-gray-dark1);
          font-size: 12px;
          box-shadow: 0px 3px 12px rgb(41 70 103 / 20%);
          padding-top: 5px;
          padding-bottom: 5px;
          line-height: 2;
          background: var(--ui-color-white);
          z-index: -1;
          text-align: left;
          padding-left: 25px;
          cursor: pointer;
        }
        i {
          color: var(--ui-color-primary-green-dark2);
          left: 0;
          top: 0;
          margin-left: -15px;
          margin-right: 4px;
        }
      }
      .member-btn:hover > .member-role-slct {
        z-index: 999999;
      }
      .member-btn:hover {
        z-index: 999999;
      }
      &:nth-child(odd){
        background: #F8FBFD;
      }
    }
  }
  .invite-member-modal-container {
    padding: 20px;
  }
  .member-record {
    margin-top: 15px;
    .icon {
      color: var(--ui-color-primary-orange-dark1);
      margin-top: 2px;
      position: relative;
      left: -4px;
      top: 1px;
      cursor: pointer;
    }
    .icons {
      margin-top: 2px;
      position: relative;
      left: -4px;
      top: 1px;
      cursor: pointer;
    }
  }
  &__name-container {
    display: flex;
    .decoration_icon{
      margin-top: 1px;
      margin-left: 4px;
    }
    .name {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      /* text-decoration-line: underline; */
      color: var(--ui-color-gray-dark1);
      margin-left: 5px;
      display: flex;
    }
    .decoration {
      text-decoration: none;
    }
    .invite-email {
      @media screen and (max-width: 470px) {
        display: flex;
        text-align: left;
        width: 75%;
        font-size: 12px;
        padding-left: 5px;
        word-break: break-all;
      }
    }
    .icon {
      color: var(--ui-color-primary-orange-dark1);
      margin-top: 2px;
    }


    .invite-btn {
      background-color: var(--ui-color-primary-green-dark2);
    }
    .pending-btn {
      // background-color: var(--ui-color-white);
      // border: 1px solid var(--ui-color-gray-dark1);

      // color: var(--ui-color-gray-dark1);
      cursor: default;
      // background: var(--ui-color-secondary-yellow);
      // padding: 4px 10px 4px 10px;
      // border-radius: 30px;
      // font-family: "Proxima Nova";
      // font-size: 9px;
      line-height: 15px;
      // color: var(--ui-color-white);
      margin-left: auto;
      text-align: center;
      position: relative;
      // width: 83px;
      text-align: center;
      .x_small {
        width: 40px;
        font-size: 9px !important;
        height: 20px !important;
        font-weight: 100 !important;
      }
    }
  }
  .email {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--ui-color-gray-dark1);
    margin-left: 25px;

    @media screen and (max-width: 360px) {
      font-size: 11px;
      line-height: 13px;
      display: flex;
      width: 100px;
      word-break: break-all;
    }
  }
  .email-2 {
    margin-left: 5px;
  }
  &__search-header{
    //margin-top: 20px;
    //font-family: "Roboto";
    //font-weight: 500;
    //font-size: 13px;
    //line-height: 26px;
    //color: #969BA3;
    color: var(--ui-color-gray-medium);
    font-size: 14px;
    letter-spacing: 0.4px;
    margin-left: 4px;
    font-weight: 600;
    position: relative;
    bottom: -5px;
  }
  &__search {

    text-align: left;
    max-width: 100%!important;
    .input-with-button__input_container{
      width: 100%!important;
    }
    .search-with-button{
      margin-right: 3px;
      margin-top: 3px;
      height: 34px!important;
      &__not-validated{
        background-color: #E0E1E2!important;
      }
    }
    .is-duplicate {
      margin-top: 10px!important;
      position: relative;
      width: 250px;
      height: 13px;
      padding-top: 2px;
      font-family: "roboto";
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.4px;
      margin-left: 0px;
      color: var(--ui-color-red) !important;
      margin-bottom: 4px;
    }



    .inputform {
      margin-top: 12px;
      .input {
        border: none;
        outline: none;
        color: inherit;
        width: 100%!important;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        padding: 10px 22px !important;
        margin-top: 0px;
        font-family: "Proxima Nova";
        color: var(--ui-color-gray-medium) !important;
      }
      .desg {
        border: 1px solid var(--ui-color-gray-pale2);
      }
      .error {
        border: 1px solid var(--ui-color-red);
      }
      .error_email {
        margin-left: 5px;
        margin-top: 8px;
        color: var(--color-red);
        position: relative;
        width: 200px;
        height: 13px;
        padding-top: 2px;
        font-family: "roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.4px;
        margin-left: 10px;
        text-transform: capitalize;
        color: var(--ui-color-red);
        margin-bottom: 4px;
      }
    }
    .material-icons {
      position: relative;
      left: 30px;
      color: var(--ui-color-gray-pale2);
      top: 10px;
      @media only screen and(max-width:396px) {
        top: 69px;
        left: 5px;
      }
    }
    .search-box {
      @media only screen and(max-width:768px) {
        margin-top: 35px;
      }
      margin-top: 14px;
      height: 30px;
      border: 1px solid var(--ui-color-gray-dark7);
      box-sizing: border-box;
      border-radius: 20px;
      width: 100%;
      padding-left: 30px;
      margin-bottom: 5px;
    }
  }
  &__select-group{
    margin-top:12px;
  }
  .startup-list {
    margin-left: 15px;
    @media only screen and(max-width:378px) {
      margin-left: 0px;
    }
    .email {
      margin-left: 5px;
    }
  }
}

.startup-container {
  //   box-sizing: border-box;
  .card {
    padding: 1.2rem;
    width: 100%;
  }

  .card > div {
    margin: 0;
  }
  @media screen and (max-width: 396px) {
    .x_small {
      width: 40px !important;
      font-size: 5px;
      height: 24px;
    }
  }
}

@media only screen and(max-width:768px) {
  .startup-container {
    .card {
      padding: 10px;
    }
  }
}
// NEW COMMENT BOX ===================================================

.commentBox {
  width: 100%;
  .comments {
    width: 100%;
    max-height: 550px;
    min-height: 250px !important;
    overflow-y: scroll;
    overflow-x: none;
    padding-right: 0.6rem;

    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--ui-color-primary-green-dark2) 00;
      border-radius: 4px;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: var(--ui-color-primary-green-dark2);
      }
    }

    .comment {
      &:hover .commentHeadEditDeleteIcon i {
        display: inline !important;
      }

      margin-top: 15px;
      padding-bottom: 12px;
      border-bottom: 1px solid var(--ui-color-gray-pale1);

      .editBox {
        .editBoxButtons {
          margin-top: 5px;

          button {
            height: 34px;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0.03em;
            color: var(--ui-color-white);
            border-radius: 17px;
            padding: 0 15px;

            &:hover,
            &:focus {
              filter: grayscale(5%);
            }
          }

          .editSave {
            background-color: var(--ui-color-primary-green-dark3);
            border: none;
          }

          .editCancel {
            margin-right: 10px;
            color: var(--ui-color-primary-green-dark3);
            border: 1px solid var(--ui-color-primary-green-dark2);
            background-color: var(--ui-color-white);
          }
        }
      }

      .commentTime {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-medium);
        margin-top: 10px;
        margin-left: 29px;
      }

      .commentText {
        font-family: Roboto;
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark1);
        display: block;
        margin-top: 5px;
        margin-left: 29px;
      }

      .commentHead {
        display: flex;
        height: 20px;

        .commentUserName {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          padding-left: 7px;
          letter-spacing: 0.03em;
          text-decoration-line: underline;
          color: var(--ui-color-gray-medium);
        }

        svg {
          transform: scale(0.75);
        }

        .commentHeadEditDeleteIcon {
          margin-left: auto;

          i {
            display: none;
            cursor: pointer;
            margin: 0 5px;
            opacity: 0.5;
            transition: all 0.1s ease;

            &:hover {
              opacity: 0.9;
            }
          }

          i:first-of-type {
            color: var(--ui-color-primary-orange-dark3);
          }

          i:last-of-type {
            color: var(--ui-color-primary-green-dark3);
          }

          .deleteIcon {
            font-family: "FontAwesome";
          }
        }
      }
    }
  }
}

// Comment Text Box
.commentInput {
  width: 100%;
  display: flex;
  height: 40px;
  padding: 0 15px;
  border-radius: 20px;
  border: 1px solid var(--ui-color-gray-dark7);

  input {
    width: 100%;
    background: none;
    outline: none;
    border: none;
  }

  button {
    background: none;
    outline: none;
    border: none;
  }
}

// This below styles not used i am checking before deleting once check this styles used or not
// &__info-window {
//   @media only screen and(min-width:900px) {
//     width: 485px;
//   }
//   left: 60px;
//   &__heading {
//     font-family: "Proxima Nova";
//     font-style: normal;
//     font-weight: 600;
//     font-size: 18px;
//     line-height: 22px;
//     color: var(--ui-color-gray-dark1);
//     margin-bottom: 16px;
//   }
//   &__description {
//     font-family: Roboto;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 13px;
//     line-height: 16px;
//     margin-bottom: 20px;
//     color: var(--ui-color-gray-medium);
//   }
// }
// &__group-details {
//   font-family: "Proxima Nova";
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   letter-spacing: 0.03em;
//   color: var(--ui-color-gray-medium);
//   margin-left: 60px;
//   margin-top: 45px;
//   margin-bottom: 87px;
//   @media only screen and(max-width:768px) {
//     margin-bottom: 35px;
//   }
//   margin-right: 35px;
// }
.group-member-modal {
  margin-top: 30px;
  .member-record {
    margin-top: 10px;
    display: flex;

    .icon {
      color: var(--ui-color-primary-orange-dark1);
      margin-top: 2px;
    }
  }
  .name {
    // font-family: "Proxima Nova";
    // font-style: normal;
    // font-weight: normal;
    // font-size: 14px;
    // line-height: 17px;
    // text-decoration-line: underline;
    // color: var(--ui-color-gray-dark1);
    // margin-left: 5px;
  }
  .email {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--ui-color-gray-dark1);
    margin-left: 25px;
  }
}

.group-task-container {
  padding-top: 20px;


  &__group-task-header {
    font-family: Proxima Nova;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: var(--ui-color-gray-medium);
  }

  &__group-task {


    &__each-line:nth-child(odd) {
      background: rgba(223, 233, 245, 0.2);
    }

    &__each-line {

      padding: 10px;
      //display: flex;
      //justify-content: space-between;

      &__startup-name {
        padding: 0px 3px;
        padding-bottom: 4px;
        font-size: 16px;
        color: var(--ui-color-gray-dark);
      }

      &__buttons-container {

        position: relative;

        &__button {
          width: auto;
          padding: 5px 13px;
          height: auto;
          text-transform: initial !important;
          font-size: 13px !important;
          display: inline-block !important;
        }

      }
    }

  }

}