.startup-container {
  .MuiTabs-scroller {
    overflow-x: scroll !important;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .MuiButtonBase-root {
    font-family: "Proxima Nova";
    font-style: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--ui-color-gray-medium);
  }
  .Mui-selected {
    font-weight: bolder;
    font-family: "proxima_nova_altbold";
  }
  .MuiTabs-indicator {
    border-bottom: 4px solid var(--ui-color-gray-medium);
    border-radius: 2px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
.card {
  background: var(--ui-color-white);
  box-shadow: 0px 3px 12px rgba(41, 70, 103, 0.2);
  border-radius: 8px;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
  .card-heading {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    padding: 25px 25px 0px 25px;
    color: var(--ui-color-gray-dark1);
    text-overflow: ellipsis;
    white-space: nowrap;
    //overflow: hidden;
    @media only screen and (max-width: 768px) {
      font-size: 13px;
    }
    @media only screen and (max-width: 500px) {
      padding: 15px 15px 15px 15px;
    }
  }

  .card-empty-text {
    // padding: 0px 7px;
    color: var(--ui-color-gray-dark4);
    font-size: 14px;
    line-height: 1.5;
    padding-top: 20px;
  }
  .card-notification-bar {
    background: var(--ui-color-primary-green-dark2);
    border-radius: 8px 8px 0px 0px;
    .text {
      font-family: "Proxima Nova";
      font-style: normal;
      font-size: 18px;
      line-height: 22px;
      color: var(--ui-color-white);
      padding: 11px 25px 11px 20px;
      .btn {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        padding: 3px 15px 3px 15px;
        border: 1px solid var(--ui-color-white);
        border-radius: 12px;
        margin-left: 25px;
        @media only screen and(max-width:490px) {
          margin-left: 5px;
        }
      }
    }
  }
}
.tab-panel-container {
  margin-top: 30px;
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
  }
}
.separator {
  padding-top: 25px;
  border-bottom: 1px solid var(--ui-color-gray-pale1);
  width: 90%;
  margin-left: 5%;
}
.back-icon {
  color: var(--ui-color-primary-green-dark2);
  cursor: pointer;
  position: absolute;
}
.page-heading {
  font-family: "Proxima Nova";
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--ui-color-gray-medium);
  margin-left: 25px;

  @media only screen and(max-width:500px) {
    font-size: 18px;
  }
}
.padding-left-0 {
  padding-left: 0px;
}
.asterik {
  color: var(--ui-color-primary-orange-dark1);
  margin-left: 2px;
}
.MuiTabs-indicator {
  background-color: var(--ui-color-gray-medium) !important;
}
.info-window {
  position: absolute;
  background: var(--ui-color-white);
  border-radius: 5px;
  box-shadow: 0px 3px 12px rgba(41, 70, 103, 0.2);
  padding: 40px;
  z-index: 999;
}
.nopadding-left {
  @media only screen and(min-width:991px) {
    padding-left: 0px;
  }
}
.nopadding-right {
  @media only screen and(min-width:991px) {
    padding-right: 0px;
  }
}
// .header-routing {
//   margin-top: -28px;
//   z-index: 2000;
//   position: fixed;
//   display: flex;
//   align-items: center;
//   @media only screen and(max-width:768px) {
//     display: none;
//   }
//   &__name {
//     font-family: Proxima Nova;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 13px;
//     line-height: 17px;
//     margin-right: 10px;
//     display: flex;
//     align-items: center;
//     letter-spacing: 0.03em;
//     color: #53cab2;
//     cursor: pointer;
//   }
//   &__highlight {
//     font-family: Proxima Nova;
//     font-style: normal;
//     font-weight: 600;
//     font-size: 12px;
//     line-height: 12px;
//     letter-spacing: 0.08em;
//     text-transform: uppercase;
//     color: var(--ui-color-primary-green-dark2);
//     cursor: pointer;
//   }
//   &__stilt {
//     color: #53cab2;
//     margin-right: 10px;
//     margin-left: 5px;
//     font-size: 10px;
//   }
// }

@media only screen and(max-width:599px ) and (min-width: 400px) {
  .MuiTabs-flexContainer {
    .MuiButtonBase-root {
      margin: auto;
    }
  }
}
