.preview-startup{
  max-width: 1200px;
  margin: auto; 
  .preview-startup-container{
    margin-top: 30px;
    .card_padding{
        padding: 20px 30px !important;
    }
    &__user-message{
        font-family: 'Roboto';
        font-size: 20px;
        font-weight: 500;
        margin-left: 15px;
        margin-bottom: 30px;
        i {
            color: var(--ui-color-primary-green-dark2);
            font-size: 20px;
            margin-left: -15px;
            margin-right: 10px;
            cursor: pointer;      
            @media only screen and(max-width:768px) {
              font-size: 20px;
            }
      
            @media only screen and(max-width:500px) {
              margin-left: -20px;
            }
          }
       }
    &__user-name{
        text-transform: capitalize;
    }
        .row-flex{        
            @media only screen and(min-width:768px) {
                display: flex;   
                .col-flex{
                    display: flex;
                }
            }
        }
        .card{
            @media only screen and (min-width:768px) {
                padding:20px 30px;   
            }
            @media only screen and (max-width:768px) {
                padding:15px;   
            }
            .card-sub-heading{
                font-family: "Roboto";
                font-style: normal;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0.02em;
                padding: 25px 25px 0px 25px;
                color: var(--ui-color-gray-dark1);
            }
        }
        &__message{
            background-color: rgba(201,241,233,0.5);
            padding: 25px;
            border-radius: 10px;
            border: 2px solid var(--ui-color-primary-green-pale);
            font-family: "Proxima Nova";
            font-size: 16px;
            color: #000;
        }
        &__evaluation_request{      
            &__heading{
                font-family: "Proxima Nova";
                font-size: 21px;
                font-weight: bold;
            }
            &__message{
                font-family: "Proxima Nova";
                font-size: 16px;
                margin-top: 20px;
            }
            button{
                margin-top: 20px;
            }
        }
        .startup-info-card{
            display: flex;
            .logo {
                margin-top: 0px!important;
            }
            &__info{
                margin-left: 20px;
                .shared-by{
                    font-weight: bold;
                }
                &__contact-info{
                    display: flex;
                    flex-wrap: wrap;
                }
                .startup-info-container__location{
                    width:50%;
                    .name{
                        position: relative!important;
                    }
                }
                .slideDeckContainer{
                    background-color:#fff;
                    div{
                        
                        width: 50%;
                    }
                }
            }        
        }
        .webiste-name{
            color: var(--ui-color-primary-green-dark3)!important;
            font-weight: bold;
        }
        .preview-evaluation{
            padding: 20px 30px;
        }
        .preview-evaluation-questions__heading{
            font-weight: bold;
        }
        .preview-evaluation-questions__section{
            margin-top: 20px;
        }
        .preview-evaluation-questions{
            &__question{
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.03em;
                margin-top:10px;
                &__answerColor{
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.03em;
                    margin-top:10px;
                    color: var(--ui-color-primary-green-dark2);
                }
                &__commentColor{
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.03em;
                    margin-top:10px;
                    color: var(--ui-color-gray-dark4);
                    font-style: italic;
                }
            }
            .not_answer{
                font-family: "Proxima Nova" !important;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.03em;
                margin-top:10px;
                text-transform: capitalize;
            }
        }
        .preview-evaluation-questions__description{
            color: var(--ui-color-gray-pale2)!important;
            margin-bottom: 10px!important;
            margin-top:5px!important;
            font-weight: 500;
        }
        .preview-evaluation-questions__webiste-name{
            color: var(--ui-color-primary-green-dark3)!important;
            font-weight: bold!important;
            overflow-wrap: anywhere;
        }
        .preview-evaluation-questions__evaluation-answer{
            color: var(--ui-color-primary-green-dark3)!important;
            font-weight: bold!important;
            margin-bottom: 10px!important;
            margin-top: 0px!important;
        }
        .preview-evaluation-questions__description__evaluation{
            color: var(--ui-color-gray-pale2)!important;
            margin-bottom: 0px!important;
            margin-top:5px!important;
            font-weight: 500;
        }
        .evaluation-section{
            margin-top: 10px;
            padding-left: 0px!important;
        }
        .evaluation-section-template{
            font-family: "Proxima Nova";
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            padding: 0 !important;
            margin-top: 20px;
        }
    }
}

  
 