.startup-info-container {
  &__details {
    margin: 25px 40px;
    @media only screen and(max-width:905px) {
      margin-top: 50px !important;
    }

    .personalia {
      padding-top: 10px;
    }
  }
  .invite-edit-container {
    display: flex;
    @media only screen and(max-width:1168px) {
      flex-direction: column;
    }
    &__edit-button {
      @media only screen and(min-width:1168px) {
        margin-left: auto;
      }
      @media only screen and(max-width:1168px) {
        margin-top: 10px;
      }
      width: 180px !important;
    }
    &__share-button {
      height: auto !important;
      font-size: 12px !important;
      @media only screen and(min-width:500px) {
        width: 360px !important;
      }
    }
  }
  .share-btn {
    position: absolute;
    right: 40px;
    background: var(--ui-color-white);
    border-radius: 15px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: var(--ui-color-gray-medium);
    padding-left: 10px;
    height: 22px;
    padding-right: 20px;
    border: 1px solid var(--ui-color-gray-medium);
    top: 37px;
    cursor: pointer;
    width: 145px;
    z-index: 999;

    span {
      width: 100%;
      text-align: center;
    }

    @media only screen and (max-width: 920px) {
      width: 22px;
      span {
        display: none;
      }
    }
    @media only screen and (max-width: 905px) and (min-width: 200px) {
      top: 55px;
    }
  }

  .second-btn {
    top: 65px;
    @media only screen and (max-width: 905px) and (min-width: 200px) {
      top: 83px;
    }
  }

  .name-icon {
    overflow: hidden;
    background: linear-gradient(
      152.3deg,
      var(--ui-color-primary-orange-medium) 9.47%,
      var(--ui-color-primary-orange-dark2) 93.11%
    );
    margin-top: 25px;
    border-radius: 50%;
    text-align: center;
    padding-top: 10px;
    font-size: 20px;
    color: var(--ui-color-white);
    margin-left: 55px;

    img {
      transform: scale(1.6);
    }

    @media only screen and(max-width:905px) {
      margin-top: 50px !important;
    }
  }

  .with-logo {
    background: none;
  }

  &__heading {
    font-family: "proxima_nova_altbold";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: inline-block;
    color: var(--ui-color-gray-dark1);
    .material-icons {
      color: var(--ui-color-gray-pale2);
      font-size: 15px;
      margin-left: 10px;
    }
  }
  &__location {
    margin-top: 10px;
    color: var(--ui-color-gray-dark7);
    .name {
      position: absolute;
      margin-left: 5px;
      font-family: "Proxima Nova";
      font-size: 11px;
      line-height: 13px;
      color: var(--ui-color-gray-medium);
      margin-top: 3px;
    }
    .material-icons {
      font-size: 16px;
    }
  }
  &__startup-official {
    margin-top: 25px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: var(--ui-color-gray-medium);
    margin-left: 15px;
    @media only screen and (min-width: 768px) {
      margin-left: 18.33%;
    }
  }
  .startup-info-contact-details {
    margin-left: 15px;
    @media only screen and (min-width: 768px) {
      margin-left: 18.33%;
    }
    &__email,
    &__linkedin,
    &__phone {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-medium);
      margin-top: 12px;
      padding-left: 0px !important;
      .material-icons {
        font-size: inherit;
        position: absolute;
        margin-top: 2px;
      }
      .name {
        margin-left: 20px;
      }
      i {
        position: absolute;
        font-size: inherit;
        color: var(--ui-color-gray-dark7);
        margin-top: 4px;
        font-family: "FontAwesome";
      }
    }
  }
  .Startup-info__slideDeckContainer {
    display: flex;
    margin-right: 30px;

    .overviewWebsite {
      margin-left: 10px;
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;

      & > .overviewWebsite {
        padding-top: 10px;
      }
    }
  }
  .startup-info-notata-info {
    margin-left: 15px;
    display: flex;
    width: 100%;

    &__web {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      padding-left: 0px !important;
      color: var(--ui-color-primary-green-dark2);
    }
  }

  .startup-help-container {
    margin-top: 25px;
    margin-left: 10px;
    &__heading {
      font-family: Roboto;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-dark1);
    }
    p {
      font-family: "Proxima Nova";
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-medium);
      margin-top: 10px;
      margin-bottom: 50px;
    }
  }
  .startup-info-demo-container {
    &__heading {
      font-family: Proxima Nova;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin: 25px;
      color: var(--ui-color-gray-medium);
    }
    &__sub-heading {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      margin: 25px 0px 0px 25px;
      color: var(--ui-color-gray-dark1);
    }

    &__video {
      margin-top: 25px;
      background: hsla(0, 0%, 75.7%, 0.3411764705882353);
      width: 100%;
      border-radius: 0px;
      padding: 25px;
      position: relative;

      .coming-soon {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        justify-content: center;
        align-items: center;
        font-size: 21px;
        text-transform: uppercase;
        color: var(--ui-color-gray-dark7);
        z-index: 999;
        display: flex;

        .message-content {
          position: absolute;
        }

        .block-out {
          background-color: rgb(255 255 255 / 42%);
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          -webkit-filter: blur(4px);
          filter: blur(4px);
        }
      }

      img {
        position: relative;
        width: inherit;
      }
    }
    &__video-info {
      height: 45px;
      background: linear-gradient(
        115.95deg,
        var(--ui-color-primary-orange-medium) 50.04%,
        var(--ui-color-primary-orange-dark3) 97.03%
      );
      margin-top: -5px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    &__video-name {
      font-family: "Proxima Nova";
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: var(--ui-color-white);
      position: absolute;
      margin: 15px 0px 0px 15px;
    }
    &__video-source {
      font-family: Roboto;
      font-size: 9px;
      line-height: 11px;
      text-align: right;
      letter-spacing: 0.03em;
      color: var(--ui-color-white);
      margin: 17px 15px 0px 0px;
      right: 40px;
      position: absolute;
    }
    .key {
      font-family: Roboto;
      font-size: 12px;
      line-height: 13px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-medium);
      margin: 15px 0px 0px 26px;
    }
    .value {
      font-family: Roboto;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-dark1);
      margin: 15px 0px 0px 0px;
      white-space: pre-wrap;
    }
  }
  .handCursor {
    cursor: pointer;
  }
}
.logged_in_overflow {
  overflow-y: auto;
  overflow-x: hidden;
}
