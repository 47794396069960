.footer {
  width: 70%;
  display: flex;
  justify-content: space-between;

  // & > button {
  //   margin-right: 10px;
  // }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.footer1 {
  max-width: 560px;
  display: flex;
  padding-top: 15px;
  padding-right: 3px;
  justify-content: flex-end;
}

.error-msg {
  &__container {
    position: relative;
  }
  position: absolute;
  top: -100px;
  left: 35px;
  font-size: 12px;
  color: var(--ui-color-red);
}
