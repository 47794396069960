.table_headerChild {
  width: 50%;
}
input {
  outline: none;
}
.tableSearch {
  width: 299px !important;
  position: relative;
}
.table_headerChild input {
  width: 100% !important;
  height: 40px;
  background: var(--ui-color-white) !important;
  border: 1px solid var(--ui-color-gray-pale2);
  box-sizing: border-box;
  border-radius: 20px !important;
  padding-left: 38px;
  box-sizing: border-box;
  color: var(--ui-color-gray-dark7);
}
button,
.handCursor {
  cursor: pointer;
}
.tableSearch button {
  position: absolute;
  width: 94px;
  height: 40px;
  right: 0px;
  top: 0px;
  background-color: var(--ui-color-primary-green-dark2);
  border-radius: 20px;
  z-index: 3;
  color: var(--ui-color-white);
  border: none;
  outline: none;
}

.tableSearch button span:last-of-type {
  display: none;
}

.tableSearch button span:first-of-type {
  display: block;
}
.tableSearch i {
  position: absolute;
  left: 15px;
  /*top: 50%;*/
  top: 15px;
  transform: translateY(-50%);
  color: var(--ui-color-gray-dark7);
  font-weight: 600;
  font-size: 16px;
}
.table_headerInner {
  display: flex;
}

.table_headerChild .addButton {
  width: 173px;
  height: 40px;
  background-color: var(--ui-color-primary-green-dark2);
  border-radius: 20px;
  color: var(--ui-color-white);
  border: none;
  outline: none;
  margin-right: 30px;
  cursor: pointer;
}

.addButton:hover,
.addButton:focus,
.searchButton:hover,
.searchButton:focus {
  background-color: var(--ui-color-primary-green-dark3);
}
.inputStyles {
  margin-top: 0px;
}

.table_headerChild .addButton i {
  color: var(--ui-color-white);
  margin-right: 5px;
}
.filterButton {
  width: 105px;
  height: 40px;
  border: 1px solid var(--ui-color-gray-medium);
  box-sizing: border-box;
  border-radius: 20px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  color: var(--ui-color-gray-medium);
  margin-top: 7px;
}
.filterButton img {
  width: 11px;
  margin-right: 6px;
  margin-top: -3px;
}
.filterContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: -7px;
}
.table_headerChildMiddle {
  display: flex;
  justify-content: center;
  margin-top: -10px;
}
.table_headerChildMiddle button {
  background-color: transparent;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  border: none;
  outline: none;
  padding: 0 17px;
  margin-left: 16px;
}
.table_headerChildMiddle button img {
  height: 8.3px;
  transform: rotate(90deg);
  margin-right: 5px;
}
.manageButton {
  width: 200px;
}

.table_headerChildMiddle button {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1450px) {
  .manageButton span {
    display: none;
  }
  .manageButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
  }
  .manageButton img {
    margin-right: 0;
    width: 13px;
    margin-right: 0px;
    padding: 0 !important;
    margin-top: 0px;
  }
  .filterButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
  }
  .filterButton img {
    margin-right: 0;
    width: 13px;
  }
  .filterButton span {
    display: none;
  }
}
@media screen and (max-width: 1178px) {
  .addButton {
    width: 200px !important;
  }

  .addButton i {
    display: none;
  }

  .tableSearch {
    width: 50% !important;
  }
  .tableSearch button {
    width: 40px;
  }

  .tableSearch button span:last-of-type {
    display: block;
  }

  .tableSearch button span:first-of-type {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .table_headerChild {
    width: 100% !important;
  }
  .table_headerInner {
    width: 100%;
    justify-content: space-between;
  }
  .table_headerChildLast {
    width: 30% !important;
  }
  .table_headerChildMiddle {
    width: 70% !important;
    justify-content: flex-start;
  }
  .table_headerChildFirst {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 450px) {
  .tableSearch button {
    display: none;
  }
  .table_headerChildMiddle {
    justify-content: space-between;
  }

  .addButton {
    width: 50% !important;
  }

  .clearAllTxt {
    color: var(--ui-color-primary-green-dark2);
    transition: color 0.1s ease-in;
    font-weight: 600;
  }
  .clearAllTxt p {
    padding-top: 20px;
  }
}

/* scrolling needed */
/* scrolling issue fix in manage column and right sidebar filter   */

.override * {
  overflow: initial;
}

.grayFadeOut {
  opacity: 0.4;
}

.clearAllTxt {
  color: var(--ui-color-primary-green-dark2);
  transition: color 0.1s ease-in;
  font-weight: 600;
  text-align: right;
  margin-bottom: -20px;
}

.clearAllTxt p {
  padding-top: 20px;
}

.clearAllTxt:hover {
  color: var(--ui-color-primary-green-dark3);
}

//*******************************************************//
.container {
  border-radius: 7px;
  overflow: hidden;

  &_mini {
    @extend .container;
    margin-left: auto;
    width: 192px;
  }
}

.content {
  padding: 10px;
  padding-bottom: 15px;
  border-top: 1px solid var(--color-gray-light);
}

.footer {
  position: relative;
}

.filter_star {
  font-size: 18px;
  cursor: pointer;
}

.filter_icon_container {
  cursor: pointer;
  cursor: pointer;
}

.filter_icon_container {
  cursor: pointer;
}

.filter_icon {
  color: var(--color-primary);
  font-size: 30px;
}

.tag_each {
  position: relative;
  padding-right: 16px;
}

.tag_kill {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-gray-medium);
  cursor: pointer;
}

.filter_container {
  display: flex;
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .filter_container {
    flex-wrap: wrap;
  }
}
.table_headerChild {
  width: 50%;
}

.filter_content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
}

.funnel_tag_container {
  text-align: center;
  position: relative;
}

.funnel_tag {
  background: var(--color-gray-light);
  color: white;
  margin: auto;
  margin-bottom: 2px;
  cursor: pointer;
}

.funnel_tag_active {
  background: var(--color-primary);
}

.pb_sm {
  margin-bottom: 15px;
  overflow: visible;
}

.pb_lg {
  margin-bottom: 30px;
  overflow: visible;
}
