.group-plugin {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  &__no_margin {
    margin: 0px !important;
  }

  &__content{
    max-width: 1040px;
    width:100%;
    @media only screen and(min-width:900px) {
      width:900px;
    }
  }
}

