// funnels
.funnels-container {
  margin-top: 15px;
  //.small{
  //  color: red !important;
  //}

  &__funnels {
    &__funnel {
      position: relative;
      margin-bottom: 30px;
      @media only screen and(max-width:500px) {
        margin-top: 10px;
        margin-left: 0;
      }
      &__heading {
        font-weight: bold;
        font-size: 16px;
        line-height: 17px;
        color: var(--ui-color-gray-dark1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          cursor: pointer;
          color: var(--ui-color-gray-pale2);
          &:hover {
            color: var(--ui-color-gray-dark5) !important;
          }
        }
        &__dropdown {
          position: absolute;
          box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
          border-radius: 5px;
          z-index: 999;
          text-align: initial;
          margin-top: 1px;
          margin-left: 40px;
        }
      }
      .parts {
        margin-top: 20px;
        max-width: 400px;

        &__part {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          justify-content: space-between;
          margin-top: 5px;

          &__heading {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-transform: capitalize;
            color: var(--ui-color-gray-medium);
            width: 100%;

            @media only screen and(max-width:500px) {
              font-size: 12px;
            }
          }

          &__color_block {
            width: 180px;
            display: flex;
            justify-content: center;
            .gray {
              background: linear-gradient(
                              180deg,
                              var(--ui-color-gray-pale1) 0%,
                              var(--ui-color-gray-pale2) 100%
              );
              height: 16px;
              border-radius: 10px;
            }
            .red {
              background: linear-gradient(
                              180deg,
                              var(--ui-color-primary-orange-dark2) 0%,
                              var(--ui-color-primary-orange-medium) 100%
              );
              height: 16px;
              border-radius: 10px;
            }
            .blue {
              background: linear-gradient(
                              180deg,
                              var(--ui-color-primary-green-dark1) 0%,
                              var(--ui-color-primary-green-medium) 100%
              );
              height: 16px;
              border-radius: 10px;
            }
            .purple {
              background: linear-gradient(180deg, #9e80dd 0%, #c7adff 100%);
              height: 16px;
              border-radius: 10px;
            }
            .orange {
              background: linear-gradient(
                              180deg,
                              var(--ui-color-secondary-yellow-dark) 0%,
                              var(--ui-color-secondary-yellow) 102.82%,
                              rgba(229, 232, 255, 0) 110.91%
              );
              height: 16px;
              border-radius: 10px;
            }
            .green {
              background: linear-gradient(180deg, #90e074 0%, #b3ff78 100%);
              height: 16px;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }

  .funel-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: var(--ui-color-gray-medium);
    margin-top: 5px;
  }
  .met {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--ui-color-gray-dark1);
    margin-top: 10px;
  }
  .fa-times {
    background-color: var(--ui-color-primary-orange-dark1);
    border-radius: 50%;
    height: 18px;
    width: 18px;
    text-align: center;
    font-size: 10px;
    margin-top: 10px;
    padding-top: 4px;
    color: var(--ui-color-white);
    cursor: pointer;
  }
  .add-new-funel {
    max-width: 190px;
    margin-left: 100px;
    @media only screen and(max-width:990px) {
      margin-top: 15px;
      margin-left: 5px;
      max-width: 300px;
    }
  }
  .dropdown {
    margin-top: 10px;
  }
  .bar {
    height: 10px;
    width: 120px;
    background-color: rgba(239, 241, 250, 1);
    border-radius: 6px;
    margin-top: 5px;
    .highligh1 {
      background: var(--ui-color-primary-orange-dark1);
      height: inherit;
      width: inherit;
      border-radius: inherit;
    }
    .highligh2-90 {
      background: var(--ui-color-primary-green-dark2);
      height: inherit;
      width: 92px;
      margin-left: 14px;
      border-radius: inherit;
    }
    .highligh2-76 {
      background: var(--ui-color-primary-green-dark2);
      height: inherit;
      width: 76px;
      margin-left: 22px;
      border-radius: inherit;
      background: var(--ui-color-gray-pale2);
    }
    .highligh2-58 {
      background: var(--ui-color-primary-green-dark2);
      height: inherit;
      width: 58px;
      margin-left: 31px;
      border-radius: inherit;
      background: var(--ui-color-gray-pale2);
    }
    .highligh2-30 {
      background: var(--ui-color-primary-green-dark2);
      height: inherit;
      width: 30px;
      margin-left: 45px;
      border-radius: inherit;
      background: var(--ui-color-gray-pale2);
      .bar {
        height: 10px;
        width: 120px;
        background-color: var(--ui-color-gray-pale1);
        border-radius: 6px;
        margin-top: 5px;
        .highligh1 {
          background: var(--ui-color-primary-orange-dark1);
          height: inherit;
          width: inherit;
          border-radius: inherit;
        }
        .highligh2-90 {
          background: var(--ui-color-primary-green-dark2);
          height: inherit;
          width: 92px;
          margin-left: 14px;
          border-radius: inherit;
        }
        .highligh2-76 {
          background: var(--ui-color-primary-green-dark2);
          height: inherit;
          width: 76px;
          margin-left: 22px;
          border-radius: inherit;
          background: var(--ui-color-gray-pale2);
        }
        .highligh2-58 {
          background: var(--ui-color-primary-green-dark2);
          height: inherit;
          width: 58px;
          margin-left: 31px;
          border-radius: inherit;
          background: var(--ui-color-gray-pale2);
        }
        .highligh2-30 {
          background: var(--ui-color-primary-green-dark2);
          height: inherit;
          width: 30px;
          margin-left: 45px;
          border-radius: inherit;
          background: var(--ui-color-gray-pale2);
        }
      }
    }
    .add-funel-container {
      @media only screen and(min-width:991px) {
        position: absolute;
        right: 20px;
      }
    }
  }
}
