//this is Report component Scss
.report-container {
  padding-top: 15px;
  .funnel-parent {
    @media only screen and (min-width: 1199px) {
      padding: 0px !important;
    }
    @media only screen and(max-width:1200px) {
      padding-left: 0px !important;
    }
    @media only screen and(max-width:768px) {
      padding-right: 10px !important;
    }
  }
  .group-parent {
    //@media only screen and (min-width:1199px) {
    padding: 0px !important;
    //}
  }
  .tags-container-parent {
    // @media only screen and (min-width:1199px) {
    padding-left: 0px !important;
    //}
    @media only screen and (max-width: 992px) {
      padding-right: 10px;
    }
    @media only screen and(max-width:768px) {
      padding-right: 10px !important;
    }
  }
  .tags-super-parent {
    :nth-child(3n) {
      @media only screen and(min-width:1200px) {
      padding-right: 0px;
      }
    }
  }
  &__add-icon-container {
    display: flex;
    justify-content: flex-end;

    &__add-icon {
      width: 48px;
      height: 48px;
      background: var(--ui-color-primary-orange-dark2);
      margin-bottom: 15px;
      border-radius: 50%;
      display: flex !important;
      align-items: center;
      justify-content: center;
      color: var(--ui-color-white);
      margin-top: 15px;
    }
  }
  .funnnel-card {
    @media only screen and(min-width:1200px) {
      height: 427px;
    }
  }

  .filter-btn-container {
    margin: 15px 0;
    float: right;
  }

  .reports-funnels-container {
    padding-top: 30px;
    @media only screen and(max-width:991px) {
      padding-bottom: 20px;
    }
    @media only screen and(max-width:400px) {
      padding-left: 15px;
    }
    &__filter {
      display: flex;
      &__stages {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: var(--ui-color-primary-green-dark2);
        padding-top: 7px;
        cursor: pointer;
        &__downup {
          margin-left: 10px;
          font-size: inherit;
          cursor: pointer;
        }
        &__dropdown {
          position: absolute;
          border-radius: 5px;
          cursor: context-menu;
          z-index: 999;
          text-align: left;
          text-align: initial;
          margin-top: 6px;
          margin-left: 200px;
        }
      }
      &__icons {
        margin-left: auto;
        @media only screen and(min-width:991px) {
          position: absolute;
          right: 10px;
        }
        i {
          color: var(--ui-color-gray-dark7);
          font-size: 12px;
          margin-right: 10px;
          &:hover {
            color: #000;
            border-bottom: 1px solid #000;
          }
        }
      }
    }
    &__funnels {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      &__funnel {
        width: 50px;
        .score {
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.03em;
          color: var(--ui-color-gray-dark1);
          text-align: center;
        }
        .funnel-chart {
          margin-top: 10px;
          height: 203px;
          width: 24px;
          background-color: var(--ui-color-gray-pale1);
          margin-left: 13px;
          margin-right: 13px;
          border-radius: 20px;
          transform: scale(-1, -1);
          // transform: rotate(90deg);
          .bar-0 {
            height: 100%;
          }
          .bar-1 {
            height: 80%;
            transform: translate(0px, calc(203px * 0.1));
          }
          .bar-2 {
            height: 60%;
            transform: translate(0px, calc(203px * 0.2));
          }
          .bar-3 {
            height: 40%;
            transform: translate(0px, calc(203px * 0.3));
          }
          .bar-4 {
            height: 20%;
            transform: translate(0px, calc(203px * 0.4));
          }

          .red {
            background: linear-gradient(
              180deg,
              var(--ui-color-primary-orange-dark3) 0%,
              var(--ui-color-primary-orange-dark1) 100%
            );
            border-radius: 20px;
          }
          .blue {
            background: linear-gradient(
              180deg,
              var(--ui-color-primary-green-dark4) 0%,
              var(--ui-color-primary-green-dark1) 100%
            );
            border-radius: 20px;
          }
          .purple {
            background: linear-gradient(180deg, #9e80dd 0%, #c7adff 100%);
            border-radius: 20px;
          }
          .orange {
            background: linear-gradient(
              180deg,
              var(--ui-color-secondary-yellow) 0%,
              var(--ui-color-secondary-yellow-dark) 102.82%,
              rgba(229, 232, 255, 0) 110.91%
            );
            border-radius: 20px;
          }
          .green {
            background: linear-gradient(180deg, #90e074 0%, #b3ff78 100%);
            border-radius: 20px;
          }
        }
        .funnel-type {
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.03em;
          margin-top: 10px;
          text-transform: uppercase;
          color: var(--ui-color-gray-medium);
          transform: rotate(30deg);
          margin-left: 20px;
        }
      }
    }
    &__data-container {
      padding: 20px 20px 40px 25px;
      @media only screen and(max-width:1199px) and (min-width: 991px) {
        padding-left: 20px;
      }
      &__data-summary {
        padding-top: 7px;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-right: 20px;
        color: var(--ui-color-gray-medium);
        .no-of-records {
          margin-left: auto;
        }
      }
      &__data-headers {
        font-family: Roboto;
        font-size: 10px;
        line-height: 12px;
        color: var(--ui-color-gray-dark7);
        margin-top: 25px;
      }
      &__data-record {
        margin-top: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        .funnel-name {
          font-family: "Roboto";
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.03em;
          color: var(--ui-color-gray-dark1);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .funnel-date {
          font-family: "Roboto";
          font-size: 10px;
          line-height: 12px;
        }
      }
      .separator {
        padding-top: 0px;
        margin-left: 0px;
      }
    }
  }

  //this is GroupInfoCards component scss (Line Number: 282 to 372)

  .my-groups-container {
    @media only screen and(min-width:1200px) {
      padding-right: 0px;
    }
    @media only screen and(max-width:1200px) {
      padding-left: 0px !important;
      padding-right: 10px !important;
    }
    .group-info-card {
      min-height: 208px;
      @media only screen and(min-width:768px) {
        margin-bottom: 0px !important;
      }
    }
    &__data{
      margin-top: 20px;
    }
    &__noOfRecords {
      font-weight: 300;
      font-size: 68px;
      line-height: 83px;
      letter-spacing: -0.04em;
      color: var(--ui-color-gray-dark1);
      margin-top: 10px;
      width: calc(100% - 120px);
      border-right: 1px solid var(--ui-color-gray-pale2);
      @media only screen and(min-width:991px) and(max-width:1199px) {
        border-right: 1px solid var(--ui-color-gray-pale2);
      }
      padding-left: 25px;
      @media only screen and(max-width:400px) {
        padding-left: 15px;
      }
      @media (min-width:1000px) and (max-width:1199px) {
         width: calc(86% - 120px);
      }
      @media (min-width:650px) and (max-width:768px) {
        width: calc(69% - 120px);
      }
      @media (min-width:445px) and (max-width:650px) {
        width: calc(79% - 120px);
      }
      @media (min-width:360px) and (max-width:450px) {
        width: calc(89% - 120px);
      }
      @media (min-width:0px) and (max-width:360px) {
        width: calc(95% - 120px);
      }
      @media (max-width:1500px) {
        border: none;
      }
      @media (max-width:1380px) {
        font-size: 58px;
      }
      @media (min-width:1200px) and (max-width:1270px) {
        font-size: 50px;
      }
      @media (max-width:1200px) {
        border-right: 1px solid var(--ui-color-gray-pale2);
      }
      @media (min-width:768px) and (max-width:815px) {
        border: none;
      }
    }
    &__last-week-comparison {
      margin-left: 30px;
      display: inline-block;
      .performance {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
      .green {
        color: var(--ui-color-primary-green-dark4);
      }
      .red {
        color: var(--ui-color-red);
      }
      .since-last-month {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 10px;
        margin-left: 5px;
        color: rgba(0, 0, 0, 0.4);
      }
      .material-icons {
        font-size: 15px;
        margin-left: 5px;
      }
    }
    &__footer-link {
      margin-right: 20px;
      position: absolute;
      bottom: 20px;
      right: 10px;
      .underline{
        text-decoration: none;
        :hover{
          color: var(--ui-color-primary-green-dark4);
        }
      }
      
      .go-to-my-groups {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: var(--ui-color-primary-green-dark4);
        display: inline-block;
        cursor: pointer;
      }
      .green {
        color: var(--ui-color-primary-green-dark4);
        cursor: pointer;
      }
    }
    img {
      position: absolute;
      top: 95px;
      right: 50px;
    }
  }
  @media (min-width:786px) and (max-width:1200px){
    .right_card_data,.left_card_data{
      // background-color: red;
      padding-right: 15px !important;
    }
  }
  .tags-container-heading {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--ui-color-gray-dark1);
    margin-top: 15px;
  }
  .no-padding {
    padding: 0px !important;
  }
}
