/* Customize the label (the container) */
.checkbox_container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 17px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: var(--ui-color-gray-medium);
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          background-color: var(--ui-color-primary-green-dark2);
          border: none;
          animation: color-me-in 1s;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: var(--ui-color-white);
    border: 1px solid var(--ui-color-gray-pale2);
    border-radius: 2px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid var(--ui-color-white);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

@keyframes color-me-in {
  /* You could think of as "step 1" */
  0% {
    background: var(--ui-color-white);
  }
  /* You could think of as "step 2" */
  100% {
    background: var(--ui-color-primary-green-dark2);
  }
}
