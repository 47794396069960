.PrivateTabIndicator-colorPrimary-2 {
  background-color: var(--ui-color-primary-green-dark2) !important;
  height: 5px !important;
  border-radius: 6px !important;
}
.PrivateTabIndicator-colorPrimary-3 {
  background-color: var(--ui-color-gray-dark1) !important;
  height: 5px !important;
  border-radius: 6px !important;
}
