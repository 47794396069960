.news-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  .delete-btn {
    font-size: 12px;
    color: var(--color-primary);
    cursor: pointer;
  }
  .btn-content{
    text-align: center;
    padding-top: 10px;
  }
  &__main {
    width: 680px;
    @media only screen and(min-width:768px) {
      margin-top: 20px;
    }
  }
  &__create-container {
    &__heading {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-transform: capitalize;
      color: var(--ui-color-gray-medium);
    }
    &__title {
      width: 100%;
      background: var(--ui-color-white);
      border: 1px solid var(--ui-color-gray-pale2);
      box-sizing: border-box;
      border-radius: 20px;
      height: 40px;
      margin-top: 5px;
      padding-left: 15px;
    }
    &__multi-selects {
      @media only screen and(min-width:768px) {
        display: flex;
        justify-content: space-between;
      }
      .react-multi-style {
        @media only screen and(max-width:768px) {
          margin-top: 15px;
        }
      }
    }
    &__news-buinder {
      background: var(--ui-color-white);
      border: 1px solid var(--ui-color-gray-pale2);
      box-sizing: border-box;
      border-radius: 8px;
      margin-top: 25px;
      height: 143px;
      width: 100%;
      resize: none;
      padding: 10px 12px;
      font-family: Proxima Nova !important;
    }
    &__footer {
      display: flex;
      margin-top: 18px;
      flex-wrap: wrap;
      &__news-by {
        @media only screen and(max-width:768px) {
          flex: 1 0 90%;
        }
      }
      &__date {
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-medium);
      }
      &__name {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark1);
      }
      &__upload-image {
        margin-left: auto;
        @media only screen and(max-width:768px) {
          align-items: flex-end;
          flex: 1 0 5%;
        }
        img {
          cursor: pointer;
          @media only screen and(max-width:768px) {
            margin-left: auto;
          }
        }
      }
      &__action-btns {
        @media only screen and(max-width:768px) {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 10px;
        }

        .hidden-file-input {
          label {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            input {
              display: inline-block;
              opacity: 0;
              position: absolute;
              z-index: 99;
              cursor: pointer;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              cursor: pointer;
              //pointer-events: none;
            }
          }
        }
      }
    }
  }
  &__heading {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--ui-color-gray-dark1);
    @media only screen and (max-width: 768px) and (min-width: 200px) {
      position: relative;
      top: 5px;
      left: 21px;
    }

    .new-icon {
      padding-left: 10px;
      cursor: pointer;
      color: var(--ui-color-primary-green-dark2);
    }
  }
  &__card {
    margin-top: 15px;
    @media screen and (max-width:885px) {
      padding-right: 15px;
    }
    &__heading {
      .edit-icon {
        color: var(--ui-color-primary-green-dark1);
        cursor: pointer;
        font-size: 16px;
        padding-left: 10px;
      }
    }
    &__news {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-medium);
      margin-top: 15px;
      white-space: pre-wrap;
      &__partition {
        overflow-wrap: anywhere;
      }
      &__img {
        float: left;
        //max-width: 190px;
        max-width: 100%;
        box-shadow: 0px 0px 5px 2px rgba(200,200,200,0.3);
        margin-bottom: 20px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
        //@media only screen and(max-width:768px) {
        //  margin: 24px;
        //  margin-left: 45px;
        //}
      }
    }
    &__footer {
      display: flex;
      margin-top: 25px;
      padding-top: 25px;
      border-top: 1px solid rgba(200, 200, 200, 0.3);


      &__like-dislike {
        &__text {
          font-family: "Roboto";
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.03em;
          color: var(--ui-color-gray-dark1);
        }
        &__icons {
          margin-top: 8px;
          img {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
      &__news-by {
        margin-left: auto;
        &__date {
          font-family: "Roboto";
          font-size: 10px;
          line-height: 20px;
          text-align: right;
          letter-spacing: 0.03em;
          color: var(--ui-color-gray-medium);
        }
        &__name {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          text-align: right;
          letter-spacing: 0.03em;
          color: var(--ui-color-gray-dark1);
          margin-top: 10px;
        }
      }
    }
  }

  &__bottom-container {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
}