.text-box {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  border-radius: 20px;
  border: 1px solid var(--ui-color-gray-pale2);
  margin-bottom: 5px;
  padding: 12px 15px;
  max-width: 100%;
}

.max-text-box {
  // max-width: 100%;
}
