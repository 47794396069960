.react-multi-style {
  &__menu {
    background: var(--ui-color-white);
    box-shadow: 0px 3px 12px rgba(41, 70, 103, 0.2) !important;
    border-radius: 8px;
    margin-top: -8px;
  }
  &__option {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px !important;
    color: var(--ui-color-gray-dark1);
    background-color: var(--ui-color-white) !important;
    display: flex !important;
    align-items: flex-end;
    input {
      height: 18px;
      width: 18px;
    }
    &--is-selected {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      color: var(--ui-color-gray-dark1);
    }
    &--is-focused {
      background-color: var(--ui-color-white) !important;
    }
    &:hover {
      background-color: var(--ui-color-white) !important;
    }
  }
  button {
    min-width: 300px !important;
    height: 40px !important;
    background: var(--ui-color-white);
    border: 1px solid var(--ui-color-gray-pale2);
    box-sizing: border-box;
    border-radius: 20px;
    margin-top: 5px;
    span {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: var(--ui-color-gray-dark1);
      padding-left: 15px;
    }
  }
  .multi-custom-select{
    min-width: 300px !important;
    height: 40px !important;
    background: var(--ui-color-white);
    border: 1px solid var(--ui-color-gray-pale2);
    box-sizing: border-box;
    border-radius: 20px;
    margin-top: 5px;
    display: flex;
    cursor:pointer;
    align-items: center;
      @media screen and (max-width:370px) {
        min-width: 250px !important;
      }
    span{
      font-family: "Proxima Nova";
      font-style: normal;
      font-size: 15px;
      color: var(--ui-color-gray-dark1);
      margin-left: 20px;
    }
    i{
      font-size: 11px;
      margin-left: auto;
      margin-right: 12px;
      cursor: pointer;
    }
    
    &__options{
      max-height: 300px;
      max-width: 300px;
      min-width: 300px !important;
      overflow-y: auto;
      padding: 10px;
      position: relative;
      -webkit-overflow-scrolling: touch;
      box-sizing: border-box;
      position: absolute;
      z-index: 99999;
      background: white;
      background: var(--ui-color-white);
      box-shadow: 0px 3px 12px rgb(41 70 103 / 20%) !important;
      border-radius: 8px;
      margin-top: -8px;
      @media screen and (max-width:370px) {
        min-width: 240px !important;
        margin-left: 5px;
        margin-top:-1px;
        padding-bottom:0px;
      }
      .checkbox_container{
        p{
          font-family: "Proxima Nova";
          font-style: normal;
          font-size: 15px;
          color: var(--ui-color-gray-dark1);
          cursor: pointer;
        }
      }
    }
  }
}

