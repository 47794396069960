.browse {
  color: var(--ui-color-gray-pale2);
  position: absolute;
  right: 20px;
  top: 15px;

  &:hover {
    color: var(--color-gray-dark);
  }
  .selected {
    color: var(--color-gray-dark);
  }

  &__drop-down {
    font-family: "Proxima Nova";
    position: absolute;
    padding: 10px 10px;
    z-index: 999;
    margin-top: -10px;
    text-align: initial;
    right: 0px;
    @media only screen and(max-width:768px) {
      right: 10px;
    }
    &__content {
      box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
      padding: 10px 25px;
      background: var(--ui-color-white);
      min-width: 149px;
      border-radius: 5px;
      position: relative;

      &__item {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        padding-top: 8px;
        padding-bottom: 8px;
        color: var(--ui-color-gray-dark1);
        display: flex;
        padding-left: 8px;
        cursor: pointer;

        &:hover {
          span {
            color: var(--color-gray-dark);
          }
        }

        a {
          text-decoration: none;
          color: var(--ui--color-gray-dark1);
          cursor: pointer;
        }

        .icon {
          display: inline;
          font-size: 12px;
          margin-right: 10px;
          position: absolute;
          left: 14px;
        }
        .text {
          display: inline;
          margin-top: 0.6px !important;
          text-transform: uppercase;
        }
      }
    }

    .selected {
      color: var(--ui-color-primary-green-dark2);
      font-weight: bold;
    }
  }
}
