.evaluation-list {
  // margin: 20px 15px !important;

  &__header-row {
    margin-bottom: 12px;

    .eval-score-heading {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: var(--ui-color-gray-dark1);
    }

    .submissions {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: var(--ui-color-gray-dark1);
      @media only screen and(max-width: 576px) {
        display: none;
      }
    }

    .score {
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: var(--ui-color-gray-medium);
      text-align: right;
      padding: 0;
      position: relative;

      .score-content {
        margin-right: 60px;
        color: var(--ui-color-gray-dark1);
        @media only screen and(max-width: 576px) {
          margin-right: 10px;
        }
      }
    }
  }

  &__each-row {
    padding-top: 8px;
    padding-bottom: 5px;
    @media (max-width:576px) {
      padding-right: 6px;
    }

    &__name {
      font-family: "Roboto";
      font-size: 16px;
      line-height: 18px;
      color: #6A6A6A;
      i {
        font-size: 14px;
        color: var(--ui-color-primary-green-dark2);
        padding-left: 7px;
        cursor: pointer;
      }
    }

    &__date {
      font-family: "Roboto";
      font-size: 16px;
      line-height: 18px;
      color: rgba(106, 106, 106, 0.6);
      @media only screen and(max-width: 576px) {
        display: none;
      }
    }

    &__score {
      font-family: "Proxima Nova";
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: var(--ui-color-gray-medium);
      text-align: right;
      padding: 0;
      position: relative;
      //margin-top: 10px;
      .score-content {
        margin-right: 60px;
        @media only screen and(max-width: 576px) {
          margin-right: 10px;
        }
        .action-icons {
          position: absolute;
          right: -5px;
          top: 0;
          font-size: 14px;
          color: var(--ui-color-primary-green-dark2);
          width: 50px;
          text-align: left;
          i {
            padding-right: 10px;
            cursor: pointer;
          }
          @media only screen and(max-width: 576px) {
            display: none;
          }
        }
        .pen_action_icon{
          position: absolute;
          right: -30px;
          top: 0;
          font-size: 14px;
          color: var(--ui-color-primary-green-dark2);
          width: 50px;
          text-align: left;
          i {
            padding-right: 10px;
            cursor: pointer;
            color: var(--ui-color-primary-green-dark2);
          }
          @media only screen and(max-width: 576px) {
            display: none;
          }
        }
      }
    }
  }

  &__each-row:nth-child(odd) {
    background-color: rgb(223 233 245 / 20%);
  }
}
