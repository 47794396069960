.evaluation-modal {
  display: flex;
  position: relative;
  justify-content: center;


  &__action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    @media only screen and(max-width: 500px) {
      flex-direction: column;
      align-items: center;
    }
  }

}

.evaluation-display {

  padding: 30px 10px 30px 20px;
  width: 100%;

  @media only screen and(max-width: 500px) {
    padding: 30px 10px 30px 12px;
  }

  &__summary {
    &__template-name {
      font-family: "Proxima Nova";
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      padding: 0 !important;
      color: var(--ui-color-gray-dark1);
    }

    &__template-by {
      font-family: "Proxima Nova";
      font-size: 14px;
      line-height: 18px;
      margin-top: 5px;
      color: var(--ui-color-gray-medium);
    }

    &__heading {
      font-family: "Proxima Nova";
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      padding: 0 !important;
      margin-top: 30px;
      color: var(--ui-color-gray-dark1);
    }

    &__section {
      display: flex;
      font-family: "Proxima Nova";
      font-size: 15px;
      line-height: 19px;
      padding-left: 20px;
      margin-left: -20px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 5px;

      &__points {
        margin-left: auto;
      }

      &:nth-child(odd) {
        background-color: rgba(223, 233, 245, 0.2);
      }
    }


  }

  &__section {

    padding-bottom: 10px;
    padding-top: 10px;

    &__header {
      margin-left: -15px;
      margin-top: 25px;
      display: flex;
      align-items: baseline;
      @media only screen and(max-width: 500px) {
        flex-direction: column;
      }

      &__label {

        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 19px;
        letter-spacing: 0.03em;


        i {
          font-size: 16px;
          color: var(--ui-color-primary-green-dark2);
          margin-left: -10px;
          cursor: pointer;
          margin-right: 10px;
        }
      }

      &__score {

        font-size: 14px;
        margin-left: 15px;
        margin-top: 15px;

        @media only screen and(min-width: 500px) {
          margin-left: auto;
        }
      }

    }

    &__body {
      margin-top: 15px;

    }

  }

}




