@keyframes timer {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.timer_loader {
  text-align: center;
  width: 100%;
  position: absolute;
  opacity: 1;
  animation-name: timer;
  animation-duration: 4s;
  .timer_header {
    font-size: 24px;
    padding-bottom: 27px;
  }
}

.tabs_section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
  margin-top: -10px;
  .tabs_section_child_active {
    background-color: var(--ui-color-primary-green-dark2);
    width: 30%;
    height: 5px;
    border-radius: 5px;
  }
  .tabs_section_child {
    width: 30%;
    height: 5px;
    border-radius: 5px;
    background-color: rgb(223, 223, 223);
    cursor: pointer;
  }
}
.profile_onboard {
  font-family: var(--proxima);
  max-width: 100vw;
  overflow: hidden;
  transition: all;
  transition-duration: 1s;
  margin-bottom: 200px;
  input {
    color: var(--ui-color-gray-dark1);
    font-size: 14px;
    caret-color: var(--ui-color-gray-dark1);
  }
  label {
    font-size: 14px;
  }
  .strip {
    background-color: var(--ui-color-primary-green-dark2);
    color: var(--ui-color-white);
    text-align: center;
    padding: 9px 20px;
  }
}
.dots_container {
  display: flex;
  justify-content: center;
}

// Page1 & Page3
.profile3 {

  padding-bottom: 20px;

  .profil_heading {
    margin: 0;
    padding: 0;
    margin-top: 20px;
  }
  .profile_subhead {
    margin: 0px 0 30px 0;
    line-height: 17px;
    font-size: 14px;
    color: #969ba3;
  }
  .profile_form {
    .profile_tags {
      margin: 0;
      padding: 0;
      margin-top: 8px;
    }
    .tag_input {
      margin-top: 10px;
      max-width: 340px;
    }
    .profile_radio {
      margin: 0;
      padding: 0;
      margin-top: 25px;
      margin-bottom: 20px;
    }
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.03em;
    color: var(--ui-color-gray-dark1);
  }
  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.03em;
  }
  h6 {
    margin-bottom: 20px;
    margin-left: 5px;
    font-size: 15px;
    span {
      border: 1xp solid grey;
      font-size: 14px;
      padding: 1px 10px;
      color: var(--ui-color-gray-medium);
      border: 1px solid var(--ui-color-gray-medium) !important;
      border-radius: 10px;
      font-weight: 500;
    }
  }
  .button_container {
    margin-top: 0;
    margin-left: -4px;
    margin-right: 30px;
    width: 100%;
    display: flex;
    //justify-content: flex-end;
    justify-content: space-between;
    button {
      height: 33px;
    }
  }
}

// Page2
.button_container_justify {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .right_btn {
    margin-right: -10px;
  }
}
.tagContainer {
  max-width: 340px;
}
.success_msg {
  margin-top: 20px;
  font-size: 12px;
  justify-self: flex-end;
  color: var(--ui-color-primary-green-dark2);
  position: absolute;
  top: 24px;
  left: 4px;
}

// Page1 & Page3 & Page2
.button_container {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dot {
  border-radius: 20px;
  background: var(--color-gray-light);
  width: 10px;
  height: 10px;
  margin: 10px;
}
.dot_active {
  background: var(--color-gray-medium);
}
.message_box {
  margin-bottom: 10px;
}

label {
  font-weight: 700 !important;
}

@media screen and (max-width: 500px) {
  .profile_onboard input {
    width: 100%;
  }

  .profile3 h1 {
    font-size: 18px !important;
    line-height: 22px !important;
    letter-spacing: 0.03em;
  }
}
