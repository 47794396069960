.settingsWeb-form-container {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__card {
    max-width: 700px;
  }

  .settingsWeb-form {
    &__description {
      font-size: 16px;
      line-height: 19px;
      color: var(--ui-color-gray-medium);
    }
  }
}
