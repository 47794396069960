.startup-grid-view-container{
  display: flex;
  flex-wrap: wrap;
  &__startup{
    position: relative;
    padding-bottom: 75px!important; /*extra 45px is for buttons */
    width: calc(calc(100% / 3) - 7px);
    @media only screen and(min-width:768px) and (max-width:1050px) {
      width: calc(50% - 5px);
    }
    @media only screen and(max-width:768px){
      width: 100%;
    }
    &:nth-child(3n+0) {
      @media only screen and(min-width:1051px) {
        margin-left: 10px;

      }
    }
    &:nth-child(3n-1) {
      @media only screen and(min-width:1051px) {
        margin-left: 10px;
      }
    }
    &:nth-child(2n+0) {
      @media only screen and(min-width:768px) and (max-width:1050px) {
        margin-left: 10px;

      }
    }
    &__header{
      display: flex;
      align-items: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #F0F0F0;
      .logo{
        width: 30px;
      }
      &__heading{
        margin-left: 13px;
        @media only screen and(max-width:768px) {
          width: 200px;
        }
        @media only screen and(min-width:769px) {
          width: 180px;
        }
        text-overflow: ellipsis;
        overflow: hidden;
        &__underlined{
          text-decoration: underline;
          cursor: pointer;
        }
      }
      i{
        margin-left: auto;
        color: #53CAB2;
        cursor: pointer;
      }
    }
    &__description{
      margin-top: 10px;
      color: #969BA3;
      font-family: "Roboto";
      font-size: 14px;
      line-height: 23px;
      height: 131px;
      overflow: hidden;
      position: relative;

      &__clipper {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

    }
    &__more-info{
      a{
        margin-top: 10px;
        width: 100%;
        padding-bottom: 22px;
        text-align: center;
        border-bottom: 1px solid #F0F0F0;
        cursor: pointer;
        color: #53cab2;
        &:hover{
          text-decoration: none;
        }
      }
      text-align: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #F0F0F0;
    }
    &__evaluation{
      margin-top: 25px;
      padding-bottom: 21px;
      &__heading{
        font-family: "Roboto";
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #6A6A6A;
      }
      &__enteries{
        font-family: "Roboto";
        font-size: 10px;
        line-height: 18px;
        text-transform: uppercase;
        color: #969BA3;
        margin-top: 5px;
      }
      &__score{
        display: flex;
        margin-top: 5px;
        align-items: center;
        &__main{
          font-family: "Roboto";
          font-size: 48px;
          line-height: 56px;
          color: #6A6A6A;
          width: auto;
          top: 4px;
          position: relative;
          padding-right: 15px;
        }
        &__highest-lowest{
          &__highest,
          &__lowest{
            display: flex;
            align-items: center;
            padding-top: 9px;
          }
          .score{
            font-family: "Roboto";
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            margin-right: 4px;
          }
          .score-level{
            font-weight: 600;
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: #979797;
            margin-left: 5px;
          }
          &__highest{
            .score{
              color: #53CAB2;
            }
          }
          &__lowest{
            .score{
              color: #FF4853;
            }
          }
        }
      }
    }
    &__buttons-container{
      border-top: 1px solid #F0F0F0;
      display: flex;
      padding-top: 15px;
      display: flex;
      position: absolute;
      bottom: 30px;
      width: calc(100% - 60px);
      justify-content: center;
      .evaulation-btn{
        @media only screen and(min-width:768px) {
          width: 60%;
        }
      }
      .score-btn{
        @media only screen and(min-width:768px) {
          width: 40%;
        }
      }
      button{
        border-radius: 16px!important;
        height: 27px!important;
        width: 100%;
        margin-right: 5px;
        margin-left: 5px;
      }
      .outlined-green{
        color: #53CAB2!important;
        // border-color:#53CAB2!important;
        border: 1px solid #53CAB2!important;
        background: white;
      }
      .outlined{
        border-color: #C4A9FC!important;
        color:#C4A9FC!important;
        background: white;
      }
    }
  }
}