.startup-list-view-container {
  padding: 0px !important;
  min-width: 880px;

  &__header {
    padding-left: 34px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 34px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #c0c2c5;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    border-bottom: 1px solid #f0f0f0;
    &__company_name {
      width: 60%;
    }
    &__header-labels {
      display: flex;
      width: 40%;
      text-align: right;
    }

    &__avg_score {
      margin-left: auto;
      margin-right: 30px;
      text-align: center;
    }
    &__evaluation {
      text-align: center;
    }
  }

  &__row {
    padding-left: 14px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 34px;
    background: #ffffff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    justify-content: space-between;
    &__startup-name-container {
      display: flex;
      width: 270px;
      position: relative;
      padding-left: 30px;
      align-items: center;
      flex-wrap: wrap;
      vertical-align: middle;
      padding-right: 30px;

      &__logo {
        position: absolute;
        left: 0;
      }

      align-items: center;
      flex-wrap: wrap;
      &__startup-name {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        line-height: 1.6;
        &__underlined {
          text-decoration-line: underline;
          cursor: pointer;
        }
        color: #6a6a6a;
        margin-left: 11px;
      }
      i {
        font-size: 19px;
        color: #53cab2;
        margin-left: auto;
        cursor: pointer;
        position: absolute;
        right: 0;
      }
    }

    &__button-container {
      display: flex;
      justify-content: right;

      @media screen and (min-width: 1050px) {
        margin-left: 37px;
        margin-bottom: 6px;
      }

      &__buttons {
        display: flex;
        align-items: center;

        button {
          max-height: 27px;
          margin-right: 10px;
          font-family: "Roboto" !important;
          color: #ffffff;
        }
        .outlined-green {
          color: #53cab2 !important;
          border: 1px solid #53cab2 !important;
        }
        .outlined {
          border-color: #c4a9fc !important;
          color: #c4a9fc !important;
        }
      }
      &__scores {
        display: flex;
        &__heading {
          font-weight: normal !important;
        }
        &__avg-score {
          font-family: "Roboto" !important;
          font-weight: bold !important;
          font-size: 16px !important;
          line-height: 19px !important;
          color: #53cab2;
          align-items: center;
          width: 75px;
          text-align: right;
          margin-right: 35px;
        }
        &__evaluation {
          font-family: "Roboto" !important;
          font-weight: bold !important;
          font-size: 16px !important;
          line-height: 19px !important;
          color: #ffa500;
          margin-left: auto;
          align-items: center;
          width: 75px;
          text-align: right;
          margin-right: 30px;
        }
      }
    }
  }
}
