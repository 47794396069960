.payment-container {
  max-width: 700px;
  padding-top: 50px;
  padding-bottom: 200px;
  margin: auto;

  &__subscriber {
    padding-top: 40px;
    padding-bottom: 40px;

    &__info-row {
      line-height: 2;
      span {
        font-weight: 600;
      }
    }

    &__button-container {
      margin-top: 30px;
    }

  }
}

.product-card {
  position: relative;
  
  &__top-section {
    background: #53CAB2;
    text-align: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    overflow: hidden;

    &__price {
      color: white;
      font-size: 48px;
      font-weight: 300;

      .unit {
        opacity: 0.5;
        font-weight: 200;
      }

      .amount {
        font-weight: 600;
      }

      .interval {
        font-size: 12px;
      }

    }

    &__plan {
      color: #fff5ac;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      font-size: 16px;
    }

    &__banner {
      position: absolute;
      top: 25px;
      right: -45px;
      height: 27px;
      background: white;
      transform: rotate(45deg);
      display: flex;
      justify-content: center;
      font-size: 10px;
      font-weight: 600;
      padding: 0px 40px;
      vertical-align: middle;
      padding-top: 6px;
      letter-spacing: 0.15em;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
      user-select: none;
      color: #515d6e;
    }

  }

  &__content {
    padding: 20px;

    &__description {
      text-align: center;

      &__line {
        line-height: 1.6;
        padding: 14px 0px;
        &__label {
          color: #a5a5a5;
          font-size: 14px;
          font-weight: 400;
        }
        &__text {

        }
      }
    }

  }

  &__footer {
    display: flex;
    justify-content: center;
  }

}