.reactour{
    &__helper{
        border-radius: 8px!important;       
        font-family: "Proxima Nova";
        font-style: normal;
        font-size: 18px;
        span[data-tour-elem="badge"]{
            border-radius: 50%!important;
        }
        button[data-tour-elem="right-arrow"],
        button[data-tour-elem="left-arrow"]
        {
            color: #53cab2;
            &:hover{
                color:#49b6a0;
            }
            &:disabled{
                color: lightgray;
            }
        }
    }
    &__close{
        color: #ff8166!important;
    }
}