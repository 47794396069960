// overview & Evaluation Summaries comp & SimilarStartupscard & SubjectiveScoresComp
.overview-container {
  &__details {
    margin: 25px 0px;
    @media only screen and (max-width: 1168px) {
      margin-left: 23px;
    }
  }
  &__scores {
    &__heading {
      font-family: "Proxima Nova";
      font-weight: bold;
      font-size: 19px;
      line-height: 23px;
      padding: 0 !important;
      color: var(--ui-color-gray-dark1);
    }
    &__selector {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-left: -10px;
      max-width: 600px;
      @media (min-width: 540px) and (max-width: 609px) {
        margin-left: -35px;
      }
      @media (min-width: 445px) and (max-width: 540px) {
        margin-left: -30px;
      }
      @media (min-width: 403px) and (max-width: 445px) {
        margin-left: -20px;
      }
      @media (max-width: 391px) {
        margin-left: -13px;
      }
      .sub_scores {
        @media (min-width: 991px) and (max-width: 1240px) {
          padding-right: 13px;
        }
      }
    }
    &__label {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-dark1);
      margin-top: 25px;
      padding: 0px;
      .score {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 76px;
        color: var(--ui-color-gray-dark1);
      }
      .you {
        @media only screen and (max-width: 768px) {
          margin-left: 50%;
        }
      }
      .selected {
        color: var(--ui-color-primary-green-dark4);
      }
    }
  }
  .overview-tags {
    // padding: 30px 35px;
  }
  .impact-goals-container {
    .img-col {
      padding-left: 0px;
      margin-top: 15px;
      cursor: pointer;
      img {
        height: 101px;
        width: 101px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);

        @media screen and (max-width: 500px) {
          height: 60px;
          width: 60px;
        }
        @media (min-width:1200px) and (max-width: 1260px) {
          height: 90px;
        width: 90px;
        }
      }
    }
    .active-img-col {
      img {
        -webkit-filter: none;
        filter: none;
      }
    }
  }
}
// AddTag Modals & AddToGroupModal
.add_group {
  font-size: 14px;
  margin-top: 10px;
  color: #969ba3;
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 4px;
}
.tags_margin {
  margin-top: 6px;
}
.parts__part {
  cursor: pointer !important;
}
