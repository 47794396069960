.free-text-conatiner {
  .text-container {
    @media only screen and(max-width:768px) {
      margin-left: 30px;
    }
  }
  textarea {
    margin-top: 20px;
    max-width: 100% !important;
    width: 100%;
    border: 1px solid var(--ui-color-gray-pale2);
    box-sizing: border-box;
    border-radius: 8px;
    background-color: var(--ui-color-white);
    resize: none;
  }
}
.text-lines-conatiner {
  margin-top: 20px;
  .cancel {
    color: var(--ui-color-primary-orange-dark1);
    margin-top: 10px;
    cursor: pointer;
  }
  .add {
    color: var(--ui-color-primary-green-dark2);
    cursor: pointer;
  }
}
