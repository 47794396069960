.MuiCheckbox-root {
  color: var(--ui-color-gray-dark6) !important;
  height: 18px;
  width: 18px;
  margin-right: 5px !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--ui-color-primary-green-dark2) !important;
}
.MuiCheckbox-colorSecondary {
  color: var(--ui-color-gray-dark6);
}
.MuiIconButton-root {
  padding-left: 0 !important;
  margin-right: 16px !important;
}

.Mui-checked:hover {
  background-color: white !important;
  box-shadow: none;
}
.MuiCheckbox-root:hover {
  background-color: white !important;
  box-shadow: none;
}
.MuiTypography-body1{
  font-family: "Proxima Nova" !important;
  font-size: 15px !important;
}
.MuiFormControlLabel-label{
  font-size: 15px !important;
}