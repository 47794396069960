.public-group-footer {
  margin-top: 54px;
  border-top: 1px solid #9ea2aa44;
  padding-top: 25px;
  color: #9ea2aa;
  font-size: 14px;
}

.public-group-footer a,
.public-group-footer a:active,
.public-group-footer a:hover,
.public-group-footer a:visited {
  color: #23527c !important;;
  text-decoration: underline !important;
}