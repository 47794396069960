.evalations-container {

  //My Evaluations
  .evaluate-startup-btn {
    margin-top: 15px;
  }

  //Evaluation component common styling
  .details {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);

    //table heading
    .heading-submissions {
      font-size: 14px;
      text-align: center;
      width: 150px;
    }

    .heading-score {
      font-size: 14px;
      text-align: right;
      width: 50px;
    }

    .heading-right-icons {
      width: 40px;
    }


    // comment box
    @media (min-width: 991px) {
      .discussion-contianer {
        max-height: 300px;
        min-height: 350px;
        height: 300px !important;
      }
      .comment_box {
        max-height: 350px !important;
        min-height: 30vh !important;
        height: 45vh !important;
      }
    }
  }
  .group-evaluation-notata-list{
    margin: 15px;
  }
  //Group evaluations
  .group-heading {
    margin-top: 10px;
    margin-left: 13px;
    cursor: pointer;
  }
  
  .group-heading li {
    display: inline-block;
  }
  .group_item {
    font-size: 15px;
    line-height: 22px;
    color: var(--ui-color-gray-dark1);
    padding: 1px 9px;
    border-radius: 20px;
    border: 0.1px solid #ffc244;
    background: #fbf1dc;
  }
  .external-temp-name{
    display: flex;
    word-break: break-all;
    @media screen and (max-width:390px) {
      width: 70px;
    }
    @media screen and (max-width:350px) {
      width: 50px;
    }
  }
  .external-submission-date{
    display: flex;
  }
}
