.download-as-svg {
  font-size: 26px;
  position: absolute;
  left: 20px;
  padding-top: 24px;
  padding-left: 7px;
  &__cloud-button {
    cursor: pointer;
    color: var(--ui-color-gray-dark3);
  }
  &__loader-button {
    color: var(--color-primary);
  }
}