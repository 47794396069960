.container {
  user-select: none;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--ui-color-gray-dark4);
}

.paginatorInput {
  background: var(--ui-color-white);
  border: 1px solid var(--ui-color-gray-pale2);
  box-sizing: border-box;
  border-radius: 20px;
  height: 32px;
  width: 46px;
  outline: none;
  text-align: center;
  color: var(--ui-color-gray-dark8);
  margin-right: 10px;
}

.leftArrow {
  padding-right: 10px;
}

.rightArrow,
.leftArrow {
  padding-left: 10px;
  color: var(--ui-color-gray-dark2);
  font-weight: 400 !important;
  cursor: pointer;
}
