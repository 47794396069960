.manage-list {

  &__section {

    .manage-line {

      display: flex;
      justify-content: space-between;
      padding: 9px 0px 9px 0px;
      border-bottom: 1px solid var(--ui-color-gray-pale1);

      &__key {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: var(--ui-color-gray-dark1);
      }

      &__val {
        margin-left: auto;
        text-align: right;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: var(--ui-color-gray-dark1);
      }

    }

  }

}