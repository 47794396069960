.archive-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .archive-list__card {
    max-width: 700px;
    .startups {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-medium);
      margin-top: 20px;
      margin-left: 25px;
    }
    .startup-name {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: var(--ui-color-gray-dark1);
      margin-bottom: 10px;
    }

    .archive-list-data {
      padding-left: 25px;
      padding-top: 25px;
      padding-right: 25px;

      &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        @media only screen and(max-width:500px) {
          flex-direction: column;
        }

        &__btn {
          display: flex;
          flex-wrap: wrap;

          @media only screen and(max-width:370px) {
            flex-direction: column;
          }

          & button:first-child {
            margin-right: 5px;
            margin-bottom: 5px;

            @media only screen and(max-width:370px) {
              margin-left: 0px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
