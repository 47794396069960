.evaluate-startup-container {
  &__data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 10px;

    @media only screen and(max-width:400px) {
      flex-direction: column;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--ui-color-gray-pale1);
    }

    .eval-type-heading {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 33px;
      color: var(--ui-color-gray-medium);
    }
  }

  &__evaluations {
    margin-left: 10px;

    @media only screen and(max-width:768px) {
      flex-direction: column;
      margin-top: 0px;
    }
    .new-evaluation {
      margin-top: 5px;
      float: right;
    }
  }
}
