.radio-button-container {
  position: relative;
  padding-left: 26px;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked {
      ~ {
        .checkmark {
          &:after {
            display: block;
          }
        }
      }
    }
  }
  &__radio-button-label {
    font-family: "Proxima Nova";
    font-size: 15px;
    line-height: 30px;
    color: var(--ui-color-gray-medium);
    font-weight: normal;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--ui-color-white);
    border-radius: 50%;
    border: 1px solid var(--ui-color-gray-pale2);
    margin-top: 5px;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
    &:after {
      top: 2px;
      left: 2px;
      bottom: 2.5px;
      right: 2.5px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: var(--ui-color-primary-green-dark2);
    }
  }
}
