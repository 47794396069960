.code-snippet {
  &__heading {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: var(--ui-color-gray-medium);
    margin-top: 33px;
  }
  &__content {
    padding: 14px 21px;
    border-radius: 8px;
    margin-top: 8px;
    font-family: "Roboto";
    background: var(--ui-color-gray-pale1);
    color: var(--ui-color-gray-dark1);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
    margin-bottom: 14px;
    overflow-wrap: anywhere;
    &--bg-white {
      background: #fff;
    }
  }
}
