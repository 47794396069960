.settings-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  &__card {
    max-width: 700px;
    &__separator {
      width: 100%;
      height: 1px;
      border: 1px solid var(--ui-color-gray-pale2);
      margin-top: 25px;
    }
  }
  .settingsMenu-container {
    margin-top: 13px;
    &__menu {
      margin-top: 25px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        height: 14px;
        width: 14px;
        margin-right: 12px;
      }
      &__name {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: var(--ui-color-gray-medium);
        a {
          text-decoration: none;
          color: var(--ui-color-gray-medium);
          &:hover {
            text-decoration: none;
            color: var(--ui-color-gray-medium);
          }
        }
      }
    }
  }
}
