//Common Css for all cards
.dashboard-container {
  //margin-top: 50px;
  max-width: 1600px;
  margin: auto;
  padding-right: 15px;
  font-family: "Proxima Nova";

  .dashboard_card {
    padding-right: 0;
    padding-top: 5px;
  }
  .funnelSpacing {
    margin-left: 3px;
  }

  .link_underline {
    text-decoration: none !important;
    :hover {
      color: var(--ui-color-primary-green-dark4) !important;
    }
    &__recentlyAdd {
      text-decoration: none !important;
      font-size: 13px;
      font-style: normal;
      font-weight: bold;
      color: white;
    }
  }

  //#DashboardRecentlyAddedStartups
  &__recently-added-card {



    background: linear-gradient(
      115.95deg,
      var(--ui-color-primary-orange-dark1) 50%,
      var(--ui-color-primary-orange-dark2) 97%
    ) !important;
    height: 300px;

    &__data-container {
      margin-top: 30px;
      max-height: 200px;
      overflow: hidden;
      &__data-entry {
        padding-top: 4px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        padding-bottom: 12px;
        .startup-name,
        .group-name {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: var(--ui-color-white);
          flex: 1 0 72%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .group-name {
          cursor: pointer;
        }
      }
    }
  }





  // #DashboardGroupInvitations
  &__invited-to-groups-card {

    background: linear-gradient(
      115.95deg,
      var(--ui-color-primary-green-dark2) 50%,
      var(--color-turquoise) 97%
    ) !important;
    height: 300px;

    &__data-container {
      overflow: auto;
      margin-right: -18px;
      padding: 0px 10px 10px 22px;
      @media only screen and(min-width:768px) {
        height: 168px;
      }
      @media (max-width: 768px) {
        height: 168px;
      }
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: #fff;
        border-radius: 10px;
      }
      .demo-msg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 14px;
      }
      &__data-entry {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 35px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        padding-bottom: 12px;
        padding-top: 4px;
        border-bottom: 0px;
        .group-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          color: var(--ui-color-white);
          display: flex;
          align-items: center;
          flex: 1 0 60%;
          span {
            margin-right: 13px;
            font-size: 18px !important;
            cursor: pointer;
          }
        }
        .admin {
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          text-decoration-line: underline;
          color: var(--ui-color-white);
          flex: 1 0 40%;
        }
      }
    }
    &__invite-container {
      background: rgba(0, 0, 0, 0.06);
      margin-left: -20px;
      margin-right: 10px;
      margin-top: 10px;
      border-radius: 2px;
      &__invite {
        padding: 10px 20px 0px 15px;
        .invite-member {
          .invitation-name {
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            text-decoration-line: underline;
            color: var(--ui-color-white);
          }
          .invitation-text {
            font-size: 12px;
            opacity: 0.6;
            color: var(--ui-color-white);
            @media screen and (max-width: 355px) {
              font-size: 11px;
            }
          }
        }
      }
      &__action-buttons {
        padding: 15px;
        padding-top: 0;
      }
      .group-action-button {
        border: 1px solid white;
        background: rgba(255, 255, 255, 1);
        width: auto;
        padding: 5px 12px;
        padding-top: 6px;
        margin-right: 10px;
        height: 25px;
        color: #47c3b4;
        font-size: 10px !important;
        font-weight: 900 !important;
        &:hover {
          background: rgba(255, 255, 255, 0.1) !important;
          color: #ffffff !important;
        }
      }
    }
    .footer_btns {
      display: flex;
      margin-top: 6px;
      justify-content: space-between;
      &__link {
        text-decoration: none !important;
      }
    }

  }

  .group-page-notifications &__invited-to-groups-card {



    &__invite-container {

      //margin-left: -14px;
      margin-left: 5px;
      background: white;
      border: 1px solid #cee8f9;
      margin-right: 5px;
      border-radius: 0;

      .invitation-text,
      .group-name{
        color: var(--ui-color-gray-dark1);
      }

      button,
      button:hover {
        color: var(--ui-color-primary-green-dark2) !important;
        border: 1px solid var(--ui-color-primary-green-dark2);
      }

    }

  }





  // #DashboardMyStartups
  &__my-startups {
    height: 300px;
    @media only screen and(max-width:768px) {
      height: auto;
    }
    &__data-container {
      height: 168px;
      overflow-y: auto;
      overflow-x: hidden;
      .data-entry-heading {
        border-bottom: none !important;
      }
      &__data {
        display: flex;
        flex-wrap: wrap;
        @media only screen and(max-width:768px) {
          padding-bottom: 15px;
          border-bottom: 1px solid var(--ui-color-gray-pale2);
          margin-top: 0px;
          padding-right: 5px;
        }
        &__heading {
          font-weight: bold;
          font-size: 12px;
          line-height: 10px;
          letter-spacing: 0.08em;
          color: var(--ui-color-gray-pale2);
          flex: 1 0 33.3%;
          @media only screen and(max-width:378px) {
            font-size: 9px;
          }
        }
      }
      &__data-entry {
        margin-top: 25px;
        display: flex;
        flex: 1 0 100%;
        width: 100%;
        flex-wrap: wrap;
        @media only screen and(max-width:768px) {
          padding-bottom: 15px;
          margin-top: 0px;
          padding-right: 5px;
        }
        &__mobile-heading {
          @media only screen and(min-width:768px) {
            display: none;
            letter-spacing: normal;
          }
          font-weight: bold;
          font-size: 9px;
          line-height: 16px;
          color: var(--ui-color-gray-pale2);
          text-transform: uppercase;
        }
        &__startup {
          flex: 1 0 33.3%;
          cursor: pointer;
          @media only screen and(max-width:768px) {
            flex: 1 0 33.3%;
          }
          &__name {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: var(--ui-color-gray-dark1);
            .big-card {
              @media screen and (max-width: 519px) {
                display: none;
              }
              @media (min-width: 1200px) and (max-width: 1500px) {
                display: none;
              }
            }
            .small-card {
              @media (min-width: 519px) and (max-width: 1200px) {
                display: none;
              }
              @media (max-width: 519px) {
                font-size: 12px;
              }
              @media screen and (min-width: 1501px) {
                display: none;
              }
            }
          }
        }

        &__funnels-entry {
          flex: 1 0 33.3%;
          @media only screen and(max-width:1200px) {
            margin-left: -10px;
          }
          &__data {
            display: flex;
            &__funnel-type {
              font-size: 13px;
              line-height: 18px;
              color: var(--ui-color-gray-dark7);
              margin-left: 5px;
              text-overflow: ellipsis;
              max-height: 33px;
              overflow: hidden;
              text-transform: uppercase;
              .big-card {
                @media screen and (max-width: 519px) {
                  display: none;
                }
                @media (min-width: 1200px) and (max-width: 1500px) {
                  display: none;
                }
              }
              .small-card {
                @media (min-width: 519px) and (max-width: 1200px) {
                  display: none;
                }
                @media screen and (max-width: 519px) {
                  font-size: 12px;
                }
                @media screen and (min-width: 1501px) {
                  display: none;
                }
              }
            }
          }
        }
        &__subjective-score-container {
          flex: 1 0 30%;
          text-align: right;
          &__data {
            display: flex;
            align-items: center;
            cursor: pointer;
            &__score {
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: var(--ui-color-gray-dark7);
              position: relative;
              left: -20px;
              top: -4px;
              @media only screen and(min-width:768px) {
                flex: 1 0 33%;
              }
              @media only screen and(max-width:1200px) {
                flex: 1 0 15%;
              }
              @media only screen and(max-width:769px) {
                flex: 1 0 50%;
                text-align: left;
                left: 0px;
                font-size: 11px;
              }
            }
            &__change {
              position: relative;
              right: 30px;
              top: -5px;
              @media only screen and(max-width:1190px) {
                flex: 1 0 40%;
                right: 5px;
              }
              @media only screen and(max-width:769px) {
                flex: 1 0 40%;
                right: 0px;
              }
              @media only screen and(max-width:526px) {
                flex: 1 0 40%;
                right: 10px;
              }
              @media only screen and(max-width:455px) {
                flex: 1 0 40%;
                right: 18px;
              }
              @media only screen and(min-width:769px) {
                flex: 1 0 85%;
              }
              @media only screen and(min-width:1700px) {
                flex: 1 0 40%;
                right: 5px;
              }
            }
          }
        }
      }
    }
    &__more-startups {
      margin-top: 25px;
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--ui-color-primary-green-dark4);
      cursor: pointer;
    }
  }

  // #DashboardNews
  &__news {
    padding-right: 2px;
    @media only screen and(max-width:768px) {
      height: auto;
    }
    &__container {
      overflow-y: auto;
      overflow-x: hidden;
      height: 505px;
      margin-bottom: 20px;
      margin-top: 30px;
      padding-right: 20px !important;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: #53cab2;
        padding-left: 10px;
      }
    }
    &__date {
      font-family: "Roboto";
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-medium);
    }
    &__news {
      margin-top: 13px;
      font-family: "Roboto";
      &__heading {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--ui-color-gray-dark1);
      }
      &__detail {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-medium);
        margin-top: 14px;
        display: flex;
      }
      &__image {
        img {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
    &__footer {
      margin-top: 13px;
      display: flex;
      &__name {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark1);
      }
      &__learn-more {
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: var(--ui-color-primary-green-dark2);
        margin-left: auto;
        cursor: pointer;
        margin-bottom: 37px;
        i {
          margin-left: 10px;
        }
      }
    }
  }

  //#DashboardReports
  &__reports-and-analytics {
    &__container {
      max-height: 505px;
    }
    &__scores-container {
      margin-top: 30px;
      display: flex;
      @media screen and (max-width: 333px) {
        display: block;
        margin-left: 20px;
      }
      &__score {
        flex: 1 0 50%;
        margin-top: 0px;
        @media screen and (max-width: 350px) {
          flex: 1 0 50%;
        }
        &__heading {
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          text-transform: uppercase;
          color: var(--ui-color-gray-medium);
        }
        &__value-container {
          display: flex;
          align-items: center;
          @media only screen and(max-width:768px) {
            flex-wrap: wrap;
          }
          @media screen and (max-width: 333px) {
            padding-bottom: 25px;
          }
          &__value {
            font-weight: 300;
            font-size: 62px;
            line-height: 76px;
            color: var(--ui-color-gray-dark1);
            margin-top: 10px;
            @media only screen and(max-width:768px) {
              flex: 1 0 100%;
            }
          }
        }
      }
    }
    &__chart-funnel-container {
      @media only screen and(max-width:768px) {
        flex-wrap: wrap;
      }
      @media only screen and(max-width:350px) {
        overflow-x: scroll;
      }
      &__chart {
        @media only screen and(max-width:768px) {
          flex: 1 0 90%;
          margin-right: 2%;
          margin-left: 2%;
        }
      }
    }
  }

  //#DashboardNotifications
  &__notification {
    padding-right: 2px;
    &__container {
      height: 515px;
      margin-bottom: 20px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 20px !important;

      @media only screen and(max-width:768px) {
        padding-right: 20px;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background: #63cab2;
          border-radius: 10px;
        }
      }
      @media (min-width: 1200px) and (max-width: 1600px) {
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background: #63cab2;
          border-radius: 10px;
        }
      }
      &__no-notifications {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        margin: 50px 0px;
        color: var(--ui-color-gray-medium);
      }
    }
  }
}

.group-page-notifications {
  .demo-msg {
    display: none !important;
  }
}