.card {
  margin-right: 0px !important;
}
.chart-tile-container {
  height: 350px;
  // this is ChartTile component scss
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 10px solid transparent;
    background-clip: content-box;
  }

  &__header {
    display: flex;
    &__heading {
      text-transform: uppercase;
      padding: 25px 5px 0px 25px !important;
      flex: 1 0 33.3%;
    }
    &__downup {
      margin-left: 10px;
      font-size: 13px !important;
      cursor: pointer;
      z-index: -1;
    }
    &__dropdown {
      position: absolute;
      padding: 4px 17px;
      z-index: 999;
      left: 56%;
      text-align: left;
      text-align: initial;
      // margin-left: 300px;
      margin-top: 0px;
      .drop-down-item {
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        padding-top: 8px;
        padding-bottom: 8px;
        color: var(--ui-color-gray-dark1);
        cursor: pointer;
      }
    }
    &__icons {
      margin-left: auto;
      margin-right: -5px;
      .selected {
        color: #000;
        border-bottom: 1px solid #000;
      }
      img {
        margin-top: 3px;
        margin-right: 5px;
        height: 10px;
        padding-bottom: 1px;
        cursor: pointer;
      }
    }
  }

  // this is ReportColumChart component scss (Line Number: 63 to 66)
  &__chart {
    height: calc(100% - 63px);
    margin-top: 40px;
  }
  &__pie {
    margin-top: 0px;
    height: 180px;
    // @media only screen and(min-width:1200px) {
    //   margin-top: 30px;
    // }
    @media only screen and(min-width:768px) {
      display: inline-block;
    }
    @media only screen and(max-width:768px) {
      height: 230px;
    }
    @media only screen and(max-width:340px) {
      width: 100%;
    }
  }

  // this is ReportPieChart component scss (Line number: 85 to 136)

  .pie-legends {
    display: flex;
    // margin-top: 20px;
    @media only screen and(min-width:768px) {
      flex-wrap: wrap;
    }
    @media only screen and(max-width:1199px) and (min-width: 768px) {
      margin-left: 25px;
    }
    @media only screen and(max-width:768px) {
      margin-left: 10px;
    }
    @media (min-width: 991px) and (max-width: 1200px) {
      justify-content: center;
    }
    @media (max-width: 768px) {
      justify-content: center;
    }
    .legend {
      margin-top: 20px;
      margin-right: 20px;
      @media only screen and(max-width:768px) {
        margin-left: 20px;
      }
      .legend-icon {
        width: 14px;
        height: 4px;
        border-radius: 2px;
      }
      .legend-text {
        font-size: 14px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-medium);
        @media only screen and(max-width:768px) {
          max-width: 55px;
        }
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 20px;
      }
      .legend-count {
        font-weight: bold;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark1);
      }
      .max {
        font-size: 47px;
      }
    }
  }
  .dashboard-legends {
    justify-content: center;
    .max {
      font-size: 22px !important;
    }
  }
  .total-text {
    z-index: 100;
    position: absolute;
    top: 75px;
    left: calc(29.15% + 30px);
    @media only screen and(max-width:768px) {
      top: 94px;
    }
    @media only screen and(max-width:1199px) {
      left: calc(50% - 15px);
    }
    text-align: center;
    text-transform: uppercase;
    .total {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.08em;
      color: var(--ui-color-gray-dark1);
      &::after {
        content: "\a";
        white-space: pre;
      }
    }
    .count {
      font-size: 20px;
      line-height: 24px;
      color: var(--ui-color-gray-dark5);
    }
  }
  .total-text-dashboard {
    top: auto;
  }

  // this is ReportBarChart component scss (Line Number: 177 to 258)

  .bar-chart-container {
    margin-left: 22px;
    margin-top: 30px;
    height: 250px;
    overflow-y: scroll;
    @media only screen and(min-width:992px) {
      max-width: 350px;
    }
    &__heading {
      font-family: "Roboto";
      font-size: 10px;
      line-height: 12px;
      color: var(--ui-color-gray-medium);
      // display: flex;
      .th-country {
        flex: 1 0 50%;
      }
      .th-startup {
        flex: 1 0 12%;
      }
      .th-noof-startups {
        flex: 1 0 38%;
      }
    }
    &__data {
      &__row {
        font-family: "Roboto";
        font-size: 13px;
        line-height: 15px;
        display: flex;
        margin-top: 10px;
        .td-country {
          font-weight: 500;
          width: 200px;
          color: var(--ui-color-gray-medium);
          overflow: hidden;
          // flex: 1 0 40%;
          flex: 1 0 20%;
          margin-right: 20px;
          @media (max-width: 400px) {
            flex: 1 0 30%;
            display: flex;
            word-wrap: break-word;
            word-break: break-all;
            margin-right: 5px;
          }
        }
        .td-startups {
          color: var(--ui-color-gray-medium);
          flex: 1 0 12%;
          height: 14px;
        }
        .td-noof-startups {
          color: var(--ui-color-gray-medium);
          display: flex;
          flex: 1 0 38%;
          span {
            flex: 1 0 30%;
          }
          .iconvisible {
            visibility: hidden;
            padding-right: 2.5px;
          }
          .icon_left {
            // color: rgb(241, 138, 138);
            color: var(--ui-color-gray-medium);
            font-size: 10px;
            padding-top: 3.7px;
            padding-right: 3px;
          }
          .bar-container {
            max-width: 60%;
            height: 10px;
            background: var(--ui-color-gray-dark6);
            margin-left: 10px;
            border-radius: 5px;
            // flex: 1 0 55%;
            margin-right: 30px;
            min-width: 55%;
            @media (max-width: 400px) {
              // flex: 1 0 20%;
              max-width: 10px;
              min-width: 50%;
            }
            @media (min-width: 1200px) and (max-width: 1450px) {
              max-width: 60%;
              min-width: 50%;
            }
            @for $i from 0 through 100 {
              .bar-#{$i} {
                width: calc(#{$i} * 1%);
                background: var(--ui-color-primary-green-dark2);
                height: 10px;
                border-radius: 5px;
                min-width: 10%;
              }
            }
            .bar-0 {
              min-width: 0% !important;
              width: 0%;
            }
          }
        }
      }
    }
  }
  .bar-chart-container-dashbaord {
    margin-left: 0px !important;
  }

  // this is ReportColumChart component scss (Line Number: 270 to 281)
  .custom-tooltip {
    background-color: var(--ui-color-white);
    // opacity: 1;
    z-index: 999;
    border-radius: 3px;
    border: 1px solid #888;
    box-shadow: 2px 2px 2px #888;
    .label {
      color: black;
      font-weight: 400;
    }
  }
  .total {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.08em;
    color: var(--ui-color-gray-dark1);
    &::after {
      content: "\a";
      white-space: pre;
    }
  }
  .count {
    font-size: 20px;
    line-height: 24px;
    color: var(--ui-color-gray-dark5);
    margin-top: 10px;
  }
}
