.tag-container {
  display: inline-block;
  position: relative;

  .kill-padding {
    padding-right: 25px !important;
  }

  .tag-items {
    font-size: 12px;
    padding: 1px 9px;
    border-radius: 20px;

    .tag-times {
      color: rgba(255, 255, 255, 0.5) !important;
      cursor: pointer;
      font-weight: 300;
      font-size: 14px;
      position: absolute;
      right: 14px;
      top: 8px;
    }
  }

  // Tag size
  .bg_tag {
    padding: 4px 19px;
    padding-top: 3px;
    padding-bottom: 5px;
    border-radius: 25px;
    font-size: 16px;
  }
  .md_tag {
    padding: 4px 15px;
    border-radius: 15px;
    margin: 1px 3px 0px 0px;
  }
  .sm_tag {
    padding: 4px 15px;
    border-radius: 15px;
    font-size: 10px;
    margin: 2px 2px 2px 2px;
  }
  .xs_tag {
    padding: 2.5px 10px;
    border-radius: 15px;
    font-size: 9px;
    margin: 2px 2px 2px 2px;
    span {
      margin-top: 40px;
    }
  }

  // Tag color
  .color_default {
    color: white;
    background: var(--ui-color-primary-green-dark2);
    border: 1px solid var(--ui-color-primary-green-dark2);
    i {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .primary_tag {
    border: 1px solid var(--ui-color-gray-dark1);
    background-color: var(--ui-color-gray-dark1);
    color: var(--ui-color-white);
  }
  .color_oragne {
    border: 0.1px solid #ffc244;
    background: #fbf1dc;
    color: var(--ui-color-gray-dark1);
  }
  .lightBlue {
    background: #e3f8ff;
    color: var(--ui-color-gray-dark1);
    border: 1px solid #e3f8ff;
  }
  .secondary-yellow {
    background-color: #ffe4b7;
    border: 1px solid #ffe4b7;
    color: var(--ui-color-gray-dark1);
  }
  .yellow-dark {
    background: #ffa701;
    border: 1px solid #ffa701;
    color: var(--ui-color-white);
  }
  .tag_margin {
    margin: 2px 2px 2px 2px;
  }
  .pale-gray {
    background-color: #d3d3d3;
    border: 1px solid #d3d3d3;
    color: #fff;
  }
}

// Input button

.buttongreen_tags {
  background-color: transparent;
  margin-bottom: -5px;
  border: none;
  outline: none;
}
.buttongreen_tags i {
  color: var(--ui-color-primary-green-dark2) !important;
  cursor: pointer;
  font-size: 14px !important;
}

.tableSearch {
  min-width: 100%;
  position: relative;
  display: inline-flex;
  .names {
    margin-bottom: -8px;
    @media screen and (max-width: 432px) {
      .primary_color {
        height: 39px !important;
        font-size: 9px !important;
        max-width: 110px;
      }
    }
  }
}

.active_tag {
  background: white;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  background-color: yellow !important;
}

.active_tag a {
  color: var(--color-primary);
  text-decoration: none;

  background-color: pink !important;
}

.button_tag {
  cursor: pointer;
}

.container {
  border-radius: 3px;
  color: var(--color-gray-dark);
  background: var(--color-gray-pale);
  border: 1px solid var(--color-gray-light);
  padding: 3px 12px;
  box-sizing: border-box;
  max-width: 100%;
  display: inline-block;
  font-size: 12px;
  margin: 2px;
  margin-top: 4px;
  position: relative;
}

.tag_wrapper {
  font-style: normal;
  font-weight: normal;
  user-select: none;
  .tag_margin {
    //margin-bottom: 100px;
  }
}
@media screen and (max-width: 500px) {
  .tag_wrapper {
    max-width: 100%;
  }
}

/* tags */
.tag_title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--ui-color-gray-dark5);
  margin-bottom: 30px;
}

.tag_sub_title1 {
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: var(--ui-color-gray-medium);
  margin-bottom: 7px;
}
.tag_sub_title2 {
  font-size: 14px;
  line-height: 14px;
  color: var(--ui-color-gray-medium);
  margin-bottom: 7px;
}

.tag_sub_title3 {
  font-size: 14px;
  line-height: 14px;
  color: var(--ui-color-gray-dark1);
  margin-bottom: 4px;
}

/* small input*/
.smallInput {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 30px;
  width: 230px;
  padding: 0 10px;
  background: var(--ui-color-white);
  border: 1px solid var(--ui-color-gray-pale2);
  box-sizing: border-box;
  border-radius: 20px;
  margin-bottom: 20px;
}

/* big input */
.bigInput {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 40px;
  width: 100%;
  background: var(--ui-color-white);
  border: 1px solid var(--ui-color-gray-pale2);
  box-sizing: border-box;
  border-radius: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 500px) {
  .bigInput ul {
    padding: 0 !important;
  }
}

/* small Tag*/
.smallTag {
  position: relative;
  min-width: 40px;
  height: 16px;
  background: var(--ui-color-gray-dark1);
  border-radius: 10px;
  font-weight: 500;
  line-height: 30px;
  font-size: 7px;
  margin: 0 8px 0px 0;
  padding: 10px 8px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* big Tag*/
.bigTag {
  position: relative;
  height: 24px;
  background: var(--ui-color-gray-dark1);
  border-radius: 12px;
  margin: 0 8px 0px 0;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.smallTagUL {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 3px 0 0 0;
}
.bigTagUL {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  align-items: center;
  width: 100%;
}
.bigInput .bigTagUL {
  padding-bottom: 8px;
  // padding-top: 5px;
  // padding-left: 5px;
}
.bigInput .bigTagUL input {
  margin-top: 10px !important;
  margin-left: 10px;
  margin-right: 10px;
  color: var(--ui-color-gray-dark4);
}
/* small tag name*/
.smallTagName {
  font-weight: 500;
  font-size: 9px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 4px 15px;
  letter-spacing: 0.03em;
  color: var(--ui-color-gray-dark6);
}

/* big tag name*/
.bigTagName {
  margin: 8px 0px 0px 8px;
  letter-spacing: 0.03em;
}

.tag_smallClose_icon i,
.tag_bigClose_icon i {
  font-weight: 500;
  color: var(--ui-color-white);
}

/* small close icon*/
.tag_bigClose_icon {
  position: absolute;
  right: 9px;
  top: 3.5px;
  font-size: 13px;
  color: var(--ui-color-white);
  cursor: pointer;
}

/* small buttons*/
.tagSmallButton {
  min-width: 35px;
  height: 16px;
  background: var(--ui-color-gray-dark7);
  border-radius: 10px;
  font-weight: 500;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.03em;
  color: var(--ui-color-gray-dark6);
  padding: 3px 9px;
  margin: 6px 3px;
  border: 0px;
  text-align: center;
  cursor: pointer;
}

/* big buttons*/
.tagBigButton {
  min-width: 57px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  padding: 4px 15px;
  line-height: 18px;
  letter-spacing: 0.09em;
  padding-bottom: 4.5px;
  margin: 6px 3px;
  border: 0px;
  text-align: center;
  cursor: pointer;
}
.tagBigButton button {
  padding-bottom: 3px;
}
.tag_btn:focus {
  outline: none !important;
}

.smallInput input {
  border: none;
  min-width: 10px;
}
.smallInput .bigTagUL {
  padding-bottom: 4px;
}

.bigInput input:focus,
.smallInput input:focus {
  outline: none !important;
}

//Tag Input

.tag_data {
  // background-color: red !important;
  // margin: 7px 2px 0px 5px;
}
.tagInput {
  @media screen and (max-width: 350px) {
    display: none;
  }
}
.tableSearch input {
  width: 85% !important;
}
.mobile-tagInput {
  @media screen and (min-width: 350px) {
    display: none;
  }
  @media screen and (max-width: 350px) {
    min-width: 10% !important;
  }
}
