.tableWrapper {
  max-width: 100%;
  box-shadow: 0px 3px 12px rgba(41, 70, 103, 0.2);
  font-family: Proxima Nova;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
.tableOuterWrapper {
  overflow-y: visible;
  position: relative;
}
.tableOuterWrapper ::-webkit-scrollbar {
  border-radius: 20px;
  background-color: transparent;
  height: 17px;
}
.tableOuterWrapper ::-webkit-scrollbar-thumb {
  background: #fff;
  /* border-radius: 30px; */
  /* border: 10px solid #F2F2F2; */
  cursor: pointer;
}
.tableOuterWrapper ::-webkit-scrollbar-track {
  padding: 8px 0;
  background-color: #f2f2f2;
}

.buttongreen {
  background-color: transparent;
  margin-left: -11.5px;
  margin-top: 2px;
  border: none;
}
.buttongreen i {
  color: var(--ui-color-primary-green-dark2) !important;
  cursor: pointer;
  font-size: 14px !important;
}
.startupTable {
  width: 100%;
  min-width: 1300px;
  font-weight: 12px;
  border-radius: 4px;
}
.startupTable thead tr {
  background: #f9f9f9;
  min-height: 42px;
}

.startupTable tr {
  border: 1px solid #f0f0f0;
}
.startupTable thead tr td {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  color: #c0c2c5;
  position: relative;
  padding: 10px 8px;
  letter-spacing: 0.08em;
}

.startupTable thead tr td:not(:first-of-type) {
  min-width: 162px;
}
.startupTable thead tr td .exchangeIcon {
  transform: matrix(0, 1, 1, 0, 0, 0);
}
.startupTable thead tr td i {
  /*color: var(--ui-color-gray-medium);*/
  /*transform: matrix(0, 1, 1, 0, 0, 0);*/
  /*font-size: 8.5px;*/
  font-size: 10px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
}
.startupTable tbody tr {
  min-height: 55px;
  height: 55px;
  background: #ffffff;
}
.startupTable td {
  padding: 0 10px;
}
.startupTable tbody td {
  font-size: 11.5px;
  position: relative;
}
.startupTable tbody td:not(:last-child):after {
  position: absolute;
  content: "";
  right: 0;
  height: 45%;
  width: 1px;
  background-color: #f0f0f0;
  top: 50%;
  transform: translateY(-50%);
}

.exchange_cursor {
  cursor: pointer;
}
/*.startupTable td:nth-child(2) {*/
/*  min-width: 275px !important;*/
/*}*/

.startupTable thead td:nth-child(n) {
  /* background-color: pink; */
  padding-left: 15px;
}

/*.startupTable tbody td:nth-child(2) {*/
/*  font-size: 13.8px;*/
/*  font-weight: 600;*/
/*  color: var(--ui-color-gray-dark1);*/
/*  min-height: 55px;*/
/*  !* position: relative; *!*/
/*}*/

/*.startupTable tbody td:nth-child(2):after {*/
/*}*/

/*.startupTable tbody td:nth-child(2) img {*/
/*  min-width: 30px;*/
/*  display: inline;*/
/*  margin-right: 4px;*/
/*}*/

/* .startupTable tbody td:nth-child(4) {
 min-width: 30px;
 display: inline;
 margin-right: 4px;
 background-color: red;
} */

/* TODO: we cannot have "nth-child" in a dynamic Spreadsheet */

/*.startupTable td:nth-child(3) {*/
/*  !* min-width: 174px; *!*/
/*  max-width: 50px;*/
/*}*/
/*.startupTable td:nth-child(4) {*/
/*  !* min-width: 175px; *!*/
/*  min-width: 115px !important;*/
/*}*/
/*.startupTable td:nth-child(5) {*/
/*  !* max-width: 20px; *!*/
/*  !* display: flex; *!*/
/*  max-width: 50px;*/
/*  padding-right: 5px !important;*/
/*}*/
/*.startupTable td:nth-child(6) {*/
/*  !* min-width: 120px; *!*/

/*  !* min-width: 145px !important; *!*/
/*  min-width: 155px !important;*/
/*}*/
/*.startupTable tbody td:nth-child(6) {*/
/*  color: var(--ui-color-primary-green-dark2);*/
/*  font-size: 14px;*/
/*  font-weight: 600;*/
/*}*/
/*.startupTable td:nth-child(7) {*/
/*  !* min-width: 150px; *!*/
/*  min-width: 120px !important;*/
/*  !* text-align: center; *!*/
/*}*/
/*.startupTable tbody td:nth-child(7) {*/
/*  text-align: center;*/
/*  !* min-width: 120px !important; *!*/
/*}*/
/*.startupTable td:nth-child(8) {*/
/*  min-width: 136px;*/
/*}*/
/*.startupTable tbody td:nth-child(8) {*/
/*  !* min-width: 136px; *!*/
/*  font-size: 14px;*/
/*}*/
/*.startupTable td:nth-child(9) {*/
/*  min-width: 115px !important;*/
/*  min-width: 119px !important;*/
/*}*/

/*.startupTable tbody td:nth-child(9) {*/
/*  font-size: 14px;*/
/*}*/

/*.startupTable td:nth-child(10) {*/
/*  !* min-width: 115px !important; *!*/

/*  min-width: 75px !important;*/
/*}*/
/*.startupTable td:nth-child(11) {*/
/*  !* min-width: 115px !important; *!*/
/*  min-width: 75px !important;*/
/*}*/
/*.startupTable td:nth-child(12) {*/
/*  !* min-width: 115px !important; *!*/
/*  min-width: 75px !important;*/
/*}*/
/*.startupTable td:nth-child(13) {*/
/*  !* min-width: 115px !important; *!*/
/*  min-width: 90px !important;*/
/*}*/
/*.startupTable td:nth-child(14) {*/
/*  !* min-width: 115px !important; *!*/
/*  min-width: 75px !important;*/
/*}*/

/*.startupTable td:nth-child(15) {*/
/*  !* min-width: 115px !important; *!*/
/*  min-width: 75px !important;*/
/*}*/
/*.startupTable td:nth-child(16) {*/
/*  !* min-width: 115px !important; *!*/
/*  min-width: 75px !important;*/
/*}*/
/*.startupTable td:nth-child(17) {*/
/*  !* min-width: 115px !important; *!*/
/*  min-width: 75px !important;*/
/*}*/

.startupTable td ul {
  /*list-style: none;*/
  margin: 0;
  padding: 0;
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  box-sizing: border-box;
  padding-top: 3px;
}

/* .startupTable td ul li {
  margin: 1px;
} */

/* .startupTable td ul li span {
  background-color: #6a6a6a;
  padding: 2px 11px;
  color: #fff;
  border-radius: 15px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  position: relative;
} */
/* // this are old styles above only*/
.startupTable td span i {
  font-size: 12px;
  color: var(--ui-color-gray-medium);
  margin-left: 5px;
  cursor: pointer;
}

.startupTable td .add_score {
  margin-left: 5px;
}
.startupTable td .edit_score i {
  color: var(--ui-color-primary-green-dark1);
}
.startupTable td .edit_score i:hover {
  color: var(--ui-color-primary-green-dark2);
}

.customStar {
  margin-top: 2px !important;
  margin-left: 17px;
  cursor: pointer;
}
.customStar img {
  /* margin-left: 15.4px !important ; */
  /* margin-left: 14.4px !important ; */

  cursor: pointer !important;
  height: 12px !important;
}

.customCheck {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.customCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  /* top:-10px; */
  position: absolute;
  /* top: 60%; */
  left: 12.5px;
  height: 15px;
  width: 15px;
  transform: translateY(-0%);
  border-radius: 3px;
  border: 1px solid var(--ui-color-gray-dark6);
}
/* On mouse-over, add a grey background color */
.customCheck:hover input ~ .checkmark {
  border-color: rgb(190, 190, 190);
}

/* When the checkbox is checked, add a blue background */
.customCheck input:checked ~ .checkmark {
  background-color: var(--ui-color-primary-green-dark2);
  border: none;
}

.favStartup {
  /* position: absolute;
  left: 52.3px;
  top:24.5px; */
  margin-left: 45px;
}
.favStartup i {
  color: #c4c4c4;
  font-size: 11px;
  cursor: pointer;
  color: #c4c4c4;
  font-size: 11px;
  cursor: pointer;
}

/* .olderThan1 {
  background: #d9f9f2;
  border-radius: 15px;
  padding: 5px 12px;
  text-align: center;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.03em; */
/*border: 1px solid #ffc244;*/
/* color: var(--ui-color-gray-medium);
} */

/* .olderThan2 {
  background: var(--ui-color-secondary-yellow);
  border-radius: 15px;
  padding: 5px 12px;
  text-align: center;
  font-family: "Roboto"; */
/*border: 1px solid #ffc244;*/
/* font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.03em;
  color: var(--ui-color-gray-medium);
} */

.olderThan3 {
  background: #ffa701;
  border-radius: 15px;
  padding: 5px 12px;
  text-align: center;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.03em;
  color: var(--ui-color-white);
}

.startupTable .startupStatus {
  font-size: 14px;
  color: var(--ui-color-gray-dark1);
}
.startupTable .startupStatus img {
  height: 12.5px;
  margin-right: 3px;
  /* margin-top: 2px; */
  margin-bottom: 4.5px;
}
.startupTable .startupStatus i {
  font-size: 12px;
  transition: all 0.1s ease;
  cursor: pointer;
}

.startupPreview {
  position: absolute !important;
  top: 63px;
  background-color: #fff;
  max-width: 350px !important;
  width: 350px !important;
  padding: 20px;
  z-index: 999999999999;
  box-shadow: 0px 3px 12px rgba(41, 70, 103, 0.2);
  border-radius: 4px;
}

@media screen and (max-width: 543px) {
  .startupPreview {
    position: fixed !important;
    top: 15% !important;
    width: 90% !important;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.startupPreview h1 {
  font-size: 16px;
  border-bottom: 1px solid #f0f0f0;
  color: var(--ui-color-gray-dark1);
  padding-bottom: 10px;
  font-weight: 700;
}
.startupPreview h3 {
  font-size: 13px;
  color: var(--ui-color-gray-dark1);
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
  margin-top: 8px;
}
.startupPreview p {
  font-size: 13px;
  line-height: 17px;
  color: var(--ui-color-gray-medium);
  font-weight: normal;
}

.user_profile_Img {
  cursor: pointer;
  padding-top: 4px;
  text-align: center;
  top: 16px;
  min-width: 27px;
  min-height: 27px;
  width: 27px;
  height: 27px;
  overflow: hidden;
  color: white;
  margin-right: 12px;
  border-radius: 50%;
  background: #b7b7b7;
}

.user_profile_Img.with_logo {
  background: none;
}

.user_profile_Img img {
  height: 120%;
  width: 120%;
  margin: -10px;
  top: -1px;
  position: relative;
}

.empty_list {
  text-align: center;
  padding: 20px;
  border-top: 1px solid var(--color-gray-light);
  color: var(--color-gray-medium);
}

.company_name {
  color: var(--color-secondary);
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
}

.funnelPopup {
  background-color: #fff;
  min-width: 180px;
  padding: 10px 0;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 5px rgba(30, 30, 30, 0.1);
  position: absolute;
  z-index: 900 !important;
  height: auto;
  left: 555px;
  /* top: 58px !important; */
  top: 60px;
  /* margin-top: 15px; */
  /* background-color: red; */
  overflow: visible;
  transition: all 0.1s ease-in;
  transform-origin: 0 0;
  animation: open 0.1s;
}

@keyframes open {
  0% {
    transform: scaleY(0.2);
  }

  100% {
    transform: scaleY(1);
  }
}

.funnelPopup ul {
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: #fff;
  width: 100%;
}
.funnelPopup ul li {
  margin: 0 !important;
  padding: 0;
  padding: 15px 17px;
  cursor: pointer;
  transition: background-color 0.1s ease-in;
}

.funnelPopup ul li svg {
  position: relative;
  left: -2px;
  top: 1px;
  margin-right: 5px;
}

.funnelPopup ul li:hover {
  background-color: var(--ui-color-gray-pale1);
}
.funnelPopup .floatingArrow {
  position: absolute;
  right: 15px;
  top: 7px;
  z-index: 100;
}

.selectDropdown {
  cursor: pointer;
  margin-left: 6px;
  /* background-color: red; */
  /* padding: 20px; */
}

.selectDropdown img {
  transform: rotate(180deg) !important;
}

/* Custom Check 2.0 */

.dealflowColumnHead .customCheck {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: block;
  margin-top: 5px;
}
.dealflowColumnHead .customCheck input {
  display: none;
}
/* Create a custom checkbox */
.dealflowColumnHead .checkmark {
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  border: 1px solid var(--ui-color-gray-dark6);
}
/* On mouse-over, add a grey background color */
.dealflowColumnHead .customCheck:hover input ~ .checkmark {
  border-color: rgb(190, 190, 190);
}

/* When the checkbox is checked, add a blue background */
.dealflowColumnHead .customCheck input:checked ~ .checkmark {
  background-color: var(--ui-color-primary-green-dark2);
  border: none;
}

/* Spreadsheet Header Checkmark */

.tableHeaderCheckmark .customCheck {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  cursor: pointer;
  margin-left: 20px;
}

.tableHeaderCheckmark > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.tableHeaderCheckmark .customCheck input {
  display: none;
}
/* Create a custom checkbox */
.headCheckmark {
  display: block;

  width: 15px;
  height: 15px;
  border-radius: 3px;
  border: 1px solid var(--ui-color-gray-dark6);
}

/* On mouse-over, add a grey background color */
.tableHeaderCheckmark .customCheck:hover input ~ .checkmark {
  border-color: rgb(190, 190, 190);
}

/* When the checkbox is checked, add a blue background */
.tableHeaderCheckmark .customCheck input:checked ~ .checkmark {
  background-color: var(--ui-color-primary-green-dark2);
  border: none;
}

.activePopup i {
  color: var(--ui-color-primary-green-dark2) !important;
  transform: rotate(180deg);
}

/* .tagList li {
  display: inline-block; */
/* background-color: red; */
/* } */

/* .TagListItem {
  padding: 1px 0px;
} */
.tag-slice {
  background-color: red !important;
  /* font-weight: 500;
  margin-bottom: 50px; */
}
.tagList li button {
  /* color: red;
  font-weight: 500;
  margin-bottom: 50px; */
}

.groupList li,
.dateList li,
.tagList li {
  display: inline-block;
}
.tagList li {
  /* display: inline-block; */
  /* margin-bottom: 2px; */
}

.groupListItem,
.dateListItem {
  font-size: 12px;
  padding: 1px 0px;
}
.TagListItem{
  cursor: context-menu !important;
}
.groupListItem {
  cursor: pointer;
}
.tags_lists div {
  display: inline-block;
}
.table_tag {
  padding: 2px 11px;
}
.funnelTag {
  cursor: pointer;
  display: flex;
  border: 1px solid #f0f0f0;
  align-items: baseline;
  border-radius: 15px;
  padding: 5px;
  padding-top: 5px;
  padding-bottom: 2px;
  width: auto;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-left: 10px;
  margin: 5px 2px;
}
.funnelTag svg {
  padding-top: 2px;
  margin-right: 5px;
}
.funnelTag .funnel_icon{
  justify-content: center;
  text-align: center;
  align-items: center;
}
.funnelTag span {
  margin-left: auto;
  margin-right: 5px;
  margin-top: -2px;
  font-size: 5px;
}

.funnelTag p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: -3px;
  font-size: 12px;
}

.funnelTag img {
  top: 2px;
  position: relative;
  padding-left: 2px;
}

.columnType_name {
  min-width: 275px !important;
  max-width: 275px !important;
}

tbody .columnType_name {
  font-size: 13.8px;
  font-weight: 600;
  color: var(--ui-color-gray-dark1);
  min-height: 55px;
}

.columnType_groups {
  max-width: 50px;
  padding-bottom: 15px;
}

.sortingColumn {
  color: var(--color-gray-dark) !important;
}

.columnType_funnels {
  min-width: 115px !important;
  /* background-color: red; */
  /* padding-top: 5.5px !important; */
  /* background-color: red; */
}

.columnType_tags {
  /*max-width: 75px;*/
  max-width: 50px;
  padding-right: 5px !important;
}
/*.startupTable tbody td:nth-child(5) {*/
/*  padding-bottom: 3px;*/
/*}*/
/*.startupTable tbody td:nth-child(3) {*/
/*  padding-bottom: 3px;*/
/*}*/
/*.startupTable tbody td:nth-child(7) {*/
/*  padding-bottom: 3px;*/
/*}*/
/*.startupTable tbody td:nth-child(4) {*/
/*  padding-bottom: 3px;*/
/*  padding-top: 3px;*/
/*}*/

.columnType_subjectiveScore {
  min-width: 155px !important;
}

tbody .columnType_subjectiveScore {
  font-size: 16px !important;
  color: orange;
  font-weight: 600;
}

.columnType_timeStamp {
  min-width: 150px !important;
}

tbody .columnType_timeStamp {
  text-align: center;
}

.columnType_evaluation {
  min-width: 75px !important;
}

tbody .columnType_evaluation {
  font-size: 16px !important;
  color: orange;
  font-weight: 600;
}

.startupTable td:nth-child(1) {
  width: 82px;
}