.popup {
  /* background-color: #fff; */
  min-width: 120px;
  padding: 20px 10px 10px 10px;
  border-radius: 8px;
  /* box-shadow: 0px 3px 20px 5px rgba(30, 30, 30, 0.1); */
  position: absolute;
  z-index: 90000 !important;
  height: auto;
  top: 35px;
  margin-left: 12px;
  overflow: visible;
  transition: all 0.1s ease-in;
  transform-origin: 0 0;
  animation: open 0.1s;
  cursor: pointer;
}

.subpopup{
    background-color: #fff;
    min-width: 120px;
     padding: 10px 0;
    border-radius: 8px;
    box-shadow: 0px 3px 20px 5px rgba(30, 30, 30, 0.1);
    /* position: absolute; */
    z-index: 90000 !important;
    height: auto; 
    /* top: 60px; */
    /* margin-left: 20px;
    overflow: visible;
    transition: all 0.1s ease-in;
    transform-origin: 0 0;
    animation: open 0.1s;  */
}

@keyframes open {
  0% {
    transform: scaleY(0.2);
  }

  100% {
    transform: scaleY(1);
  }
}

.menu_items {
  text-align: left;
}

.menu_items .menuItem {
  cursor: pointer;
  color: var(--ui-color-gray-dark1);
  text-transform: capitalize;
  font-size: 14px;
  padding: 10px;
  font-weight: normal;
  transition: all 0.1s ease-in;
}

.menu_items .menuItem:hover,
.menu_items .menuItem:focus {
  background-color: var(--ui-color-gray-pale1);
}

.nestedMenu {
  display: none;
  background-color: #fff;
  min-width: 120px;
  padding: 10px 0;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 5px rgba(30, 30, 30, 0.1);
  position: absolute;
  z-index: 90001 !important;
  height: auto;
  margin-top: -45px;
  left: calc(100% - 3px);
  transition: all 0.1s ease-in;
  transform-origin: 0 0;
  animation: open 0.1s;
}

.nestedMenu:hover,
.nestedMenu:focus {
  display: block;
}

.menuItem:hover + div,
.menuItem:focus + div {
  display: block;
}

.nestedMenuItem {
  cursor: pointer;
  color: var(--ui-color-gray-dark1);
  text-transform: capitalize;
  font-size: 14px;
  padding: 10px;
  font-weight: normal;
  transition: all 0.1s ease-in;
}

.nestedMenuItem:hover,
.nestedMenuItem:focus {
  background-color: var(--ui-color-gray-pale1);
}
