.grid-view {
  display: flex;
  flex-wrap: wrap;

  @media only screen and(min-width:500px) {
    margin-left: -6px;
    margin-right: -6px;
  }


  &__startup {

    width:100%;

    @media only screen and(min-width:500px) {
      width: 50%;
      padding-left: 5px;
      padding-right: 5px;
    }
    @media only screen and(min-width:800px) {
      width: 33.33%;
      padding-left: 5px;
      padding-right: 5px;
    }

    &__header{

      display: flex;
      padding-bottom: 15px;
      border-bottom: 1px solid #F0F0F0;
      align-items: center;
      cursor: pointer;
      font-size: 18px;

      .logo{
        margin-right: 12px;
        font-size: 18px !important;
      }

    }

    &__description {
      margin-top: 10px;
      color:#969BA3;
      font-family: "Roboto";
      font-size: 14px;
      line-height: 23px;
      height: 131px;
      overflow: hidden;
      position: relative;

      &__clipper {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }


      @media only screen and(max-width:500px) {
        height: auto;
        min-height: 5px;
      }

    }

    &__tags {
      padding-top: 15px;
      padding-bottom: 5px;
    }


    &__more-info{
      margin-top:10px;
      width: 100%;
      padding-bottom: 22px;
      text-align: center;
      border-bottom: 1px solid #F0F0F0;
      cursor: pointer;
      color: #53cab2;
      a{
        font-family: "Roboto";
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #53CAB2!important;
        text-transform: uppercase;
        &:hover{
          text-decoration: none;
        }
      }
    }
    &__button-container {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      font-size: 25px;
      padding: 0px 10px;
      color: var(--ui-color-gray-light1);
      cursor: pointer;

      &__selected-heart {
        color: #ff9797;
      }

      &__selected-download {
        color: #53cab2;
      }

    }
  }


}