.sharing-opions-contianer {
  .loader-blocker {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 99999;
  }

  .question {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    margin-top: 35px;
    color: var(--ui-color-gray-medium);
  }
  .options-container {
    margin-top: 35px;
    .option {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      margin-top: 10px;
      margin-left:25px;
      color: var(--ui-color-gray-medium);
      // position: relative;
      // left: 12px;
      // top: -1px;
      i {
        font-size: 12px;
        padding-left: 10px;
      }
      &_heading{
        color: var(--ui-color-gray-medium);
        font-weight: normal;
        font-size: 16px;
        margin-top:20px;
        margin-left:25px;
      }
    }
    .evaluation-options-container {
     // margin-left: 25px;
      margin-top: 15px;
    }
  }
}
.MuiSvgIcon-root {
  position: relative;
  left: 12px;
  top: -1px;
}
