.archive_and_delete__container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 20px auto;

  @media screen and (max-width: 1050px) {
    justify-items: center;
  }

  @media screen and (max-width: 768px) {
    justify-items: start;
  }

  @media screen and (max-width: 600px) {
    justify-items: center;
  }
  .open-archive {
    justify-self: end;

    @media screen and (max-width: 1050px) {
      justify-self: center;
    }

    @media screen and (max-width: 768px) {
      justify-self: end;
    }

    @media screen and (max-width: 600px) {
      justify-self: center;
    }
  }
}

.except-tab {
  @media screen and (max-width: 1200px) {
    justify-items: center;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  .open-archive {
    @media screen and (max-width: 1200px) {
      justify-self: center;
    }
  }
}

.tab-always {
  display: none;

  @media screen and (max-width: 768px) {
    display: grid;
  }

  .open-archive {
    justify-self: end;

    @media screen and (max-width: 600px) {
      justify-self: center;
    }
  }
}
