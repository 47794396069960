.score-summary {

  font-family: "Proxima Nova";

  width: 250px;
  margin-bottom: 20px;
  margin-top: 10px;

  .title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--ui-color-gray-dark1);
    @media screen and (max-width: 410px) {
      display: flex;
      width: 80%;
    }
  }

  .entries-line {
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--ui-color-gray-medium);
  }

  .scores-container {
    display: flex;
    align-items: center;
    margin-top: 6px;

    .score {
      font-size: 44px;
      line-height: 44px;
      margin-top: 5px;
      color: var(--ui-color-gray-dark1);
      padding-right: 15px;
    }

    .highest-lowest-score {

      .record {
        height: 20px;
        width: 100px;
        display: flex;
        align-items: center;
        text-align: left;
      }

      .score {
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        width: 24%;
        text-align: right;
        padding-right: 4px;
      }

      .highest {
        color: var(--ui-color-primary-green-dark2);
      }
      .lowest {
        color: var(--ui-color-red);
      }

      .score-label {
        font-weight: 600;
        font-size: 10px;
        line-height: 11px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: var(--ui-color-gray-medium);
        margin-left: 5px;
        width: 60%;
      }
    }
  }
}
