/* FLOATING LOGIN BUTTON CSS STARTS HERE */
.floating_loginButtons {
  display: flex;
  position: absolute;
  right: 40px;
  top: 25px;
  width: 260px;
  justify-content: space-between;
    // media quaries
    @media screen and (max-width: 940px) {
        position: relative;
        margin: auto;
        top: -0px;
        right: 0;
        margin-top: 20px;
        width: 339px;
          button {
            width: 45% !important;
            height: 40px;
          }
     }
     @media screen and (max-width: 422px) {
        position: fixed;
        // top: 85% !important;
        left: 50%;
        transform: translateX(-50%);
        width: 85%;
        height: 43px;
      
    }
}


