.startup-container {
  // display: flex;
  // width: 100%;
  justify-content: center;
  &__content {
    width: 100%;
    &__answers {
      font-family: "Roboto";
      &__module-heading {
        margin-top: 45px !important;
        color: #6a6a6a;
        font-family: "Roboto";
        font-weight: bold;
        font-size: 19px;
        line-height: 22px;
      }
      &__module-description {
        margin-top: 7px !important;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #969ba3;
      }
      .card-message {
        padding-left: 0px;
        margin-top: 15px;
        &__content {
          padding: 10px !important;
          .material-icons {
            display: none;
          }
          &__message {
            font-weight: 500;
            line-height: 26px;
          }
        }
      }
    }
  }
}

.page-heading {
  color: var(--ui-color-primary-green-dark4);
  font-size: 20px;
  cursor: pointer;
}
.back-button {
  cursor: pointer;
}
