.printable-startup {

  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 75px;
  margin-bottom: 75px;
  line-height: 2;
  padding-bottom: 100px;

  &__evaluation-section {
    &__title {
    font-size: 24px;
    }

    &__by-name {

    }

    &__by-date {

    }

    &__summary {
      &__header {
        padding-top: 20px;
      }

      &__section {

        display: flex;
        justify-content: space-between;
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;

        &:nth-child(odd) {
          background: rgba(128,128,128,0.1);

        }

        &__name {

        }
        &__points {

        }
      }

    }

  }

  &__template-form {


    &__tags {
      &__tag {}
    }

    &__name {
      font-size: 28px;
      font-weight: 900;
    }

    &__section {
      padding-top: 50px;

      &__name {
        font-size: 22px;
        position: relative;
        top: 20px;
      }

      &__question {
        padding-top: 35px;
        position: relative;

        &__name {
          font-size: 16px;
          font-weight: 600;
        }

        &__description {
          font-style: italic;
          opacity: 0.8;
          padding-bottom: 5px;
        }

        &__answer {
          position: relative;
        }

        &__no-answer {
          opacity: 0.4;
        }

        &__comment {
          font-style: italic;
          opacity: 0.8;
        }
      }


    }


  }

  .block-style {
    padding-left: 40px;
    border-left: 4px solid rgba(128,128,128,0.2);
    margin-top: 75px;
  }


  hr {
    border-top: 1px solid rgba(128,128,128,0.2);
    margin-top: 100px;
    margin-bottom: 100px;
  }

}