.overview-groups-container {
  &__groups {
    margin-top: 25px;
  }
  .group-name {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--ui-color-gray-dark1);
    margin-top: 5px;
    display: block;
    text-decoration: underline;
    cursor: pointer;
    padding: 4px;
  }
  .group-name:nth-child(odd) {
    background: var(--color-gray-pale);
  }

  .add-startup-container {
    display: inline-flex;
    @media only screen and(max-width:768px) {
      display: initial;
      padding-left: 0px;
      margin-top: 10px;
    }
    .add-text {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-medium);
      margin-top: 10px;
      margin-right: 10px;
      @media only screen and(min-width:768px) {
        position: absolute;
        right: 210px;
      }
    }
    .add-startup-to-group {
      width: 100%;
      display: flex;
      align-items: center;

      .green_plus {
        margin-top: -3px;
        margin-left: 4px;
      }

      @media only screen and(min-width:768px) {
        width: 190px;
        position: absolute;
        right: 20px;
      }
    }
  }

  &__add-button {
    i {
      background-color: var(--ui-color-primary-green-dark2);
      border-radius: 50%;
      height: 23px;
      width: 23px;
      text-align: center;
      font-size: 15px;
      margin-top: 10px;
      padding-top: 4px;
      color: var(--ui-color-white);
      cursor: pointer;
    }
  }
}
