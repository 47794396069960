.notata-list-table {

  font-family: "Proxima Nova";

  width: calc(100% + 24px);
  margin-left: -12px;
  margin-right: -12px;

  tr {
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 16px;
    line-height: 18px;
  }

  td {
    padding: 7px 12px;
  }

  thead {

    tr {
      color: var(--ui-color-gray-dark1);
      font-weight: 900;
    }

  }



  tbody {

    margin-top: 10px;

    tr {
      color: var(--ui-color-gray-medium);
    }

    tr:nth-child(odd) {
      background-color: rgba(223, 233, 245, 0.2);
    }

  }

  i {
    font-size: 12px;
    display: inline-block;
  }

  a, a:hover {
    color: var(--ui-color-gray-medium);
    cursor: pointer;
  }

}





