.tableModalOutter * {
  box-sizing: border-box;
}
.tableModalOutter {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.87);
  top: 0;
  left: 0;
  z-index: 9999999;
  overflow-y: auto;
}
.tableModalInner {
  position: fixed;
  background: var(--ui-color-white);
  box-shadow: 0px 3px 12px rgba(41, 70, 103, 0.2);
  border-radius: 6px;
  width: 550px;
  max-height: 98vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 17px;
  overflow-y: auto;
}
.tableModalInner i {
  cursor: pointer;
}
.tableModalInner .top h1 {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--ui-color-gray-medium);
  border-bottom: 1px solid var(--ui-color-gray-pale1);
  padding-bottom: 17px;
  margin-bottom: 23px;
}
.tableModalInner .footer {
  padding: 15px 0 0 0;
  margin-top: 20px;
}
.tableModalInner .footer .buttonContainer {
  display: flex;
  justify-content: flex-end;
}
.tableModalInner .footer button {
  outline: none;
  padding-top: 2px;
  box-sizing: border-box;
}
.tableModalInner .footer button:first-child {
  width: 102px;
  height: 40px;
  border: 1px solid var(--ui-color-gray-medium);
  box-sizing: border-box;
  border-radius: 20px;
  background-color: transparent;
  margin-right: 10px;
  color: var(--ui-color-gray-medium);
}
.tableModalInner .footer button:last-child {
  width: 102px;
  height: 40px;
  background: var(--ui-color-primary-green-dark2);
  border-radius: 20px;
  color: var(--ui-color-white);
  border: none;
}
.tableModalInner .footer button:last-child i {
  color: var(--ui-color-white);
}
.tableModalInner .close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.tableModalInner .close i {
  color: var(--ui-color-gray-pale2);
}
.createAnotherContainer {
  display: flex;
  align-items: baseline;
}
.createAnotherContainer_checkbox_container {
  margin-right: 10px;
}
/* GROUP MODAL CSS STARTS HERE */
.group {
  display: flex;
  flex-wrap: wrap;
}
.groupChild {
  /*width: 50%;*/
  width: 100%;
  padding: 10px 0;
}
.nogroups,
.noFilteredGroups {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  font-family: Proxima Nova;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: var(--ui-color-gray-medium);
}
.groupChild h2 {
  text-transform: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: var(--ui-color-gray-medium);
  margin-bottom: 15px;
}
.groupChild ul li {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 17px;
  color: var(--ui-color-gray-dark1);
  margin-top: 16px;
}
.groupChild i {
  color: var(--ui-color-primary-orange-dark1);
  margin-left: 5px;
}
.groupChild select {
  width: 190px;
  height: 40px;
  border: 1px solid var(--ui-color-gray-dark2);
  border-radius: 20px;
  padding: 0 20px;
  appearance: none;
  outline: none;
  width: 100%;
  color: var(--ui-color-gray-dark1);
  cursor: pointer;
  position: relative;
  display: grid;
}
.groupChild select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: var(--ui-color-gray-dark7);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}
.groupChild select::-ms-expand {
  display: none;
}
.groupChild .groupDropContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.groupDropContainer .fa-plus-circle {
  color: var(--ui-color-primary-green-dark2);
  opacity: 0.3;
}
.groupDropContainer i {
  color: var(--ui-color-gray-dark2);
}
.funnelImage {
  width: 70%;
}
.groupFunnelChild {
  text-align: left;
  padding-left: 10px;
}

/* SCORE CSS STARTS HERE */
.score {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px 0;
}
.score .child {
  width: 10%;
  display: flex;
  justify-content: center;
}
.score .child p {
  width: 40px;
  height: 40px;
  border: 1px solid var(--ui-color-gray-medium);
  box-sizing: border-box;
  text-align: center;
  border-radius: 20px;
  color: var(--ui-color-gray-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.score .activeChild p {
  height: 40px;
  width: 40px;
  background: #c4a9fc;
  border-radius: 20px;
  color: var(--ui-color-white);
  border: none;
}

/* STARTUP CSS STARTS HERE */
.startup {
  margin-top: -17px;
  margin-bottom: -15px;
}

.startup .expand {
  display: flex;
  margin-top: 15px;
}
.startup .expandLeft {
  width: 65%;
}
.startup .expandRight img {
  width: 60%;
}
.inputContainer {
  margin-top: 15px;
}
.inputContainer p {
  font-weight: 500;
  font-size: 13.5px;
  line-height: 15px;
  display: flex;
  letter-spacing: normal !important;
  align-items: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: var(--ui-color-gray-medium);
  margin-bottom: 7px;
  text-transform: none !important;
}
.startupGroupContainer {
  display: flex;
  margin-top: 15px;
}
.startupGroupContainer div {
  width: 50%;
}
.startupGroupContainer ul {
  padding: 10px;
  display: flex;
}
.startupGroupContainer ul li {
  font-size: 12px;
  margin-right: 10px;
}
.startupGroupContainer i {
  color: var(--ui-color-primary-orange-dark1);
}
.groupDropContainer {
  display: flex !important;
}
.groupDropContainer i {
  margin-left: 6px;
}
.startup .expandRight {
  width: 35%;
  text-align: center;
  padding-left: 25px;
}
.startupFunnel {
  margin: auto;
  margin-bottom: 25px;
  width: 75%;
  justify-content: center;
}
.startupFunnel p {
  width: 45%;
}
.startupFunnel p i {
  margin-left: 7px;
  color: var(--ui-color-primary-orange-dark1);
}
.mobileFunnelImage {
  display: none;
}
@media screen and (max-width: 832px) {
  .expand {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .expandLeft {
    width: 100% !important;
  }
  .expandRight {
    width: 100% !important;
    padding: 0 !important;
    text-align: center;
  }
  .mobileFunnelImage {
    display: block;
  }
  .desktopFunnelImage {
    display: none;
  }
  .expandRight img {
    width: 100% !important;
  }
  .tableModalInner {
    width: 95% !important;
    padding: 20px 13px;
  }
  .startupGroupContainer {
    display: flex;
    flex-direction: column;
  }
  .startupGroupContainer div {
    width: 100% !important;
  }
  .groupDropContainer {
    width: 100% !important;
  }
}
@media screen and (max-width: 609px) {
  .score .child {
    width: 20%;
    margin-bottom: 20px;
  }
  .groupChild {
    width: 100%;
    margin-bottom: 25px;
  }
  .funnelImage {
    width: 99% !important;
  }
  .startupFunnel {
    width: 100% !important;
    flex-direction: column;
  }
}
.sartupList {
  margin-top: 20px;
}
.checkIcon {
  font-size: 14px !important;
  color: darkgrey;
}
.sartupList_startup {
  margin-top: 10px;
}
.startupName {
  margin-left: 10px;
  font-size: 14px;
  color: var(--ui-color-primary-green-dark4);
  font-family: "Roboto";
  cursor: pointer;
}
.message {
  margin-left: 3px;
  font-size: 14px;
  color: var(--ui-color-gray-dark7);
  font-family: "Roboto";
}
.startup .short .doyoumean {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.03em;
  height: 17px;
  color: var(--ui-color-gray-medium);
}
.startup .doyoumean span {
  font-weight: 500;
  text-decoration: underline;
}

@media screen and (max-width: 435px) {
  .footer {
    padding: 15px 0 0 0;
    margin-top: 3px;
  }
  .startup .short .doyoumean {
    margin-left: 8px;
  }
}
@media screen and (max-width: 325px) {
  .createAnotherContainer_checkbox_container p {
    margin-left: -3px;
    font-size: 11px;
  }
}
.btnSpace {
  margin-bottom: 25px !important;
  float: right;
}
.tagsPlaceholder {
  width: 100%;

  border: 1px solid var(--ui-color-gray-dark2);
  margin-top: 10px;
  border-radius: 20px;
  padding: 8px;
  display: flex;
  flex-flow: row wrap;
  z-index: 999;
  min-height: 40px;
}

/* @media only screen and (min-width: 768px) and (max-width: 1115px) {
  .tagsPlaceholder {
    width: 80%;
  }
} */

.tagsPlaceholder i {
  background-color: var(--ui-color-primary-green-dark2);
  border-radius: 50%;
  height: 18px;
  width: 18px;
  text-align: center;
  font-size: 10px;
  margin-top: 2px;
  /* position: absolute; */
  padding-top: 4px;
  margin-left: 7px;
  color: var(--ui-color-white);
  cursor: pointer;
}

.startupFunnel > div {
  flex-direction: column-reverse;
}

.startupFunnel > div > div {
  width: 100%;
}

.companyLink {
  color: var(--ui-color-primary-green-dark2);
  cursor: pointer;
}

.notatabtn {
  position: relative;
  top: -20px;
  left: 67%;
}
