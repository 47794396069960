.notifications-container-new {

  &__notification {
    display: flex;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--ui-color-gray-pale1);

    .icon i {
      color: var(--ui-color-primary-green-dark2);
      font-size: 25px;
      
    }
    img {
      height: 24px;
      width: 24px;
    }

    &__text {
      font-family: "Roboto";
      font-style: normal;
      font-size: 13px;
      line-height: 17px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-medium);
      padding-left: 20px;

      &__message {
        color: var(--color-gray-dark);
        
      }

      &__username {
        font-weight: 500;
        text-decoration-line: underline;
      }
      &__hour-ago {
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-medium);
        margin-top: 10px;
      }
      &__comment {
        font-family: "Roboto";
        font-style: italic;
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark1);
        margin-top: 5px;
      }
    }

    &__action-buttons {
      margin-top: 10px;
      margin-bottom: 10px;
      .link_text{
        text-decoration: none;
        :hover{
          color: var(--ui-color-white);
        }
      }
        .x_small {
        border: 0;
        background-color: var(--ui-color-primary-green-dark2);
        color: white;
        border-radius: 20px;
        margin-top: -3px;
        width: auto;
        font-size: 11px;
        padding: 5px 12px;
        padding-top: 6px;
        margin-right: 10px;
        text-transform: uppercase;
        font-weight: 500;
        height: 25px;
        line-height: 1;
      }

      .accept-button {
        background-color: var(--ui-color-primary-green-dark2) !important;
      }

      .reject-button {
        background-color: var(--ui-color-primary-orange-dark1) !important;
      }

      .view-button {
        color: var(--ui-color-gray-medium);
        background-color: var(--ui-color-white) !important;
        border: 1px solid;
      }
    }
  }
}



