.sidebar {
  width: 300px;
  background-color: var(--ui-color-white);
  position: fixed;
  height: 100vh;
  top: 50px;
  padding-top: 40px;
  z-index: 9999;
  box-shadow: 0px 3px 12px rgba(41, 70, 103, 0.2);
  transition: all;
  transition-duration: 0.5s;
  right: 0;
  padding-left: 20px;
  padding-right: 18px;
  overflow-y: scroll;
  overflow-x: hidden;
  // background-color: red;
  // overflow-y: scroll !important;
  /* Overriding the scroll: IMPORTANT */
  padding-bottom: 100px;
  /* transform: translateX(100%); */
  animation: sidebar 0.15s ease-in;

  .padLeft {
    padding-left: 10px;
  }

  .symbolColor {
    color: var(--ui-color-primary-green-dark2);
    cursor: pointer;
  }

  &__Togler {
    font-size: 18px;
    cursor: pointer;
  }
  &__padLeft {
    padding-left: 10px;
  }

  &__Main {
  }
}

@keyframes sidebar {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.sidebar::-webkit-scrollbar {
  // width: 0px;
}

.sidebar::-webkit-scrollbar-thumb {
  // background-color: var(--ui-color-primary-green-dark2);
  // box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.03);
  // border-radius: 20px;
  // z-index: -1;
}

.sidebarToggler i .sidebar .title {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--ui-color-gray-medium);
  margin-top: 15px;
}

.sidebar .title i {
  margin-right: 5px;
  color: var(--ui-color-gray-medium);
}

/* MANAGE CSS STARTS HERE */
.manage {
  ul {
    margin: 30px 0;
    li {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      color: var(--ui-color-gray-dark1);
      margin-bottom: 18px;

      .customCheck {
        width: 50px;
        display: inline;
        height: 30px;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .disabled-checkmark {
          position: absolute;
          top: 60%;
          left: 0px;
          height: 15px;
          width: 15px;
          transform: translateY(-0%);
          border-radius: 3px;
          //border: 1px solid var(--ui-color-gray-dark6);
          margin-top: 2px;
          background-color: var(--color-gray-light);
          border: none;
          &::after {
            display: block;
            content: "";
            position: absolute;
            left: 3px;
            top: 6px;
            width: 9px;
            height: 3px;
            border-width: 0 3px 3px 0;
            background: white;
          }
        }

        .checkmark {
          position: absolute;
          top: 60%;
          left: 0px;
          height: 15px;
          width: 15px;
          transform: translateY(-0%);
          border-radius: 3px;
          border: 1px solid var(--ui-color-gray-dark6);
          margin-top: 2px;
          .checkmark::after {
            content: "";
            position: absolute;
            display: none;
            left: 35%;
            top: 10%;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        &::after {
          left: 35%;
          top: 10%;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:hover input ~ .checkmark {
          border-color: rgb(190, 190, 190);
        }
        input:checked ~ .checkmark {
          background-color: var(--ui-color-primary-green-dark2);
          border: none;
        }
      }
    }
    ul {
      margin: 0;
      margin-left: 30px;
      margin-top: 15px;
    }
    p {
      padding-left: 27px;
      margin-top: -4px;
      box-sizing: border-box;
    }
    .chek_box_text {
      font-weight: normal !important;
      cursor: pointer;
    }
  }
  input {
    margin-right: 4px;
    line-height: 24px;
  }
}

// .customCheck input {
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
//   height: 0;
//   width: 0;
// }
// /* Create a custom checkbox */
// .checkmark
/* On mouse-over, add a grey background color */
// .customCheck:hover input ~ .checkmark {
//   border-color: rgb(190, 190, 190);
// }

// /* When the checkbox is checked, add a blue background */
// .customCheck input:checked ~ .checkmark {
//   background-color: var(--ui-color-primary-green-dark2);
//   border: none;
// }
// /* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

// /* Show the checkmark when checked */
.customCheck input:checked ~ .checkmark:after {
  display: block;
}

// /* Style the checkmark/indicator */
.customCheck .checkmark:after {
  left: 35%;
  top: 10%;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.clearAllButton {
  outline: none;
  border: none;
  height: 28px;
  background-color: var(--ui-color-primary-green-dark2) !important;
  color: var(--ui-color-white);
  border-radius: 20px;
  margin-top: -3px;
  width: auto;
  font-size: 14px;
  padding: 0 15px;
  position: absolute;
}

.filter {
  .search {
    width: 100%;
    position: relative;
    margin: 50px 0 40px 0;
    input {
      width: 100%;
      height: 40px;
      border-radius: 20px;
      border: 1px solid var(--ui-color-gray-pale2);
      padding: 0 20px;
      color: var(--ui-color-gray-dark1);
      margin: 0;
    }
    button {
      border: none;
      height: 40px;
      background-color: var(--ui-color-primary-green-dark2);
      color: var(--ui-color-white);
      border-radius: 20px;
      width: 90px;
      position: absolute;
      right: 0;
      top: 0px;
    }
  }

  .funnelStage {
    margin: 30px 0 20px 0;
    h2 {
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: center;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: var(--ui-color-gray-medium);
      margin-bottom: 10px;
    }
  }
}
.filterbar_stared_content {
  h2 {
    color: var(--ui-color-gray-medium);
    font-size: 11px;
    letter-spacing: 1px;
    margin-bottom: 7px;
  }
  .fa-star {
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .star-select {
    color: orange;
  }
  .star-not-select {
    color: #d7d7d7 !important;
  }
}

// .filter .search button {
//   border: none;
//   height: 30px;
//   background-color: var(--ui-color-primary-green-dark2);
//   color: var(--ui-color-white);
//   border-radius: 20px;
//   width: 90px;
//   position: absolute;
//   right: 0;
//   top: 0;
// }

// .clearAllButton {
//   border: none;
//   height: 28px;
//   background-color: var(--ui-color-primary-green-dark2) !important;
//   color: var(--ui-color-white);
//   border-radius: 20px;
//   margin-top: -3px;
//   width: auto;
//   font-size: 14px;
//   padding: 0 15px;
//   position: absolute;
//   /* right: 0; */
//   /* top: 0;  */
// }

// .funnelStage {
//   margin: 20px 0;
// }
// .funnelStage h2 {
//   font-weight: bold;
//   font-size: 11px;
//   line-height: 13px;
//   display: flex;
//   align-items: center;
//   letter-spacing: 0.12em;
//   text-transform: uppercase;
//   color: var(--ui-color-gray-medium);
//   margin-bottom: 15px;
// }
.funnelUl li {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.funnelUl li div {
  display: flex;
}
.funnelUl li .image {
  padding-left: 10px;
}
.funnelUl li img {
  margin-left: 12px;
  margin-top: 5px;
  width: 130px;
}
.funnelUl .customCheck span {
  margin-top: 1px;
}
.funnelUl p {
  padding-left: 10px;
  font-size: 13px;
}
.funnelUl p .radio_btn_font {
  font-weight: normal;
  font-weight: 400 !important;
}

.funnelUl .funnel_name {
  text-transform: capitalize;
}
/* DATE CSS STARTS HERE */
.dateStage {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
.dateStage div {
  width: 49%;
}
.dateStage div input {
  width: 100%;
  border: 1px solid var(--ui-color-gray-pale2);
  height: 30px;
  border-radius: 20px;
  color: var(--ui-color-gray-pale2);
  padding: 0 5px;
  font-size: 12px;
}
.dateStage label {
  font-size: 12px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: normal;
  text-transform: uppercase;
  color: var(--ui-color-gray-medium);
  margin-bottom: 5px;
  font-weight: 400 !important;
  text-transform: capitalize;
}
[type="date"] {
  background: var(--ui-color-white) url("/calender.png") 92% 50% no-repeat;
  cursor: pointer;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.shortDates {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.shortDates div {
  width: 33%;
}
.shortDates p {
  color: var(--ui-color-primary-green-dark2);
  font-size: 13px;
  border-bottom: 1px solid var(--ui-color-primary-green-dark2);
  display: inline;
  cursor: pointer;
}

.padLeft:hover {
  color: var(--ui-color-primary-green-dark2);
  cursor: pointer;
}

/* Radio Button for Filter */
// .funnelUl input[type="radio"] {
/* display: none; */
//   appearance: none;
//   outline: none;
//   position: relative;
// }

// .funnelUl input[type="radio"]::before {
//   width: 16px;
//   height: 16px;
//   border-radius: 50%;
//   top: -1.79px;
//   left: -1.1px;
//   position: relative;
//   background-color: var(--ui-color-white);
//   content: "";
//   display: inline-block;
//   z-index: 0;
//   visibility: visible;
//   border: 1px solid var(--ui-color-gray-dark1);
// }

// .funnelUl input[type="radio"]::after {
//   width: 10px;
//   height: 10px;
//   border-radius: 5px;
//   top: 1.2px;
//   left: 2px;
//   position: absolute;
//   background-color: var(--ui-color-white);
//   content: "";
//   display: inline-block;
//   visibility: visible;
//   z-index: 1;
// }

// .funnelUl input[type="radio"]:checked:after {
//   background-color: var(--ui-color-primary-green-dark2);
//   content: "";
//   display: inline-block;
//   position: absolute;
//   visibility: visible;
// }

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  // padding-left: 10px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: var(--ui-color-gray-dark1);
  left: -20px;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--ui-color-primary-green-dark2);
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.overview-tags {
  margin-left: 0px !important;
}
.tags__placeholder {
  width: 100%;

  border: 1px solid var(--ui-color-gray-pale2);
  margin-top: 10px;
  border-radius: 20px;
  padding: 8px;
  display: flex;
  flex-flow: row wrap;
  z-index: 999;
  min-height: 40px;
}

@media only screen and (min-width: var(--desktop-common-width-for-medium-screen-768px)) and (max-width: 1115px) {
  .tags_placeholder {
    width: 80%;
  }
}

.tags__placeholder i {
  // background-color: var(--ui-color-primary-green-dark2);
  border-radius: 50%;
  height: 18px;
  width: 18px;
  text-align: center;
  font-size: 10px;
  margin-top: 0px;
  /* position: absolute; */
  padding-top: 4px;
  margin-left: 7px;
  // color: var(--ui-color-white);
  color: var(--ui-color-primary-green-dark2);
  font-size: 15px;
  cursor: pointer;
}

.activePopup:before {
  color: var(--ui-color-primary-green-dark2) !important;
}

.sidebarHeading {
  color: var(--ui-color-primary-green-dark2);
}
