.content-card__container {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 3px 12px rgb(41 70 103 / 20%);
  top: 68px;
  position: relative;
  width: 1304px;

  padding: 30px 50px;
}
