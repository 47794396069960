.tags-container {
  margin: 35px 0px 0px 0px;
  .select-tags {
    margin-left: -1px;
    margin-right: 1px;
    margin-bottom: 4px;
  }
  &__select_group {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: var(--ui-color-gray-medium);
    padding: 20px 0px 10px 5px;
  }
  &__error {
    font-family: "Proxima Nova";
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: var(--ui-color-red);
    padding-top: 15px;
  }
  &__heading {
    // font-family: "Proxima Nova";
    font-weight: 600;
    font-size: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 24px;
    color: var(--ui-color-gray-medium);
    text-transform: capitalize;
    // margin-top: 25px;
    h2 {
      text-transform: uppercase;
      font-size: 11px !important;
    }
  }
  &__sub-heading {
    font-family: Roboto;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: var(--ui-color-gray-medium);
    // margin-top: 20px;
    margin-bottom: 8px;
    &__choose-tags {
      font-size: 11px;
      margin-left: 2px;
    }
  }
  &__placeholder {
    margin-top: 10px;
    padding: 0px 8px 0px 0px;
    display: flex;
    flex-flow: row wrap;
    z-index: 999;
    min-height: 40px;

    i {
      background-color: var(--ui-color-primary-green-dark2);
      border-radius: 50%;
      height: 23px;
      width: 23px;
      text-align: center;
      font-size: 15px;
      margin-top: 10px;
      padding-top: 4px;
      margin-left: 7px;
      color: var(--ui-color-white);
      cursor: pointer;
    }
  }
  &__dropdown {
    position: relative;
    width: 100%;
    margin-top: 10px;
    padding-bottom: 15px;
    .section-heading {
      margin-top: 15px;
      //padding: 10px 10px 10px 20px;
      font-family: "Proxima Nova";
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: var(--ui-color-gray-dark1);
      cursor: pointer;
      .tag_group_name {
        cursor: pointer;
      }
    }
    i {
      margin-top: 15px;
      padding: 10px 0px 10px 0px;
      color: var(--ui-color-primary-green-dark2) !important;
    }
  }

  &__tag-item {
    position: relative;
    height: 24px;
    background: var(--ui-color-gray-dark1);
    border-radius: 12px;
    margin: 0 8px 0px 0;
    padding: 4px 15px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    .inner-tag {
      margin-right: 15px;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.03em;
      color: var(--ui-color-white);
    }
  }

  .tag {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.03em;
    color: var(--ui-color-gray-dark6);
    background: var(--ui-color-gray-dark1);
    border-radius: 12px;
    padding: 4px 10px 4px 6px;
    margin: 5px;
    cursor: pointer;
    .delete {
      margin-left: 15px;
      font-family: inherit;
      font-size: 13px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .type-tags-container {
    display: flex;
    flex-flow: row wrap;
    //padding-left: 10px;
  }
  .suggested-tag {
    color: var(--ui-color-white);
    background: var(--ui-color-gray-dark1);
    font-size: 11px;
    padding: 5px 10px 5px 10px;
  }
  .no_tags {
    opacity: 50%;
    font-family: "Proxima Nova";
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
  }
  .loading_icon {
    color: var(--color-primary);
    border-radius: 100%;
    text-align: center;
  }

  .loading_icon i {
    font-size: 30px;
  }
}

.expand-collapse-icon {
  cursor: pointer;
  padding-right: 20px;
  text-align: right;
  // background-color: blue;
  // margin-top: -10px;
  margin-top: -10px;
  margin-bottom: 10px;
  .fa {
    padding-top: -10px !important;
  }
}

.selectedTags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  .md_tag {
    margin-bottom: 4px !important;
    cursor: pointer !important;
  }
}
