.access-denied {
  display: flex;
  justify-content: center;
  &__content{
    max-width: 500px;
    min-width: 290px;
    width: 100%;
    position: absolute;

    &__header{
      padding-bottom: 18px;
      border-bottom: 1px solid #F0F0F0;
    }
    .card-message{
      padding-right: 0px;
      padding-left: 0px;
    }

  }
}