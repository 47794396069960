.notata-header__container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 55px;
  background: var(--color-gray-pale);
  z-index: 1002;
  display: flex;
  justify-content: flex-end;
  padding-right: 35px;
  line-height: 60px;
  align-items: center;

  .question_circle {
    margin-right: 5px;    
  }
  .disssable-tour{
    cursor: not-allowed;
    &:hover{
      color: var(--ui-color-gray-dark3);
    }
  }

  @media screen and (max-width: 768px) {
    padding-right: 20px !important;
  }
  & > span {
    margin-left: 26px;
  }
  & > span i {
    color: var(--ui-color-gray-dark3);
    font-size: 21px;
    cursor: pointer;
    &:hover {
      color: var(--color-gray-dark7);
    }
  }

  .link-color {
    color: var(--color-gray-dark7);
  }
  .hide-notification {
    position: absolute;
    right: -90px;
    top: 50px;
    @media screen and (max-width: 769px) {
      display: none;
    }
  }
}

.circle {
  position: relative;
  top: -35px;
  width: 10px;
  height: 10px;
  margin-top: -13px;
  border-radius: 50%;
  background: var(--ui-color-primary-orange-dark4);
  left: 12.6px;
}

.profile {
  display: flex;

  @media screen and (max-width: 768px) {
    display: none !important;
  }
  .profile__name {
    text-transform: capitalize;
  }
  &__picture {
    position: relative;
    top: 16px;
    width: 27px;
    height: 27px;
    color: var(--ui-color-white);
    margin-left: 36px;
    margin-right: 12px;
    border-radius: 50%;
    background: var(--ui-color-gray-dark3);

    &__img {
      position: relative;
      top: 16px;
      height: 27px;
      margin-left: 36px;
      margin-right: 12px;
      width: 27px;
      border-radius: 50%;
      overflow: hidden;
      background: var(--ui-color-gray-dark3);

      & span {
        position: absolute;
        height: 100%;
        width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 2;
        font-family: "Roboto";
        font-weight: 900;
        color: var(--ui-color-white);
      }

      & img {
        width: 110%;
        height: 110%;
        position: absolute;
        top: -5%;
        left: -5%;
      }
    }

    &__dropdown {
      position: absolute;
      background: var(--ui-color-white);
      box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
      border-radius: 5px;
      padding: 10px 30px;
      z-index: 999;
      margin-top: 51px;
      text-align: left;
      text-align: initial;
      margin-left: 28%;

      &__item {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 12px;
        padding-top: 12px;
        padding-bottom: 8px;
        color: var(--ui-color-gray-medium);
        cursor: pointer;
        text-transform: capitalize;
      }
    }
  }

  & a {
    position: absolute;
    top: -17px;
    left: 8px;
    width: 20px;
    height: 20px;
    text-decoration: none;
    color: var(--ui-color-white);
  }

  &__name {
    font-family: "roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    top: 20px;
    height: 30px;
    position: relative;
    color: var(--ui-color-gray-medium);
  }
}

.angle_icon i {
  font-size: 20px;
  font-weight: normal;
  margin-left: 12px;
  margin-top: 19px;
  color: var(--ui-color-primary-green-dark1);
}

.notification_icon_selected {
  color: var(--ui-color-gray-dark1) !important;
}

.drop_down {
  position: fixed;
  right: 20px;
  top: 45px;
  z-index: 9999;
  &__item {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 12px;
    padding-top: 12px;
    padding-bottom: 8px;
    color: var(--ui-color-gray-medium);
    cursor: pointer;
    text-transform: capitalize;
  }
}
