//*** FilterBar Styling ***//
.filter_bar_card {
  background-color: #fff;
  padding: 15px;
  padding-bottom: 0;
  margin-top: 0px;
  border-radius: 2px;
  box-shadow: 0px 3px 12px rgb(41 70 103 / 20%);
  margin-bottom: 10px;
}

.filter_tab_container {
  display: flex;
  overflow-x: scroll !important;
}
.filter_tab {
  font-family: Proxima Nova;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 4px 15px;
  background-color: var(--ui-color-primary-green-dark2);
  border-radius: 15px;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 10px;
  white-space: nowrap;

  span {
    //padding-right: 10px;
    color: #fff;
  }

  &__clear_all {
    background-color: #d3d3d3;
    color: #fff;
    cursor: pointer;
  }

  i {
    font-weight: 500;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5);
  }
}
