// LOGIN
.forgot {
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  &_password {
    text-decoration: none;
    color: #969ba3;
    font-weight: 600;
    &:hover {
      text-decoration: none;
      color: #969ba3;
      font-weight: 600;
    }
  }
}
.signup {
  font-size: 14px;
  text-align: center;
  &_member {
    text-decoration: none;
    color: #969ba3;
    font-weight: 500;
    u {
      font-weight: 600;
    }
    &:hover {
      text-decoration: none;
      color: #969ba3;
      font-weight: 500;
    }
  }
}

// COMMON STYLING
.auth_structure {
  position: relative;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh !important;
  overflow: hidden !important;
  display: flex !important;
  align-items: center;
  font-family: "Proxima Nova", Georgia, sans-serif;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 30px 0;
    flex-direction: column;
  }

  input {
    margin: auto;
    color: var(--ui-color-gray-dark1);
    font-size: 14px;
    caret-color: var(--ui-color-gray-dark1);
    &::placeholder {
      color: var(--ui-color-gray-light1);
    }
  }
  button {
    height: 38px;
    font-size: 13px;
    text-transform: none;
  }
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    width: 340px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: var(--ui-color-gray-medium);
    font-weight: bold;
    margin-left: 0;
    @media (min-width: 900px) and (max-width: 1143px) {
      width: 100%;
    }
    @media screen and (max-width: 401px) {
      width: 300px;
    }
    @media screen and (max-width: 361px) {
      width: 260px;
    }
    &::before,
    &::after {
      content: "";
      flex: 1;
      border-bottom: 1px solid var(--ui-color-gray-pale);
    }

    &:not(:empty)::before {
      margin-right: 0.25em;
    }

    &:not(:empty)::after {
      margin-left: 0.25em;
    }
  }

  &__left {
    width: 45%;
    display: flex;
    margin-left: 10%;
    align-items: center;
    @media screen and (max-width: 900px) {
      width: 100%;
      justify-content: center;
      margin: 0;
      align-items: center;
      height: 100%;
    }
    input {
      max-width: 100%;
      @media (min-width: 1499px) {
      }
    }
    &__under-maintenance {
      padding: 20px;
      h1 {
        font-weight: 700;
        font-family: "Proxima Nova";
      }
      &__msg {
        font-family: "Proxima Nova";
      }
    }
    &__mainContent {
      width: 339px;
      display: flex;
      flex-direction: column;

      @media (min-width: 901px) and (max-width: 1260px) {
        width: 85%;
      }
      @media (min-width: 901px) and (max-width: 1150px) {
        .xx_large {
          width: 100%;
        }
      }
      @media screen and (max-width: 401px) {
        width: 300px;
      }
      @media screen and (max-width: 361px) {
        width: 260px;
      }
      form {
        display: flex;
        flex-direction: column;
        .logoContainer {
          text-align: center !important;
          margin-top: 10px;
          margin-bottom: -10px;

          .logo {
            width: 40px;
            height: 40px;
          }
          h1 {
            padding-top: 25px;
            font-size: 21px;
            font-weight: 600;
            color: var(--ui-color-gray-medium);
          }
        }
        .error {
          color: var(--color-primary);
          font-size: 12px;
          text-align: left;
          position: relative;
          font-family: "Roboto";
          font-weight: 400;
          top: -6px;
          margin: 10px 0 10px 0;
          max-width: 342px;
          @media only screen and (max-width: 768px) {
            text-align: center;
            width: 90%;
            span {
              i {
                display: none;
              }
            }
          }

          &i {
            display: none;
          }
          .span {
            width: 100px;
          }
        }
      }
    }
  }
  &__right {
    width: 55%;
    margin-left: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;

    @media screen and (max-width: 1260px) {
      img {
        width: 145%;
      }
    }
    @media (min-width: 900px) and (max-width: 941px) {
      img {
        width: 220%;
        margin-right: -150px !important;
      }
    }
    @media screen and (max-width: 900px) {
      display: none !important;
      img {
        display: none !important;
      }
    }
  }
}

// Logo container
.logoContainer {
  text-align: center !important;
  margin-top: 10px;
  margin-bottom: -10px;

  .logo {
    position: relative;
    left: 44.25%;
    width: 25px;
    height: 25px;;
  }
  h1 {
    font-size: 21px;
    font-weight: 600;
    color: var(--ui-color-gray-medium);
  }
  p {
    font-size: 15px;
    margin: 25px 0;
    margin-bottom: 45px;
    line-height: 18.05px;
    font-size: 15px;
    color: #969ba3;
  }
}
.span {
  width: 100px;
}

// SOCIALlOGINS PAGE
.go_social {
  display: flex;
  flex-direction: column;
  .primary_color {
    background-color: #53cab2;
    text-transform: lowercasecls;
  }
  button {
    position: relative;
    align-items: center !important;
  }
  p {
    display: block;
    position: absolute;
    text-align: left;
    width: 68% !important;
    font-weight: 700;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    text-transform: none;
    letter-spacing: normal;
    font-size: 13px;
  }
}

// MEDIA QUERIES
@media screen and (max-width: var( --mobile-width-breakpoint-500px)) {
  .mainContent {
    align-items: center;
    justify-content: center;
    width: 85% !important;
    padding-bottom: 20px;
  }
  form {
    width: 100%;
  }
  .go_social {
    width: 100%;
  }
  .go_social button {
    margin-bottom: 15px !important;
  }
  .auth_structure button {
    width: 100% !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
  .auth_structure {
    height: auto !important;
    max-height: auto !important;
    max-height: 100% !important;
  }
  .auth_structure_left form input {
    margin: auto;
    height: 18px;
  }
} ;
