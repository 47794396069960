
      .table_container {
        width: 100%;
          td {
            padding: 10px 10px;
            position: relative;
              &:first-child {
                padding-right: 0;
              }
            }
          thead {
            // white-space: nowrap;
            // font-size: 14px;
            // color: var(--color-gray-medium);
            // font-weight: var(--font-weight-normal);
            // text-transform: uppercase;
            //   td {
            //     padding: 12px 12px;
            //     border-left: 1px solid var(--color-gray-light);
            //       &:first-child {
            //         border-left: none;
            //       }
            //       div {
            //         display: flex;
            //         justify-content: space-between;
            //       }
                //  }
               }
          tbody {
            font-size: 15px;
              tr {
                border-top: 1px solid var(--color-gray-light);
                  &:first-child {
                      border-top: none;
                    }
                 }
             }
          .loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 28px;
              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: var(--ui-color-white);
                opacity: 0.7;
              }
              i {
                color: var(--color-gray-medium);
              }
            }
          .empty_list {
            text-align: center;
            padding: 20px;
            border-top: 1px solid var(--color-gray-light);
            color: var(--color-gray-medium);
            }
          .no_margin {
             margin: 0;
            }
      
          .sort_icon {
            margin-left: 4px;
            cursor: pointer;
            font-size: 14px;
             }
          .background_clicker {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            cursor: pointer;
             }
      
          .actual_content {
                z-index: 1;
                position: relative;
                display: inline-block;
              }
            @media screen and (max-width: 600px) {
              .responsive_sm {
                display: none;
              }
            }
            @media screen and (max-width: 1101px) {
              .responsive_md {
                display: none;
              }
            }
            @media screen and (max-width: 1200px) {
              .responsive_lg {
                display: none;
              }
            }

      }













