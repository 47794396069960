.reciever-sharing-container{
    padding: 20px 30px;
    .user-message{
        font-family: 'Roboto';
        font-size: 24px;
        font-weight: 500;
    }
    .thank-you-text{
        padding: 20px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        text-align:center;
        color:var(--ui-color-gray-medium);
    }
    &__separator-text{
        visibility: hidden;
    }
    &__separator{
        //border-bottom: 1px solid grey;
        border: none;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &__notata-investor{
        font-family: "Roboto";
        font-size: 20px;
        font-weight: bold;
    }
    &__notata-investor-message{
        font-family: "Roboto";
        font-size: 16px;
        margin-top: 20px;   
        margin-bottom: 20px;   
    }
}