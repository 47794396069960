// NEW COMMENT BOX

.comment-box {
  &__commentBox {
    width: 100%;

    .comments {
      width: 100%;

      //max-height: 450px;
      //min-height: 450px;
      //height: 450px;
      height: calc(100vh - 400px);
      max-height: calc(100vh - 400px);

      overflow-y: scroll;
      overflow-x: none;
      padding-right: 0.6rem;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--ui-color-primary-green-dark2) 00;
        border-radius: 4px;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: var(--ui-color-primary-green-dark2);
        }
      }

      .comment {
        &:hover .commentHeadEditDeleteIcon i {
          display: inline-block !important;
        }

        margin-top: 15px;
        padding-bottom: 12px;
        border-bottom: 1px solid var(--ui-color-gray-pale1);

        .editBox {
          .editBoxButtons {
            margin-top: 5px;

            button {
              height: 34px;
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              letter-spacing: 0.03em;
              color: var(--ui-color-white);
              border-radius: 17px;
              padding: 0 15px;

              &:hover,
              &:focus {
                filter: grayscale(5%);
              }
            }

            .editSave {
              background-color: rgba(83, 202, 178, 1);
              border: none;
            }

            .editCancel {
              margin-right: 10px;
              color: rgba(83, 202, 178, 1);
              border: 1px solid var(--ui-color-primary-green-dark2);
              background-color: var(--ui-color-white);
            }
          }
        }

        .commentTime {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          letter-spacing: 0.03em;
          color: var(--ui-color-gray-medium);
          margin-top: 10px;
          margin-left: 29px;
        }

        .commentText {
          font-family: Roboto;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.03em;
          color: var(--ui-color-gray-dark1);
          display: block;
          margin-top: 5px;
          margin-left: 29px;
          overflow-wrap: anywhere;
        }

        .commentHead {
          display: flex;
          align-items: center;

          .commentUserName {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            padding-left: 7px;
            letter-spacing: 0.03em;
            text-decoration-line: underline;
            color: var(--ui-color-gray-medium);
            line-height: 25px;
          }

          img {
            transform: scale(0.75);
          }

          .fa-spinner {
            color: var(--ui-color-primary-green-dark2);
            font-size: 15px;
            margin-left: 7px;
          }

          .commentHeadEditDeleteIcon {
            margin-left: auto;

            i {
              display: none;
              cursor: pointer;
              margin: 0 5px;
              font-size: 14px;
              transition: all 0.1s ease;
              @media screen and (max-width: 768px) {
                display: inline-block;
              }
            }

            i:first-of-type {
              color: var(--ui-color-primary-green-dark2);
              &:hover {
                color: var(--ui-color-primary-green-dark3);
              }
            }

            i:last-of-type {
              color: var(--ui-color-primary-orange-dark2);
              &:hover {
                color: var(--ui-color-primary-orange-dark3);
              }
            }

            .deleteIcon {
              font-family: "Font Awesome 5 Pro";
            }
          }
        }
      }
    }
  }

  // Comment Text Box
  &__commentInput {
    width: 100% !important;
    display: flex;
    min-height: 40px;
    height: auto;
    padding: 0 15px;
    border-radius: 20px;
    border: 1px solid var(--ui-color-gray-dark7);
    margin-top: 15px;
    @media (max-width: 991px) {
      margin-top: 40px;
    }

    textarea {
      width: 100%;
      background: none;
      outline: none;
      border: none;
      resize: none;
      padding-top: 10px;
    }

    textarea::-webkit-scrollbar {
      appearance: none;
      width: 6px;
    }

    textarea::-webkit-scrollbar-thumb {
      background-color: var(--ui-color-primary-green-dark2);
      border-radius: 3px;
    }

    button {
      color: var(--ui-color-primary-green-dark2);
      background: none;
      outline: none;
      border: none;
    }

    .editIcon {
      color: var(--ui-color-primary-green-dark2);
    }
    .placeHolderOpacity {
      opacity: 0.75;
      width: 100% !important;
      outline: none;
      border: none;
      color: #000000;
    }
    .placeHolder {
      font-size: 14px;
      line-height: 17px;
      font-weight: normal;
      font-family: Proxima Nova !important;
      color: #6a6a6a;
      color: var(--ui-color-gray-dark1);
      outline: none;
    }
  }
}
