.notification-menu {

  &__dropdown {
    position: absolute;
    text-align: left;
    text-align: initial;
    max-width: 407px;
    right: 138px;
    @media only screen and(max-width:768px) {
      right: 0px;
    }
    height: auto;
    margin-top: -15px;
    border: 10px solid transparent;
    border-top: 20px solid transparent;
      &__sub_dropdown{
        background: var(--ui-color-white);
        box-shadow: 0px 3px 12px rgb(0 0 0 / 30%);
        border-radius: 5px;
        z-index: 999;
        text-align: left;
        text-align: initial;
        max-width: 407px;
        // min-wi0dth: 300px;
        right: 138px;
        @media only screen and(max-width:768px) {
          right: 0px;
        }
        height: auto;
        padding-bottom: 50px;
        margin-top: -15px;
      }
    .notifications {
          padding-bottom: 10px;
          button{
            margin-bottom: -8px;
          }
      .no_notification{
        margin-top: 50px;
        margin-bottom: 50px;
        color: #969ba3;
        font-size: 14px;
        text-align: center;
        width:350px;
        font-family: "Proxima Nova";
      }
      &::-webkit-scrollbar {
        width: 20px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--ui-color-primary-green-dark2);
        border-radius: 4px;
      }
      padding-right: 12px;
      padding-left: 12px;
      margin-top: 10px;
      ///max-height: calc(80vh - 70px);
      //min-height: calc(80vh - 100px);
      height: auto;
      overflow-x: auto;
      @media only screen and(max-width:768px) {
        padding-left: 15px !important;
        margin-top: 34px;
      }
      .icon {
        position: relative;
        right: 5px;
        bottom: 10px;
        top:-10px;
        // padding-top: 0px;

        i {
          color: var(--ui-color-primary-green-dark2) !important;
          font-size: 25px;
        }
      }
      .notifications-container-new {
        &__notification {
          display: flex;
          @media only screen and(max-width:650px) {
            flex-wrap: wrap;
          }
          // align-items: flex-end;
          // padding-bottom: 20px;
          // padding-top: 10px;
          padding-right: 50px;
          border-bottom: 1px solid var(--ui-color-gray-pale1);

          padding-bottom: 20px;
          img {
            height: 24px;
            width: 24px;
            padding-right: 50px;
          }
          &__comment-text {
            font-family: "Roboto";
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.03em;
            color: var(--ui-color-gray-medium);
            margin-left: 15px;
          }
          &__tags-text {
            font-family: "Roboto";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.03em;
            color: var(--ui-color-gray-medium);
            margin-left: 15px;
          }
          &__text {
            display: flex;
            flex-direction: column;
            padding-top: 5px;
            @media only screen and(max-width:650px) {
              width: 215px;
            }
            margin-left: 15px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.03em;
            position: relative;
            right: 20px;
            color: var(--ui-color-gray-dark1);
            .highlight {
              color: var(--ui-color-primary-green-dark2);
              font-weight: bold;
            }
            &__message {
              width: 290px;
            }
            &__hour-ago {
              font-family: "Roboto";
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 0.03em;
              color: var(--ui-color-gray-medium);
              margin-top: 10px;
            }
          }
          &__actions {
            display: flex;
            margin-top: 10px;
          }
          &__decline-btn {
            height: 11px;
            border: 1px solid var(--ui-color-gray-medium);
            max-width: 81px;
            margin-left: 8px;
            .MuiButton-label {
              font-family: "Proxima Nova";
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 12px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.03em;
              text-transform: uppercase;
              color: var(--ui-color-gray-medium);
            }
          }
          &__join-btn {
            height: 11px;
            background-color: var(--ui-color-primary-green-dark2);
            margin-left: 8px;
            max-width: 90px;
            .MuiButton-label {
              font-family: "Proxima Nova";
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 12px;
              letter-spacing: 0.03em;
              text-transform: uppercase;
              color: var(--ui-color-white);
              .material-icons {
                font-size: 15px;
              }
            }
          }
          .img-src {
            width: 48px;
          }
        }
      }
    }
    &__footer {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--ui-color-primary-green-dark2);
      text-align: right;
      margin-right: 30px;
      margin-top: 20px;
      cursor: pointer;
      i {
        margin-left: 5px !important;
        color: var(--ui-color-primary-green-dark4);
        font-size: inherit !important;
        cursor: pointer;
      }
    }
  }
}
// .icon {
//   // width: 100px;
//   i {
//     color: var(--ui-color-primary-green-dark2) !important;
//     // padding-right: 50px;
//     // position: relative;
//     // right: 40px;
//   }
// }
