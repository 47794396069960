.similar-startups-contianer {
  margin-left: 0px;
  margin-right: 0px;
  &__table-container {
    overflow-x: auto;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
    table {
      width: 100%;
      td:nth-child(1) {
        min-width: 100px !important;
      }
       td:nth-child(2) {
          min-width: 240px;
          display: flex;
        }
        td:nth-child(3) {
          padding-left: 15px;
          // justify-content: space-around;
        }
        td:nth-child(4) {
          margin-top: 10px;
          display: flex;
          // justify-content: space-around;
        }
      .grid-header {
        background: rgba(193, 193, 193, 0.1);
        border-radius: 0px;
        margin-left: 0px;
        margin-right: 0px;
        th {
          font-family: "Proxima Nova";
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          color: var(--ui-color-gray-pale);
          padding: 20px 25px 20px 10px;
          min-width: 130px;
          text-align: left;
        }
      }

      tr:not(:last-child) {
        border-bottom: 1px solid var(--ui-color-gray-pale1);
      }

      tr {
        .company-name {
          min-width: 250px;
          height: 70px;
          .similar_startups_table {
            display: flex;
            align-items: center;
            text-decoration: none;
          }
          .name {
            font-family: "Proxima Nova";
            font-weight: 600;
            font-size: 14px;
            color: var(--ui-color-gray-dark1);
            margin-left: 20px;
            word-break: break-all;
          }
          .icon {
            height: 30px;
            width: 30px;
            background: linear-gradient(
              152.3deg,
              var(--ui-color-primary-orange-medium) 9.47%,
              var(--ui-color-primary-orange-dark1) 93.11%
            );
            border-radius: 50%;
            text-align: center;
            line-height: 30px;
            font-size: 12px !important;
            vertical-align: top;
            color: var(--ui-color-white);
          }
        }
        .tag-placeholder {
          display: flex;
          align-items: center;
          p {
            font-size: 12px;
          }
        }
        .subjective-score {
          font-family: "Proxima Nova";
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: var(--ui-color-primary-green-dark2);
        }
        .last-evaluation {
          font-family: "Proxima Nova";
          font-size: 18px;
          line-height: 22px;
          color: var(--ui-color-gray-dark1);
        }
        .last-eval-description {
          font-family: "Proxima Nova";
          font-size: 10px;
          line-height: 10px;
          color: var(--ui-color-gray-dark1);
          position: absolute;
          margin-left: 5px;
        }
        .updated-date {
          background: var(--ui-color-secondary-yellow);
          border-radius: 10px;
          padding: 5px 12px;
          text-align: center;
          font-family: "Roboto";
          font-size: 9px;
          line-height: 11px;
          letter-spacing: 0.03em;
          color: var(--ui-color-gray-medium);
        }
        .updated-date-tag {
          background: val(--ui-color-secondary-yellow);
          border-radius: 10px;
          padding: 5px;
          text-align: center;
          width: 100px;
        }
        .email {
          font-family: "Proxima Nova";
          font-size: 14px;
          line-height: 17px;
          color: var(--ui-color-gray-dark1);
        }
        .sent {
          font-family: "Roboto";
          font-size: 9px;
          line-height: 11px;
          letter-spacing: 0.03em;
          color: var(--ui-color-gray-medium);
        }
        .share-same-template {
          background: #c4a9fc;
          border-radius: 12px;
          padding: 3px;
          width: 170px;
          cursor: pointer;
          i {
            color: var(--ui-color-white);
            margin-left: 15px;
            font-size: 12px;
          }
          .text {
            font-family: "Proxima Nova";
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            margin-left: 10px;
            color: var(--ui-color-white);
            margin-right: 5px;
          }
        }
        td {
          padding: 14px 25px 13px 2px;
          .tags-container {
            &__tags {
              border: 0px;
              max-width: 150px;
              display: flex;
            }
          }
        }
      }
    }
  }
}

.connection-link {
  cursor: pointer;
}
