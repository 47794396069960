.settingsYour-team-container {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__card {
    max-width: 700px;
  }

  .settingsYour-team {
    &__header {
      font-weight: bold;
      font-size: 16px;
      line-height: 17px;
      color: var(--ui-color-gray-dark1);
    }

    &__icon-container {
      width: 30px;
      padding-right: 0px;
      padding-left: 15px;

      i {
        color: var(--ui-color-primary-orange-dark1);
        cursor: pointer;
        font-size: 14px;
      }
    }

    &__members {
      &__pending {
        margin-top: 40px;
      }

      &__member {
        color: var(--ui-color-gray-dark1);
        &__email {
          font-size: 12px;
          color: var(--ui-color-gray-medium);
        }
      }
    }

    &__pending-invitation {
      margin-top: 44px;
    }

    &__email {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: var(--ui-color-gray-medium);
    }

    &__pending-invite-container {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      &__inputfield {
        width: 60%;
        @media (max-width: 1000px) {
          width: 100%;
        }
      }
      & > button {
        margin-bottom: 17px;
        margin-left: 5px;
        margin-right: 15px;
      }

      @media screen and (max-width: 600px) {
        align-items: flex-start;

        & > button {
          margin-bottom: 0px;
          margin-left: 0px;
        }
      }
    }
  }
}
.delete-member-modal-from-team {
  font-size: 14px;
  line-height: 17px;
  color: var(--ui-color-gray-medium);
  padding-top: 55px;
  padding-left: 20px;
  padding-bottom: 55px;
}
