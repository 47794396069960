.search {
  margin-top: 5px;
  position: relative;
  max-width: 500px;

  .material-icons {
    position: absolute;
    left: 14px;
    color: var(--ui-color-gray-pale2);
    top: 9px;
    z-index: 999;
  }
  .search-spinner {
    position: relative;
    left: 25px;
    z-index: 999;
    color: var(--ui-color-gray-pale2);
    z-index: 999;
    font-size: 15px;

    @media (min-width: 442px) {
      margin-left: -15px;
    }
    @media (max-width: 442px) {
      top: 35px;
      margin-top: -40px;
      left: 12px;
    }
  }
  .search-box {
    height: 36px;
    border: 1px solid var(--ui-color-gray-pale2);
    box-sizing: border-box;
    border-radius: 20px;
    width: 100%;
    padding-left: 40px;
    margin-left: -10px;
    margin-bottom: 5px;
    left: 10px;
    position: relative;
  }

  .search-button {
    position: absolute;
    right: 0;
    border: none;
    outline: none;
    border-radius: 20px;
    background: #53cab2;
    color: white;
    width: 36px;
    height: 36px;
    cursor: pointer;
    top: 0;
  }
}
.reset-to-default {
  font-family: "Roboto";
  font-size: 12px;
  line-height: 14px;
  color: #53cab2;
  margin-top: 18px;
  cursor: pointer;
}
.sort_header {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  &__selected-item {
    align-items: center;
    background: #53cab2;
    border-radius: 16px;
    padding: 8px 20px;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.5);
    margin-right: 10px;
    &__close {
      cursor: pointer;
    }

    &__value {
      color: white;
      margin-left: 3px;
    }
  }
}
.sort_direction {
  border-bottom: 1px solid var(--color-gray-lighter);
  padding-bottom: 60px !important;
}

.sort_container_inner {
  &__select-evaluation {
    @media only screen and(min-width:768px) {
      max-width: 300px;
    }
  }
}

.sort_button {
  width: auto;
  text-transform: inherit !important;
  margin: 2px !important;
  padding: 8px 22px !important;
}
.un_selected_color {
  background: #d3d3d3 !important;
  color: white !important;
  font-size: 12px !important;
  line-height: 14px !important;
  font-family: "Roboto" !important;
  cursor: pointer;
}

.sort_toggle_button {
  width: auto;
  text-transform: inherit !important;
  margin: 2px !important;
}

.sort_label {
  font-family: "Proxima Nova";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--ui-color-gray-medium);
  margin-bottom: 10px;
}
.sort_label_select {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #b5b5b5;
  padding-top: 25px;
  padding-bottom: 8px;
}
//SORTING END

// Startup Card
.group-startup-card {
  padding-left: 80px;
  padding-right: 60px;
  padding-top: 20px;
  position: relative;

  // EvaluationSummaries
  &__score-summaries {
    &__subjective-score {
      height: 130px;
    }
  }
  @media only screen and(max-width:400px) {
    // padding-left: 73px;
    padding-right: 20px;
  }
  @media only screen and(max-width:355px) {
    padding-left: 53px;
    padding-right: 20px;
  }

  // EvaluationDetails & EvaluationSummaries & RequestedEvaluations & YourEvaluations
  &__content-section {
    margin-top: 10px;
    padding-bottom: 15px;
    position: relative;
    cursor: pointer;
    &__icon {
      position: absolute;
      left: -30px;
      color: var(--ui-color-primary-green-dark2);
      top: 0;
    }
    &__title {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;
      color: var(--ui-color-gray-dark1);
      @media only screen and(max-width:768px) {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  &__content-inner {
    margin-bottom: 35px;
    .row {
      .primary_color {
        font-size: 12px !important;
        @media screen and (max-width: 400px) {
          font-size: 10.5px !important;
        }
        @media screen and (max-width: 319px) {
          font-size: 8.5px !important;
        }
      }
    }
    .request_evaluation_btn {
      .primary_color {
        margin-left: 15px;
        @media screen and (max-width: 319px) {
          margin-left: 5px;
        }
      }
    }
    .req_evaluation {
      @media (max-width: 400px) {
        font-size: 11px !important;
      }
      @media (max-width: 379px) {
        font-size: 10px !important;
      }

      margin-left: 11px;
      @media (max-width: 361px) {
        font-size: 9px !important;
        height: 28px;
        max-width: 190px !important;
      }
    }
  }

  // StartupHeader
  &__header {
    font-family: "Proxima Nova";
    margin-top: 30px;
    padding-right: 20px;
    margin-bottom: 25px;
    @media only screen and(max-width:768px) {
      margin-top: 20px;
    }
    &__name {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.04em;
      color: var(--ui-color-gray-dark1);
      border-bottom: 0px;
      display: inline-block;
      position: relative;
      top: -5px;

      @media screen and (max-width: 421px) {
        font-size: 17px;
        display: flex;
        word-break: break-all;
      }
    }
    &__logo {
      position: absolute;
      top: -35px;
      left: -50px;
      .logoss {
        height: 40px;
        width: 40px;
        font-size: 25px;
        margin-left: 15px;
        margin-top: 25px;
        font-weight: 500 !important;
        @media only screen and(max-width:905px) {
          height: 40px;
          width: 40px;
          font-size: 16px;
          margin-top: 3px;
        }
        @media only screen and(max-width:400px) {
          height: 40px;
          width: 40px;
        }
      }
      @media screen and (max-width: 905px) {
        top: -13px;
      }
      @media only screen and(max-width:769px) {
        top: -10px;
        left: -55px;
        .logoss {
          height: 40px;
          width: 40px;
          font-size: 15px !important;
          font-weight: 600 !important;
        }
      }
      @media screen and (max-width: 355px) {
        top: -5px !important;
        left: -38px;
        .logoss {
          height: 29px;
          width: 29px;
          font-size: 13px !important;
          font-weight: bold !important;
        }
      }
    }
    &__one-liner {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: var(--ui-color-gray-medium);
      line-height: 1.7;
      margin-top: 15px;
      @media screen and (max-width: 421px) {
        font-size: 12px;
      }
    }
  }

  &__sharing-options {
    position: absolute;
    top: 20px;
    right: 20px;
    @media only screen and(max-width:490px) {
      display: none;
    }
  }

  // StartupHeader
  &__info-window {
    top: 25px;
    @media only screen and(min-width:900px) {
      width: 485px;
    }
    &__heading {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: var(--ui-color-gray-dark1);
      margin-bottom: 16px;
    }
    &__description {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 20px;
      color: var(--ui-color-gray-medium);
    }
  }

  // YourEvaluations
  &__your-evaluations {
    &__subjective {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #6a6a6a;
      text-transform: uppercase;
      @media only screen and(max-width:1200px) {
        margin-bottom: 15px;
      }
    }
    &__button {
      padding-right: 0px !important;
      button {
        height: 22px !important;
      }
    }
    &__buttons-container {
      @media only screen and(max-width: 1199px) {
        display: none;
      }

      text-align: right;
      @media only screen and(max-width:1200px) {
        text-align: left;
      }
      top: 7px;
      button {
        height: 22px !important;
        font-family: Roboto !important;
        font-weight: bold !important;
        font-size: 12px !important;
        line-height: 18px !important;
        text-align: center;
      }
    }
    &__score-option,
    &__score-option-active {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      font-size: 14px;
      padding-top: 8px;
      border: 1px solid var(--ui-color-gray-dark1);
      color: var(--ui-color-gray-dark1);
      cursor: pointer;
      margin-right: 10px;
    }
    &__score-option-active {
      background: #c4a9fc;
      border: 1px solid #c4a9fc;
      color: var(--ui-color-white);
    }

    &__button {
      text-align: right;
      @media only screen and(max-width: 1200px) {
        display: none;
      }
    }

    &__details-heading {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.08em;
      color: var(--ui-color-gray-medium);
      text-transform: uppercase;
      margin-top: 40px;
    }

    &__name {
      font-family: "Roboto";
      font-size: 16px;
      line-height: 18px;
      color: #6a6a6a;
      i {
        cursor: pointer;
        color: var(--ui-color-primary-green-dark2);
      }
    }

    &__time-stamp {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--ui-color-gray-medium);
      @media only screen and(max-width: 576px) {
        display: none;
      }
      @media only screen and (max-width: 768px) {
        position: absolute;
        right: 31%;
      }
    }

    &__score {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: var(--ui-color-gray-medium);

      i {
        cursor: pointer;
        color: var(--ui-color-primary-green-dark2);
        margin-left: 5px;
      }
      @media only screen and (max-width: 400px) {
        position: relative;
        left: -20px;
      }
      @media only screen and (max-width: 768px) and(min-width:400px) {
        position: relative;
        text-align: right;
      }
    }

    &__record {
      padding: 11px;
      margin: 0px !important;
      &__share-with-group {
        cursor: pointer;
        width: 140px;
        text-align: center;
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: var(--ui-color-white);
        background: var(--ui-color-primary-green-dark2);
        padding: 7px 20px;
        border-radius: 14px;
        margin-left: auto;
        @media only screen and(max-width:1268px) {
          margin-top: -5px;
          margin-bottom: 10px;
          margin-left: auto;
        }
        @media only screen and(max-width:768px) {
          margin-top: 10px;
        }
      }
      &:nth-child(odd) {
        background-color: rgb(223 233 245 / 20%);
      }
    }

    &__sharing-options {
      margin-top: 25px;
      padding-bottom: 10px;
      padding-left: 28px;
      @media only screen and(min-width: 1200px) {
        display: none;
      }
    }
  }

  // RequestedEvaluation
  &__requested-evaluations {
    .row {
      margin-bottom: 10px;
    }
  }

  // Startup Comments
  &__comment-section {
    margin-top: 10px;
    padding-bottom: 15px;
    position: relative;
    cursor: pointer;

    &__icon {
      position: absolute;
      left: -15px;
      color: var(--ui-color-primary-green-dark2);
    }

    &__heading {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #6a6a6a;
      margin-left: 13px;
      color: var(--ui-color-primary-green-dark4);
      text-transform: uppercase;
    }
  }
}

// Comment box in groups
.cmnt_sm {
  min-height: 100px !important;
  max-height: 0px !important;
}
.cmnt_md {
  min-height: 200px !important;
  max-height: 0px !important;
}
.cmnt_bg {
  min-height: 300px !important;
  max-height: 0px !important;
}
.cmnt_lg {
  min-height: 450px !important;
  max-height: 0px !important;
}
.commentTxt {
  @media screen and (max-width: 350px) {
    margin-left: -15px !important;
  }
}
