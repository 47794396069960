.modal-container {
  z-index: 10001;
  position: relative;
  width: 100%;
}

.content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  outline: 0;
  padding: 20px;
  padding-bottom: 125px;
}

@media screen and (max-width: 500px) {
  .content {
    padding: 10px;
  }
}

.content::-webkit-scrollbar {
  appearance: none;
  background-color: var(--ui-color-white);
  opacity: 0.2;
  width: 8px;
}

.content::-webkit-scrollbar-thumb {
  appearance: none;
  background-color: var(--ui-color-primary-green-dark2);
  border-radius: 4px;
}

.main_content {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 100px;
}

.modal_header {
  border-bottom: 1px solid var(--color-gray-light);
}

.modal_footer {
  margin-left: -2px;
  margin-right: -2px;
  padding-top: 20px;
  text-align: right;
  @media only screen and(max-width:360px) {
    display: flex;
    margin-left: -13px;
    position: relative;
  }

  & > button {
    margin-left: 15px;
    margin-bottom: 5px;
  }
}

.modal_title {
  position: relative;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: var(--ui-color-gray-dark1);
  padding-bottom: 15px;
}

.inner {
  box-sizing: border-box;
  padding: 20px 30px;
  position: relative;
  top: 100px;
  margin: auto;
  padding-bottom: 24px;
  max-width: 620px;
  background: var(--ui-color-white);
  margin-bottom: 100px;
  text-align: left;
  box-shadow: 0px 3px 12px rgb(41 70 103 / 20%);
  border-radius: 8px;
}

.close_modal {
  color: var(--color-primary);
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 17px;
  font-size: 21px;
}

.ghost {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
}

.alert_text {
  font-family: "Proxima Nova";
  color: var(--ui-color-red);
  font-size: 15px;
  float: left;
}

.preview-startup-modal {
  max-width: 1000px !important;
}
