.display-question {


  align-items: baseline;
  padding-top: 15px;
  padding-bottom: 25px;
  @media only screen and(max-width: 500px) {
    flex-direction: column;
  }

  &__label {

    display: flex;
    justify-content: space-between;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.03em;
    margin-bottom: 15px;

    @media only screen and(max-width: 500px) {
      flex-direction: column;
    }

    div {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      white-space: nowrap;
      color: var(--ui-color-gray-medium);
      padding-left: 5px;
      @media only screen and (max-width: 500px) {
        display: none;
      }
    }


  }

  &__answers {

    &__not_answer {
      color: var(--ui-color-gray-pale2);
      padding-top: 10px;
    }

    &__answer {
      font-family: "Proxima Nova";
      font-size: 14px;
      line-height: 1.8;
      padding: 10px 15px !important;
      margin-top: 5px;
      background-color: #fffce3;
      border-left: 1px solid #f7deae;
      //border-radius: 4px;
      overflow-wrap: anywhere;
    }

    &__comment {
      position: relative;
      font-family: "Proxima Nova";
      font-size: 14px;
      line-height: 1.8;
      padding: 10px 15px !important;
      margin-top: 5px;
      background-color: #f5f5f5;
      border-left: 1px solid #d5d5d5;
      //border-radius: 4px;
      overflow-wrap: anywhere;
      margin-top: 5px;

      &__label {
        opacity: 0.4;
      }

      &__value {
        //color: red;
        font-style: italic;
      }
    }

  }


}