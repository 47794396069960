
.blockWidth {
  max-width: auto;
  max-height: 500px;
  background-color: var(--ui-color-red);
  &__contentScroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: 60%;
    &__cardContent > {
      display: flex;
      padding: 10px 0px 0px 10px;
       div,img {
        margin: 1px;
        padding: 4px;
      }
    }
    &__underline{
      border: 1px solid var(--ui-color-gray-pale1);
    }
  }

}

.blocks_text {
  color: var(--ui-color-gray-medium);
}





