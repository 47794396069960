.delete-group-modal-container {
  padding: 60px 25px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--ui-color-gray-dark1);
  .remember {
    font-weight: bold;
    margin-top: 20px;
  }
  .delete-traces {
    margin-top: 25px;
  }
  .leave-modal-options {
    margin-top: 15px;
    margin-left: 20px;
  }
}
