.share-startup-container {
  // padding: 30px;
  margin-top: 0px;
  background-color: red;
  .MuiIconButton-colorSecondary:hover {
    background-color: transparent;
  }
  .share-startup__info {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 30px;
    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .logo-uploader-container {
    display: flex;
    align-items: center;
    padding-left: 20px;

    @media only screen and (max-width: 360px) {
      flex-direction: column;
    }
  }

  .logo-uploader-logo-container {
    margin-right: 15px;
    @media only screen and (max-width: 360px) {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }

  .hidden-file-input {
    label {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        display: inline-block;
        opacity: 0;
        position: absolute;
        z-index: 99;
        cursor: pointer;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  .menu-container {
    background: linear-gradient(
      115.95deg,
      var(--ui-color-primary-green-dark4) 50.04%,
      var(--color-turquoise) 97.03%
    );
    border-radius: 8px;
    padding: 15px 30px 30px 30px;
    margin-top: 50px;
    max-width: 250px;
    min-width: 140px;
    align-self: flex-start;
    @media only screen and (max-width: 768px) {
      display: none;
    }
    li {
      font-family: "Proxima Nova";
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      padding-bottom: 14px;
      padding-top: 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      a {
        color: var(--ui-color-white);
      }
    }
  }
  .startup-details-container {
    margin-top: 50px;

    .camera {
      height: 110px;
      width: 110px;
      border-radius: 50%;
      background-color: var(--ui-color-gray-pale1);
      text-align: center;
      padding-top: 47px;
      color: var(--ui-color-gray-medium);
      overflow: hidden;
    }

    .logo-no-bg {
      height: 110px;
      width: 110px;
      border-radius: 50%;
      overflow: hidden;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: stretch;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .file-uploader-container {
      display: flex;
      align-items: center;

      & > .delete-btn {
        margin-left: 10px;
      }

      @media only screen and(max-width:1000px) {
        flex-direction: column;
        & > .delete-btn {
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }

    .upload-logo-btn,
    .delete-btn {
      width: 148px;
      height: 40px;
      background: var(--ui-color-primary-green-dark2);
      border-radius: 20px;
      border: none;
      font-family: "Proxima Nova";
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      text-transform: uppercase;
      color: var(--ui-color-white);
    }

    .upload-logo-btn {
      &:hover,
      &:focus {
        background-color: var(--ui-color-primary-green-dark3);
      }
    }
    .delete-btn {
      border: 1px solid var(--ui-color-gray-medium);
      background-color: var(--ui-color-white);
      color: var(--ui-color-gray-medium);
    }
    .info-separator {
      @media only screen and(max-width:768px) {
        margin-top: 100px;
      }
    }
    .separator {
      margin: 30px 0;
    }
    .details {
      .heading {
        font-family: "Proxima Nova";
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        align-items: center;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark1);

        @media only screen and(max-width:500px) {
          font-size: 16px;
        }

        .fa {
          color: var(--ui-color-primary-green-dark1);
          margin-right: 13px;
          cursor: pointer;
        }
      }
      .question {
        padding-top: 15px;

        .heading-section {
          padding-bottom: 20px;
          .heading {
            font-family: "Proxima Nova";
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            align-items: center;
            letter-spacing: 0.03em;
            color: var(--ui-color-gray-dark1);

            @media only screen and(max-width:500px) {
              font-size: 16px;
            }

            .fa {
              color: var(--ui-color-primary-green-dark1);
              margin-right: 13px;
            }
          }
          .sub-heading {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.03em;
            color: var(--ui-color-gray-dark1);
            margin-top: 25px;
            padding-left: 0px;
          }
        }

        .description {
          font-family: "Roboto";
          font-size: 13px;
          line-height: 17px;
          letter-spacing: 0.03em;
          color: var(--ui-color-gray-medium);
          margin-top: 3px;
        }
        .textbox {
          margin-top: 0px;
          input,
          textarea {
            background: var(--ui-color-white);
            border: 1px solid var(--ui-color-gray-pale2);
            box-sizing: border-box;
            border-radius: 8px;
            height: 120px;
            max-width: 560px;
            padding-left: 10px;
            font-family: "Proxima Nova" !important;
            font-size: 14px;
            line-height: 17px;
          }
          textarea {
            height: 120px;
            padding-top: 10px;
          }
        }
        .no-of-founders {
          input {
            height: 30px;
            border-radius: 20px;
            max-width: 280px;
          }
          i {
            margin-left: 17px;
            background-color: var(--ui-color-primary-orange-dark1);
            border-radius: 50%;
            height: 18px;
            width: 18px;
            text-align: center;
            font-size: 10px;
            margin-top: 4px;
            position: absolute;
            padding-top: 4px;
            color: var(--ui-color-white);
          }
          .fa-plus {
            background-color: var(--ui-color-primary-green-dark2);
          }
        }
        .comment {
          font-family: "Proxima Nova";
          font-size: 12px;
          line-height: 15px;
          text-transform: capitalize;
          color: var(--ui-color-gray-medium);
          margin-top: 12px;
        }
        .comment-text-area {
          margin-top: 5px;
          textarea {
            height: 50px;
          }
        }
        .terms-condition {
          margin-top: 25px;
          padding-left: 0px;
        }
        .text-box-heading {
          font-family: "Proxima Nova";
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-transform: capitalize;
          color: var(--ui-color-gray-medium);
          margin-top: 12px;
          padding-left: 0px;
        }
        .name-text {
          padding-left: 0px;
          margin-top: 5px;
        }
      }
    }

    .company-name {
      position: relative;
      top: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      border-bottom: 1px solid var(--ui-color-gray-pale1);
      .heading{
        padding-left: 7px;
      }
    }

    .footer {
      .upload-logo-btn,
      .delete-btn {
        margin-top: 50px;
        margin-left: 0px;
      }
    }
    .section-preview {
      .preview-heading {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: capitalize;
        margin-top: 10px;
        color: var(--ui-color-gray-medium);
      }
      .preview-check-heading {
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        text-transform: capitalize;
        color: var(--ui-color-white);
        margin-top: 51px;
        margin-left: 10px;
        img {
          margin-top: -2px;
        }
      }
      .download-cover {
        padding: 30px 25px 25px 30px;
        background: var(--ui-color-gray-pale1);
        border-radius: 8px;
        margin-right: 15px;
        margin-top: 10px;
        @media only screen and (max-width: 768px) {
          height: 90px;
          width: 90px;
          border-radius: 50%;
        }
      }
      .preview_img {
        margin-top: 10px;
        margin-right: 10px;
        border-radius: 8px;
      }
      .preview_img__selected {
        border: 2px solid var(--ui-color-primary-green-dark1);
      }
      .material-icons {
        color: var(--ui-color-primary-green-dark1);
        margin-top: 53px;
        position: absolute;
        cursor: pointer;
      }
    }
    .social-section {
      width: 85%;
      margin-top: 35px;
      .social-profiles-heading {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark1);
        margin-left: 15px;
        position: absolute;
      }
      .connect-btn,
      .disconnect-btn {
        background: var(--ui-color-primary-green-dark2);
        border-radius: 12px;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: var(--ui-color-white);
        padding: 3px 30px 3px 30px;
        border: none;
      }
      .disconnect-btn {
        color: var(--ui-color-gray-medium);
        background-color: var(--ui-color-gray-pale1);
        border: 1px solid var(--ui-color-gray-medium);
        border-radius: 12px;
        max-width: 110px;
      }
      .social-profile {
        height: 60px;
        padding: 14px 14px 14px 0px;
        margin-left: 15px;
        &__gray {
          background-color: var(--ui-color-gray-pale1);
        }
      }
      .profile-link {
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-medium);
        margin-left: 48px;
        margin-top: -10px;
        overflow: hidden;
        height: 18px;
      }
    }
  }
}
#section-1,
#section-2,
#section-3 {
  height: 500px;
}

.import-section-btn {
  background: var(--ui-color-primary-green-dark2);
  height: 30px;
  margin-top: 5px;
  width: 187px;
  padding: 11px 20px 11px 20px !important;
  .MuiButton-label {
    font-size: 12px;
    line-height: 15px;
  }
}
.import-section-btn {
  background: var(--ui-color-primary-orange-dark2);
  @media only screen and(max-width:500px) {
    width: 152px;
  }
}

.btn:hover {
  color: var(--ui-color-white);
}
.btn {
  position: relative;
  top: 0.65vh;
}

.save_sccess {
  font-size: 12px;
  color: var(--ui-color-primary-green-dark2);
}
