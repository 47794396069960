.evaluation-modal {
  display: flex;
  position: relative;
  justify-content: center;

  .evaluation-detail-container {
    padding: 30px 10px 30px 20px;
    width: 100%;

    @media only screen and(max-width:500px) {
      padding: 30px 10px 30px 12px;
    }
    &__heading {
      font-family: "Proxima Nova";
      font-weight: bold;
      font-size: 19px;
      line-height: 23px;
      padding: 0 !important;
      color: var(--ui-color-gray-dark1);
      margin-bottom: 30px;
      i {
        color: var(--ui-color-primary-green-dark2);
        cursor: pointer;
        margin-right: 15px;
        margin-left: -5px;
      }
    }
    &__summary {
      //padding-left: 25px;
      &__template-name {
        font-family: "Proxima Nova";
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        padding: 0 !important;
        color: var(--ui-color-gray-dark1);
      }
      &__template-by {
        font-family: "Proxima Nova";
        font-size: 14px;
        line-height: 18px;
        margin-top: 5px;
        color: var(--ui-color-gray-medium);
      }
      &__heading {
        font-family: "Proxima Nova";
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding: 0 !important;
        margin-top: 30px;
        color: var(--ui-color-gray-dark1);
      }
      &__section {
        display: flex;
        font-family: "Proxima Nova";
        font-size: 15px;
        line-height: 19px;
        // padding: 10px !important;
        padding-left: 20px;
        margin-left: -20px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 5px;
        &__points {
          margin-left: auto;
          //margin-right: 25px;
        }
        &:nth-child(odd) {
          background-color: rgba(223, 233, 245, 0.2);
        }
      }
      &__evaluation-actions {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        @media only screen and(max-width:500px) {
          flex-direction: column;
          align-items: center;
          .delete-permanently-btn {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .detail {
    display: flex;
    justify-content: space-between;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.03em;

    @media only screen and(max-width:500px) {
      flex-direction: column;
    }
  }
  .question__answer {
    font-family: "Proxima Nova";
    font-size: 14px;
    line-height: 1.8;
    padding: 10px !important;
    margin-top: 5px;
    background-color: #fffce3;
    border: 1px solid #f7deae;
    border-radius: 4px;
    overflow-wrap: anywhere;
  }
  .not_answer {
    color: var(--ui-color-gray-pale2);
    padding-top: 10px;
  }
  .question_comment {
    font-family: "Proxima Nova";
    font-size: 14px;
    line-height: 1.8;
    padding: 10px !important;
    margin-top: 5px;
    background-color: #f5f5f5;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    overflow-wrap: anywhere;
    margin-top: 5px;
  }
  .detail-score {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    // line-height: 40px;
    white-space: nowrap;
    color: var(--ui-color-gray-dark1);
    @media only screen and(min-width:500px) {
      padding-left: 5px;
    }
  }
  .detail-list {
    margin-top: 15px;
    &__question {
      align-items: baseline;
      //padding-left: 30px;
      padding-top: 5px;
      padding-bottom: 10px;
      //padding-right: 20px;

      &:nth-child(odd) {
        //background-color: rgba(223, 233, 245, 0.2);
      }
      @media only screen and(max-width:500px) {
        flex-direction: column;
      }
    }
  }
  .detail-type {
    margin-left: -15px;
    margin-top: 25px;
    display: flex;
    align-items: baseline;
    @media only screen and(max-width:500px) {
      flex-direction: column;
    }
    .detail-score {
      margin-left: 15px;
      @media only screen and(min-width:500px) {
        margin-left: auto;
        //margin-right: 25px;
      }
    }
  }
  .detail-heading {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: var(--ui-color-gray-dark1);
    i {
      color: var(--ui-color-primary-green-dark2);
      margin-left: -10px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}
