.card-message {
  border-radius: 20px;
  position: relative;

  &__content {
    border-radius: 5px;
    padding: 20px;
    padding-left: 70px;
    color: var(--ui-color-gray-dark1);
    position: relative;

    .icon {
      position: absolute;
      left: 18px;
      font-size: 35px;
      top: 15px;
    }

    &__title {
      font-size: 18px;
      line-height: 24px;
      position: relative;
      padding-bottom: 15px;
    }

    &__message {
      font-size: 14px;
      line-height: 22px;
    }

    &__children {
      padding-top: 15px;
    }
  }

  &__content.danger {
    background: var(--ui-color-gray-pale);
    border: 1px solid var(--ui-color-primary-orange-pale);
    .icon {
      color: var(--ui-color-primary-orange-pale);
    }
  }
  &__content.error {
    border: 1px solid #fadedd;
    background-color: rgba(255, 236, 235, 0.5);
    .icon {
      color: var(--ui-color-primary-orange-pale);
    }
  }


  &__content.error {
    background: white;
    border: 1px solid var(--color-primary-light);
    .icon {
      color: var(--color-primary-light);
    }
  }


  &__content.success {
    background: #f3fbfe;
    border: 1px solid var(--ui-color-primary-green-pale);
    .icon {
      color: var(--ui-color-primary-green-pale);
    }
  }

  &__content.notice {
    background: #f1fbfe;
    border: 1px solid #b9e7f9;
    .icon {
      color: #b9e7f9;
    }
  }

  &__content.regular {
    background: #fffdee;
    border: 1px solid #f7deb0;
    .icon {
      color: #f7deb0;
    }
  }
}
