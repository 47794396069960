.logo {
  overflow: hidden;
  border-radius: 50%;
  //cursor: pointer;
  color: var(--ui-color-white);
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  font-weight: 600;

  img {
    height: 100%;
    width: 100%;
    margin: -10px;
    top: -1px;
  }

  &__big {
    height: 80px;
    width: 80px;
    font-size: 40px;
  }
  &__medium {
    height: 50px;
    width: 50px;
    font-size: 25px;
    margin-left: 15px;
    margin-top: 25px;
    font-weight: 500 !important;
    @media only screen and(max-width:905px) {
      margin-left: 15px;
      margin-top: 50px;
    }
  }

  &__small {
    top: 16px;
    width: 27px;
    height: 27px;
    margin-right: 12px;
  }
  &__mini {
    height: 30px;
    width: 30px;
    font-size: 12px !important;
    vertical-align: top;
    cursor: pointer;
  }
}

.logo-bg {
  background: linear-gradient(
    152.3deg,
    var(--ui-color-primary-orange-medium) 9.47%,
    var(--ui-color-primary-orange-dark1) 93.11%
  );
}

.green-border {
  border: 2px solid var(--ui-color-primary-green-dark1);

  &:hover {
    border: 2px solid var(--ui-color-primary-green-dark3);
  }
}

.orange-border {
  border: 2px solid var(--ui-color-gray-medium) ;
}
