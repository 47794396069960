.dd_wrapper {
  background: white;
  position: relative;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  user-select: none;
  width: 100% !important;
  .dd_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: default;
    cursor: pointer;
    background: var(--ui-color-white);
    border: 1px solid var(--ui-color-gray-pale2);
    border-radius: 20px;
    font-size: 14px;
    line-height: 17px;
    padding: 11px 22px;
    height: 40px;
    width: 100% !important;
    &:focus {
      outline: none !important;
    }

    i {
      z-index: 99999;
      color: var(--ui-color-gray-medium) !important;
      font-weight: normal;
      font-size: 20px;
    }
    .dd_header_title {
      font-style: normal;
      font-weight: 600;
      z-index: 99999;
      color: var(--ui-color-gray-dark);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .dd_list {
    position: absolute;
    width: 100%;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    background: var(--ui-color-white);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 8px 10px rgb(41 70 103 / 20%);
    left: 0;
    top: 23px;
    padding: 12px 0;
    z-index: 999;
    border-right: 1px solid #cecece;
    border-left: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    @media screen and (max-width: 398px) {
      padding-top: 40px;
    }
    .dd_list_item {
      display: inline-block;
      overflow: hidden;
      width: 100%;
      background: var(--ui-color-white);
      border: 0px;
      cursor: pointer;
      font-size: 14px;
      line-height: 17px;
      font-weight: normal;
      font-family: "Proxima Nova";
      text-align: left;
      padding: 5px 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--ui-color-gray-dark);
      &:nth-child(even) {
        background-color: var(--color-gray-pale);
      }
      &:hover {
        background-color: var(--ui-color-gray-pale1) !important;
        width: 100%;
        left: 0.2px;
      }
    }
  }

  .selected_bg {
    background-color: var(--ui-color-gray-pale1);
  }
}
