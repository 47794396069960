.edit-evaluation {
  max-width: 1200px;
  margin: auto;
}
.edit-evaluation-container__card {
  padding: 30px;

  .edit-evaluation-container {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 35px;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  .menu-container-1 {
    background: linear-gradient(
      115.95deg,
      var(--ui-color-primary-green-dark4) 50.04%,
      var(--color-turquoise) 97.03%
    );
    border-radius: 8px;
    padding: 15px 30px 30px 30px;
    margin-top: 65px;
    max-width: 250px;
    min-width: 160px;
    align-self: flex-start;

    @media only screen and (max-width: 768px) {
      display: none;
    }

    li {
      font-family: "Proxima Nova";
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      padding-bottom: 14px;
      padding-top: 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      a {
        color: var(--ui-color-white);
      }
    }
  }
}

.textbox {
  input,
  textarea {
    background: var(--ui-color-white);
    border: 1px solid var(--ui-color-gray-pale2);
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    max-width: 560px;
    padding-left: 10px;
    font-family: "Proxima Nova" !important;
    font-size: 14px;
    line-height: 17px;
  }
  textarea {
    height: 120px;
    padding-top: 10px;
  }
}
.text-rightSave {
  text-align: right;
  padding-bottom: 10px;
  padding-top: 25px;
}
