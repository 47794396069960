.group-share-container{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  &__markdown {
    padding-top: 20px;
    font-size: 16px;
    img {
      max-width: 100%;
    }
    a {
      color: #337ab7 !important;
    }
    p {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h2 {
      padding: 20px 0px;
    }
  }

  &__no_margin {
    margin: 0px !important;
  }


  &__content{
    max-width: 1040px;
    width:100%;
    @media only screen and(min-width:900px) {
      width:900px;
    }
    &__card{
      &__description{
        font-family: "Roboto";
        font-size: 14px;
        line-height: 27px;
        color: rgba(75, 75, 75, 0.4);
        margin-top: 5px;
      }
      &__tags{
        display: flex;
        margin-top: 10px;
        &__tag{
          border: 0.1px solid #ffc244;
          background: #fbf1dc;
          color: var(--ui-color-gray-dark1);
          padding: 4px 15px;
          border-radius: 15px;
          margin: 1px 3px 0px 0px;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          margin-right: 10px;
        }
      }
      .card-message{
        padding: 0px!important;
        margin-top: 31px;
        &__content{
          padding-left: 10px;
          padding-top: 13px;
          &__message{
            line-height: 27px;
            color: #4B4B4B;
            font-family: "Roboto";
          }
          .material-icons{
            display: none;
          }
        }
      }
    }
    &__view-variants{
      display: flex;
      button{
        color: #C4C4C4!important;
        border-radius: 20px;
        margin-right: 10px;
        padding-right: 25px;
        padding-left: 15px;
      }
      .selected{
        color: #6A6A6A!important;
        border-color: #6A6A6A!important;
      }
    }
    &__startups {
      display: flex;
      flex-wrap: wrap;

      @media only screen and(min-width:500px) {
        margin-left: -6px;
        margin-right: -6px;
      }


      .margin-left-15{
        @media only screen and(min-width:800px) {
          margin-left: 10px;
        }
      }

      &__startup{
        @media only screen and(min-width:500px) {
          width: 50%;
          padding-left: 5px;
          padding-right: 5px;
        }
        @media only screen and(min-width:800px) {
          width: 33.33%;
          padding-left: 5px;
          padding-right: 5px;
        }

        width:100%;


        &__header{
          display: flex;
          padding-bottom: 15px;
          border-bottom: 1px solid #F0F0F0;
          align-items: center;
          cursor: pointer;
          .logo{
            margin-right: 12px;
            font-size: 18px!important;
          }
        }
        &__description {
          margin-top: 10px;
          color:#969BA3;
          font-family: "Roboto";
          font-size: 14px;
          line-height: 23px;
          height: 131px;
          overflow: hidden;
          //letter-spacing: 0.03em;
          position: relative;

          @media only screen and(min-width:500px) {
            &:before {
              content: "";
              position: absolute;
              width: 100%;
              bottom: 0px;
              left: 0px;
              height: 15px;
              background: linear-gradient(0deg, rgba(255,255,255,1), rgba(255,255,255,0));
            }
          }


          @media only screen and(max-width:500px) {
            height: auto;
            min-height: 5px;
          }
        }




        &__more-info{
          margin-top:10px;
          width: 100%;
          padding-bottom: 22px;
          text-align: center;
          border-bottom: 1px solid #F0F0F0;
          cursor: pointer;
          color: #53cab2;
          a{
            font-family: "Roboto";
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: #53CAB2!important;
            text-transform: uppercase;
            &:hover{
              text-decoration: none;
            }
          }
        }
        &__button-container{
          margin-top: 15px;
          display: flex;
          button{
            width:100%;
            border-radius: 16px;
            height: 27px;
            font-size: 12px;
            line-height: 14px;
          }
          .outlined-green{
            color: #53CAB2!important;
            border-color:#53CAB2!important;
          }
          button:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
      &__list-view{
        padding: 0px;
        font-family: "Roboto";
        &__header{
          display: flex;
          padding: 13px 24px;
          border-bottom: 1px solid #F0F0F0;
          &__item{
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: #C0C2C5;
            width: 70%;
          }
          .score-header{
            width: 15%;
            text-align: center;
          }
          .evaluate-header{
            width: 15%;
            text-align: center;
          }

          @media screen and (max-width: 500px) {
            .score-header{
              display: none;
            }
            .evaluate-header{
              display: none;
            }
          }

          .ml-auto{
            margin-left: auto;
          }
        }
        &__row{
          padding: 13px 24px;
          font-family: "Roboto";
          display: flex;
          border-bottom: 1px solid #F0F0F0;
          &__startup-container{
            display: flex;
            align-items: center;
            width: 70%;

            @media screen and (max-width: 500px) {
              width: 100%;
            }

            &__startup_logo {
              @media screen and (max-width: 500px) {
                display: none;
              }
            }


            &__startup-name{
              //font-weight: 500;
              font-size: 16px;
              line-height: 16px;
              //text-decoration: underline;
              color: #6A6A6A;
              margin-left: 11px;
              cursor: pointer;
            }
          }
          &__buttons-container{
            width:30%;
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: 500px) {
              display: initial;
              width:auto;
            }

            button{
              border-radius: 16px!important;
              height: 27px!important;
              margin-left: 10px;
              margin-right: 10px;
              margin-top: 2px;
              margin-bottom: 2px;
            }
            .outlined-green{
              color: #53CAB2!important;
              border-color:#53CAB2!important;
            }
          }
        }
      }
    }
  }
}
.thank-you-container{
  display: flex;
  justify-content: center;
  &__content{
    max-width: 620px;
    &__header{
      padding-bottom: 18px;
      border-bottom: 1px solid #F0F0F0;
    }
  }
  .card-message{
    padding-left: 0px;
    padding-right: 0px;
  }
  &__button-container{
    .button-style{
      margin-top: 10px;
      button{
        border-radius: 12px;
        height: 27px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}