.NotataButton_container {
  font-family: Proxima Nova !important;
  text-transform: uppercase !important;
  font-size: 11px;
  line-height: 13px;
  border-radius: 0;
  font-weight: 700 !important;
}

.heading_text{
  color:  rgba(255, 255, 255, 0.5);
  text-transform: capitalize;
  font-weight: 700;
  margin-right: 0.3rem;
}
.icon_cancel{
  margin-left: 10px;
  margin-right: -5px;
}
/*margins*/
.mb-md {
  margin-bottom: 15px !important;
}

/*Button Size*/
.xx_large {
  width: 340px;
  height: 40px;
  border-radius: 20px !important;
  font-size: 14px !important;
  line-height: 17px !important;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.x_large {
  width: 250px;
  height: 30px;
  border-radius: 16px !important;
  font-size: 12px !important;
  line-height: 15px !important;
}

.x_large_wide {
  width: 250px;
  height: 40px;
  border-radius: 20px !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.x_large--res {
  width: 250px;
  height: 30px;
  border-radius: 20px !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.large {
  width: 181px;
  height: 30px;
  border-radius: 16px !important;
  font-size: 12px !important;
  line-height: 18px !important;
}

.large1 {
  width: 190px;
  height: 30px;
  border-radius: 16px !important;
  font-size: 12px !important;
  line-height: 15px !important;
}

.big {
  width: 170px;
  height: 30px;
  border-radius: 16px !important;
  font-size: 12px !important;
  line-height: 15px !important;
}
.big_slim {
  width: 150px;
  height: 22px;
  border-radius: 16px !important;
  font-size: 11px !important;
  line-height: 15px !important;
}
.mb-big {
  width: 170px !important;
  height: 30px !important;
  border-radius: 16px !important;
  font-size: 12px !important;
  line-height: 15px !important;
  @media only screen and(max-width:350px) {
    font-size: 10px !important;
  }
}

.big_wide {
  width: 170px;
  height: 40px;
  border-radius: 20px !important;
  font-size: 12px !important;
  line-height: 15px !important;
}

.medium {
  width: 118px;
  height: 30px !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  line-height: 15px !important;
}
.MuiButton-endIcon{
  // margin-left: auto!important;
}
.medium_wide {
  width: 118px;
  height: 40px;
  border-radius: 20px !important;
  font-size: 12px !important;
  line-height: 15px !important;
}

.small_wide {
  width: 106px;
  height: 40px !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  line-height: 12px !important;
}

.small {
  width: 106px;
  height: 30px;
  border-radius: 20px !important;
  font-size: 12px !important;
  line-height: 12px !important;
}

.input_with_button {
  height: 31px;
  border-radius: 20px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  position: absolute !important;
  right: 5px;
  top: 5px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}


.x_small {
  width: 90px;
  height: 30px;
  border-radius: 16px !important;
  font-size: 10px !important;
  line-height: 15px !important;
}

.x_small_slim {
  width: 78px;
  height: 16px;
  border-radius: 12px !important;
  font-size: 10px !important;
  line-height: 12px !important;
  @media screen and (max-width: 400px) {
    width: 48px !important;
    height: 16px !important;
    border-radius: 12px !important;
    font-size: 10px !important;
    line-height: 12px !important;
  }
}

.small1 {
  height: 31px;
  border-radius: 16px !important;
  line-height: 10px !important;
  font-weight: 900;
  padding: 0px;
  border-radius: 50px;
  div {
    font-size: 18px !important;
    padding: 15px;
  }
}
.onlyIcon {
  height: 30px;
  width: 40px !important;
  min-width: 40px !important;
  border-radius: 20px !important;
  padding-left: 18px !important;
}

/*Colors*/
.primary {
  color: var(--ui-color-primary-green-dark2) !important;

  &:hover {
    background: none !important;
    color: var(--ui-color-primary-green-dark3) !important;
  }
}

.primary--dark {
  color: var(--ui-color-primary-green-dark3) !important;
}

.secondary {
  color: var(--ui-color-primary-orange-dark2) !important;

  .x_small_slim {
    width: 78px !important;
    height: 16px !important;
    border-radius: 12px !important;
    font-size: 10px !important;
    line-height: 12px !important;
  }

  /*Colors*/
  .primary {
    color: var(--ui-color-primary-green-dark2) !important;

    &:hover {
      background: none !important;
      color: var(--ui-color-primary-green-dark3) !important;
    }
  }
}

.secondary--dark {
  color: var(--ui-color-primary-orange-dark3) !important;
}

.grey {
  color: var(--ui-color-gray-dark2) !important;

  &:hover {
    background: none !important;
    color: var(--ui-color-gray-dark3) !important;
  }
}

.grey--dark {
  color: var(--ui-color-gray-dark3) !important;
}
.white {
  color: var(--ui-color-white) !important;

  &:hover {
    background: none !important;
    color: var(--ui-color-white) !important;
  }
}

/*colors with background*/
.secondary_color {
  background-color: var(--ui-color-primary-orange-dark2) !important;
  color: var(--ui-color-white) !important;
  &:hover {
    background-color: var(--ui-color-primary-orange-dark3) !important;
  }
}

.secondary_color--dark {
  background-color: var(--ui-color-primary-orange-dark3) !important;
  color: var(--ui-color-white) !important;
}

.primary_color {
  background-color: var(--ui-color-primary-green-dark2) !important;
  color: var(--ui-color-white) !important;
  &:hover {
    background-color: var(--ui-color-primary-green-dark3) !important;
  }
}

.primary_color--dark {
  background-color: var(--ui-color-primary-green-dark3) !important;
  color: var(--ui-color-white) !important;
}

.grey_color {
  background-color: var(--ui-color-gray-dark2) !important;
  color: var(--ui-color-white) !important;
  &:hover {
    background-color: var(--ui-color-gray-dark3) !important;
  }
}

.grey_color--dark {
  background-color: var(--ui-color-gray-dark3) !important;
  color: var(--ui-color-white) !important;
}
.white_color {
  background-color: var(--ui-color-white) !important;
  color: var(--ui-color-gray-medium) !important;
  border: 1px solid var(--ui-color-gray-medium) !important;
}
.white_color i {
  color: var(--ui-color-gray-medium);
}

/* Button Styles */
.responsive-btn {
  padding: 5px 15px !important;
  border-radius: 16px !important;
}

.outlined {
  border: 1px solid var(--ui-color-gray-medium) !important;
  color: var(--ui-color-gray-medium) !important;

  &:hover {
    background: none !important;
  }
}

.text_button {
  font-size: 11px !important;
  padding: 0 !important;
}

.text_button,
.text_button:hover,
.text_button:active {
  background: none !important;
}

.empty {
  color: var(--ui-color-gray-medium) !important;

  &:hover {
    background: none !important;
  }
}

.empty-green {
  color: var(--ui-color-primary-green-dark2) !important;
  text-transform: lowercase !important;
  letter-spacing: 1.5px !important;
  &:hover {
    background: none !important;
    color: var(--ui-color-primary-green-dark3) !important;
  }
}
.auth_social_icon {
  position: absolute;
  width: 70px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

