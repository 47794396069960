// External resources
.external-resources-container{
    margin-top:20px;
    &__resource{
        display: flex;
        font-family: "Proxima Nova";
        font-size: 16px;
        line-height: 19px;        
        padding: 7px;
        margin-left: -4px !important;
        &__resource-label{
            display: flex;
            flex: 10%;
            word-wrap: break-word;
            word-break: break-all;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            color:var(--ui-color-gray-medium)
        }
        &__resource-createdAt{
            margin-left: auto;
            color: var(--ui-color-gray-medium);
            margin-right: 15px;
            @media (max-width:450px) {
            display: none;
            }
        }
        &:nth-child(odd) {
            background: var(--color-gray-pale);
            // background-color: red;
        }
        &__delete{
            color: var(--ui-color-primary-orange-dark2);
            cursor: pointer;
        }
    }
    &__add-new{
        margin-top:20px;
        display: flex;
        button{
            margin-left: auto;
        }
    }
}

// AddExternalResources
.add-external-resource-container{
    .error_input {
        border: 1px solid red;
    }
    .error_msg{
        color: red;
        margin-left: 7px;
    }
    &__heading{
        margin-top: 15px;
        margin-bottom: 5px;
        margin-left: 5px;
        font-family: "Proxima Nova";
        font-size: 16px;
        line-height: 19px;
        font-family: "Roboto";
        font-weight: 500;
        color: var(--ui-color-gray-medium);       
    }
}