.terms-section {

  margin-top: 20px;

  h2 {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 16px;
    font-weight: 900;
  }

  p {
    font-size: 14px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

}