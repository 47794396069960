.startup-card-container {
  &__header {
    display: flex;
    align-items: center;
    margin-left: -15px;
    .logo {
      margin: 0px !important;
      cursor: pointer;
    }
    &__heading {
      margin-left: 13px;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: #6a6a6a;
    }
    &__underline-heading {
      text-decoration: underline;
      cursor: pointer;
    }
    &__add-to-deal-flow {
      margin-left: auto !important;
    }
  }
  .card-message {
    margin-left: 28px;
  }
  &__requested-evaluations {
    margin-bottom: 10px;
    button {
      text-align: center;
      justify-content: center;
    }
  }

  &__description {
    margin-top: 25px;
    font-family: "Roboto";
    font-size: 15px;
    line-height: 1.7em;
    margin-left: 28px;
    color: #6a6a6a;
  }
  &__section_header {
    display: flex;
    margin-top: 25px;
    &__icon {
      color: #53cab2;
      cursor: pointer;
    }
    &__heading {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #6a6a6a;
      margin-left: 13px;
    }
  }
  &__section {
    margin-left: 28px;
    border: 1px solid #c1e6f7;
    margin-top: 11px;
    padding: 25px;
    border-radius: 4px;
    position: relative;
    &__mini-title {
      position: absolute;
      left: 15px;
      top: -8px;
      background: white;
      font-weight: 900;
      font-size: 10px;
      letter-spacing: 0.13em;
      padding-left: 5px;
      padding-right: 5px;
      color: #c1e6f7;
    }
    &__subjective-score-container {
      padding: 11px !important;
    }
    &__your-evals {
      padding: 11px !important;
    }
    &__subjective-score-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f8fbfd;
      padding: 11px;
    }
  }

  &__buttons-container {
    padding-top: 15px;
    display: block;
    position: absolute;
    bottom: 30px;
    button {
      border-radius: 16px !important;
      height: 27px !important;
      width: 150px;
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 10px;
    }
    .outlined-green {
      color: #53cab2 !important;
      border: 1px solid #53cab2 !important;
      background: white;

      &:hover {
        background: white;
      }
    }
    .outlined {
      border-color: #c4a9fc !important;
      color: #c4a9fc !important;
      background: white;
      &:hover {
        background: white !important;
      }
    }
  }
}
