.share-evaluation-container {
  padding: 5px !important;

  .my_evaluation {
    color: var(--ui-color-gray-pale2);
  }
  .req_star {
    color: red;
  }
  &__input-div {
    label {
      margin-left: 0px;
    }
  }
  &__heading {
    color: var(--ui-color-gray-medium);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-bottom: 7px;
    font-weight: 700;
    display: block;
    margin-top: 20px;
  }
  textarea {
    width: 100%;
    border-radius: 10px;
    margin-top: 5px;
    background-color: #fff;
    border-color: lightgray;
    resize: none;
    padding: 15px;
  }
  .text_box_err {
    border: 1px solid red;
  }
  &__sharable-link {
    padding: 20px;
    width: 100%;
    color: #000 !important;
    background: rgba(201, 241, 233, 0.5);
    margin-top: 8px;
    border-radius: 10px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-size: 13px;
    overflow-wrap: anywhere;
    border: 2px solid var(--ui-color-primary-green-pale);
  }
  &__sharable-link a {
    color: #000 !important;
    &:hover {
      text-decoration: none;
    }
  }
  &__copy-link {
    margin-top: 10px;
    justify-content: end;
    margin-top: 14px;
    display: block;
    color: rgba(96, 206, 184, 1);
    font-family: Proxima Nova;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.08em;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    &__copy_text {
      margin-left: 5px;
    }
    &__copy_icon {
      padding-bottom: -10px;
    }
  }
}
.radio_btn {
  margin-top: 10px;
}

.sharable_link {
  &__sharable-link {
    width: 100%;
    width: 500px;
  }
}
