// SYNC MODAL
.sync-modal {
  &__title {
    font-size: 13px;
    font-family: Roboto;
    padding: 20px;
    color: rgb(150 155 163);
  }

  &__record {
    position: relative;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 16px;
    line-height: 18px;
    font-family: "Proxima Nova";
    color: var(--ui-color-gray-medium);
    i {
      color: var(--color-primary);
      position: absolute;
      left: 8px;
      top: 6px;
    }
  }
  &__record:nth-child(even) {
    background: rgba(223, 233, 245, 0.2);
  }

}