.right-sidebar-funnel-filter{
    &__funnel-container{
        border: 1px solid #ccc;
        padding:15px 20px;
        border-radius: 7px;
        margin-bottom: 10px;
        padding-bottom: 20px;
        &__funnel-name{
            font-size: 13px;
            letter-spacing: 0.5px;
            color: var(--ui-color-gray-dark1);
            margin-top: 0px;
            margin-bottom: 10px;
            padding-bottom: 10px;
            
        }
        &__item{
            cursor: pointer !important;
            font-size: 10px;
            padding-left: 20px;
        }
    }
    &__stage-name{
        font-size: 0.8rem;
        margin-top: -1px !important;
        font-weight: 400 !important;
        cursor: pointer !important;
    }
    svg{
        margin-left: 10px !important;
        margin-top: 3px;
        margin-right: 7px;
    }
}
