.loader {

  &__main {
    position: absolute;
    color: var(--ui-color-primary-green-dark2);

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    //top: 14px;
    //left: 15px;
    //width: calc(100% - 15px);
    //height: calc(100% - 14px);

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    z-index: 999;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--ui-color-white);
      opacity: 0.7;
      border-radius: 8px;
    }
  }

  &__with_margin {
    top: 55px;
    left: 0;
    width: 100% !important;
    height: 50% !important;
    &:before {
      top: 55px;
      width: 100% !important;
      height: 50% !important;
    }
  }

  &__no_margin {
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    @media only screen and(max-width:768px) {
      top: 0 !important;
      height: 100% !important;
      width: 100% !important;
    }
  }



  &__large i {
    font-size: 60px;
  }

  &__medium i {
    font-size: 40px;
  }

  &__small i {
    font-size: 25px;
  }
}


















//&__green_spinner_loader {
//  position: absolute;
//  color: var(--ui-color-primary-green-dark2);
//  top: 14px;
//  left: 15px;
//  width: calc(100% - 15px);
//  height: calc(100% - 14px);
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  font-size: 28px;
//  z-index: 999;
//  &:before {
//    content: "";
//    position: absolute;
//    left: 0;
//    top: 0;
//    width: 100%;
//    height: 100%;
//    background: var(--ui-color-white);
//    opacity: 0.7;
//    border-radius: 8px;
//  }
//}
