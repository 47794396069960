.answer-container {
  // padding: 30px 20px;
  padding: 30px !important;

  padding-bottom: 30px !important;
  &__heading {
    font-family: "Proxima Nova";
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    padding: 0 !important;
    color: var(--ui-color-gray-dark1);
  }
  .question {
    margin-top: 30px !important;

    &__heading {
      font-family: "Proxima Nova";
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      padding: 0 !important;
      overflow-wrap: anywhere;
    }
    &__answer {
      font-family: "Proxima Nova";
      font-size: 14px;
      line-height: 1.8;
      padding: 10px !important;
      margin-top: 5px;
      background-color: #fffce3;
      border: 1px solid #f7deae;
      border-radius: 4px;
      overflow-wrap: anywhere;
    }
  }
}
.not_answer {
  color: var(--ui-color-gray-pale2);
}
