.fav-icon {
  margin-left: 10px;
  font-size: 12px;
}
.discussion-container {
  @media only screen and(min-width:992px) {
    max-width: 400px;
  }
  .card {
    @media (min-width: 992px) and (max-width: 1351px) {
      overflow-y: hidden !important;
    }
  }
}
.discussion-card {
  @media only screen and(min-width:992px) {
    max-width: inherit;
    position: fixed;
  }
  @media (min-width: 992px) and (max-width: 1452px) {
    max-width: 26%;
  }
  @media (min-width: 992px) and (max-width: 1185px) {
    max-width: 25%;
  }

  @media (min-width: 992px) and (max-width: 1050px) {
    max-width: 24%;
  }

  .discussions-contianer {
    @media (max-width: 991px) {
      height: 600px;
    }

    &__heading {
      font-family: "Proxima Nova";
      font-weight: bold;
      font-size: 19px;
      line-height: 23px;
      padding: 0 !important;
      color: var(--ui-color-gray-dark1);
    }
    &__sub-heading {
      font-family: "Proxima Nova";
      font-size: 13px;
      line-height: 15px;
      margin-top: 7px;
      margin-bottom: 10px;
      color: var(--ui-color-gray-medium);
      padding: 0 !important;
    }
    &__disucssions {
      font-family: "Roboto";
      margin: 0 !important;
      margin-top: 20px !important;

      &__discussion {
        margin-top: 20px;
      }
      &__sender {
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.03em;
        text-decoration-line: underline;
        color: var(--ui-color-gray-medium);
        margin-left: 30px;
      }
      &__message {
        font-style: italic;
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-dark1);
        margin-left: 30px;
        margin-top: 5px;
      }
      &__date {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.03em;
        color: var(--ui-color-gray-medium);
        margin-left: 30px;
        margin-top: 5px;
      }
      &__text {
        width: 100%;

        height: 40px;
        padding: 0 15px;
        border-radius: 20px;
        border: 1px solid var(--ui-color-gray-dark7);
      }
      &__send-icon {
        position: absolute;
        right: 40px;
        top: 8px;
        button {
          background-color: transparent;
          outline: none;
          border: none;
        }
      }
      .comment {
        font-family: "FontAwesome";
        font-size: 22px;
        color: rgba(83, 202, 178, 1);
        position: absolute;
        margin-top: 2px;
      }
    }

    .discussions-contianer__disucssions__discussion {
      .editDelete_icons {
        opacity: 0;
        transition: all 0.1s ease-in;
        margin-left: auto;
      }
    }
    .discussions-contianer__disucssions__discussion:hover .editDelete_icons {
      opacity: 1;
    }
  }
  .loader__green_spinner_loader {
    width: calc(100% - 30px);
    height: calc(100% - 30px);
  }
}
.startup-info-container {
  &__card {
    padding: 30px !important;
    padding-right: 50px !important;
    &__info {
      display: flex;
      &__logo {
        margin: 0px !important;
        min-height: 50px !important;
        min-width: 50px !important;
      }
      &__description {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 15px;
        &__name-container {
          display: flex;
          align-items: center;
          .name {
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 23px;
            margin-top: 5px;
            color: var(--ui-color-gray-dark1);
          }
          .last-updated {
            margin-left: auto;
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: var(--ui-color-gray-dark1);
            .last-updated-date {
              font-weight: normal;
              color: var(--ui-color-primary-orange-dark3);
            }
          }
        }
        p {
          font-family: "Roboto";
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.03em;
          color: var(--ui-color-gray-medium);
          margin-top: 1rem;
          margin-bottom: 20px;
        }
        &__slidedeck-container {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: flex-end;

          @media (max-width: 500px) {
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 15px;
            & > .overviewWebsite {
              padding-top: 10px;
            }
          }

          .slide_deck {
            margin-left: -9px;
            @media (max-width: 500px) {
              margin-left: 0px;
            }
          }
          .medium {
            padding-right: 0px;
          }
          .overviewWebsite {
            margin-bottom: -5px;
            @media screen and (min-width: 500px) {
              margin-left: auto;
            }
            a {
              font-family: "Proxima Nova";
              font-size: 13px;
              word-break: break-word;
              font-style: normal;
              font-weight: 600;
              line-height: 12px;
              letter-spacing: 0.08em;
              text-align: left;
              cursor: pointer;
              color: #53cab2;
            }
          }
        }
      }
    }
  }
}

.card-heading {
  font-size: 20px !important;
}
