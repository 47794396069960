.reports-analytics-chart {
  margin-top: 10px;
  max-width: 400px;
  height: 417px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Proxima Nova";
  &__dropDown {
    position: absolute;
    border-radius: 5px;
    z-index: 999;
    text-align: left;
    text-align: initial;
    margin-top: 10px;
    margin-left: 125px;
    cursor: context-menu;
  }
  &__top-bar {
    display: flex;
    margin-bottom: 20px;
    &__tags {
      display: flex;
      margin-top: 10px;
      &__heading {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: var(--ui-color-gray-medium);
      }
      &__select-tag {
        margin-left: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        cursor: pointer;
        color: var(--ui-color-primary-green-dark1);
        i {
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
    &__chart-types {
      margin-left: auto;
      .selected {
        color: var(--ui-color-gray-dark5);
        border-bottom: 1px solid var(--ui-color-gray-dark5);
      }
      img {
        margin-top: 3px;
        margin-right: 5px;
        height: 10px;
        padding-bottom: 1px;
        cursor: pointer;
      }
    }
  }
}
