.evaluation-templates-container {
  @media only screen and(min-width:768px) {
    margin-left: 50%;
    transform: translate(-50%, 5%);
    max-width: 700px;
  }
  &__header {
    display: flex;
    i {
      margin-right: 10px;
      color: var(--ui-color-primary-green-dark2);
      font-size: 24px;
      cursor: pointer;
      @media only screen and(max-width:768px) {
        font-size: 20px;
      }
    }
  }
  &__last-to-header {
    margin-left: auto;
  }
  &__tabs-container {
    margin-top: 50px;
    @media only screen and(max-width:768px) {
      padding-left: 15px;
    }
    .MuiButtonBase-root {
      font-family: "Proxima Nova";
      font-style: normal;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--ui-color-gray-medium);
    }
    .Mui-selected {
      font-weight: bolder;
      font-family: "proxima_nova_altbold";
    }
    .MuiTabs-indicator {
      border-bottom: 4px solid var(--ui-color-gray-medium);
      border-radius: 2px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
  &__data-container {
    cursor: pointer;
    &__data {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 0.3fr;
      align-items: center;
      gap: 10px;
      padding-top: 15px;
      padding-bottom: 15px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--ui-color-gray-pale1);
      }

      .template-name {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: var(--ui-color-gray-dark1);
        @media only screen and(max-width:500px) {
          font-size: 12px;
        }
      }
      .sections,
      .group-name {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: var(--ui-color-gray-medium);
        @media only screen and(max-width:500px) {
          font-size: 12px;
        }
      }
      .group-name {
        @media only screen and(max-width:768px) {
        }
      }
      &__browse {
        color: var(--ui-color-gray-pale2);
        cursor: pointer;
        &__dropdown {
          position: absolute;
          z-index: 999;
          text-align: left;
          left: 0;
          text-align: initial;
          cursor: pointer;
          color: var(--ui-color-gray-pale2);
          cursor: pointer;
        }
        @media only screen and(min-width:768px) {
          text-align: end;
          right: 48px;
        }
        span {
          position: relative;
          cursor: pointer;
          color: var(--ui-color-gray-pale2);
          &:hover {
            color: var(--ui-color-gray-dark5) !important;
          }
        }

        &:hover {
          color: var(--ui-color-gray-dark5);
        }
        .selected {
          color: var(--ui-color-gray-dark5);
        }
        &__eve-drop-dwon {
          position: absolute;
          margin-top: -5px;
          right: 20px;
          @media (max-width: 769px) {
            right: 65px;
          }
          @media (max-width: 764px) {
            right: 88px;
          }
          @media (max-width: 725px) {
            right: 68px;
          }
          @media (max-width: 675px) {
            right: 48px;
          }

          @media (max-width: 581px) {
            right: 35px;
          }
          @media (max-width: 485px) {
            right: 25px;
          }
          @media (max-width: 460px) {
            right: 18px;
          }
          @media (max-width: 380px) {
            right: 10px;
          }
        }
        &__drop-dwon {
          position: absolute;
          z-index: 999;
          text-align: left;
          text-align: initial;
          margin-top: -1px;
          right: -32px;
          @media (max-width: 1100px) {
            right: -33px;
          }
          @media (max-width: 770px) {
            right: -42px;
          }
        }
      }
    }
    &__sectionData {
      display: grid;
      grid-template-columns: 1fr 1fr 0.7fr 0.3fr;
      align-items: center;
      gap: 10px;
      padding-top: 15px;
      padding-bottom: 15px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--ui-color-gray-pale1);
      }

      .template-name {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: var(--ui-color-gray-dark1);
        @media only screen and(max-width:500px) {
          font-size: 12px;
        }
      }
      .sections,
      .group-name {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: var(--ui-color-gray-medium);
        @media only screen and(max-width:500px) {
          font-size: 12px;
        }
      }

      &__browse {
        color: var(--ui-color-gray-pale2);
        cursor: pointer;

        &__dropdown {
          position: absolute;
          z-index: 999;
          text-align: left;
          left: 500px;
          text-align: initial;
          cursor: pointer;
          color: var(--ui-color-gray-pale2);
          cursor: pointer;
        }
        @media only screen and(min-width:768px) {
          text-align: end;
          right: 48px;
        }
        span {
          position: relative;
          cursor: pointer;
          color: var(--ui-color-gray-pale2);
          &:hover {
            color: var(--ui-color-gray-dark5) !important;
          }
        }

        &:hover {
          color: var(--ui-color-gray-dark5);
        }
        .selected {
          color: var(--ui-color-gray-dark5);
        }
        &__drop-dwon {
          position: absolute;
          z-index: 999;
          text-align: left;
          text-align: initial;
          margin-top: -1px;
          margin-left: 32px;
          &__item {
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
            padding-top: 8px;
            padding-bottom: 8px;
            color: var(--ui-color-gray-dark1);
            cursor: pointer;
            .settings,
            .leave {
              display: inline;
              font-size: 12px;
              margin-right: 10px;
            }
            .delete-text {
              color: var(--ui-color-red) !important;
            }
          }
          .leave {
            color: var(--ui-color-red) !important;
          }
        }
      }
    }
    &__actions {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 500px) {
        flex-direction: column;
        margin-top: 15px;
      }

      &__save-cancel {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 500px) {
          margin-top: 15px;
        }
        & button:not(last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.create-template-btn:hover {
  background: var(--ui-color-primary-green-dark3) !important;
}

.section_btn {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  // margin-top: 25px;

  & > button {
    margin-bottom: 10px;
  }

  @media only screen and(max-width:768px) {
    margin-left: 0;
  }
}
