.datepicker-container {
  position: relative;
  // width: 286px;
  right: 0px;

  .date-container {
    position: absolute;
    top: -310px;
    left: 20px;
  }

  .fa-chevron-right {
    margin-left: 25px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 24px;
  }
  &__header {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: var(--ui-color-gray-medium);
    padding-left: 30px;
    margin-top: 30px;
  }

  &__date-time {
    display: inline-flex;
    justify-content: space-between;
    // margin-top: 15px;
    width: 100%;
    margin-right: 20px;
  }
  &__from-to {
    &__header {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: var(--ui-color-gray-medium);
    }
    &__datePicker {
      font-size: 12px;
      line-height: 15px;
      color: var(--ui-color-gray-medium);
      background: var(--ui-color-white) ff;
      border: 1px solid var(--ui-color-gray-pale2);
      box-sizing: border-box;
      border-radius: 20px;
      height: 30px;
      padding: 6px 10px 8px 11px;
      margin-top: 5px;
      min-width: 105px;
      cursor: pointer;
      margin-right: 5px;
      font-size: 10px;
      padding-top: 8px;
      i {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
  .react-datepicker {
    margin-left: -19px !important;
    width: 240px;
    &__month-container {
      padding: 10px 2px;
    }
    &__header {
      background: var(--ui-color-white);
      border-bottom: none;
    }
    &__current-month {
      padding-top: 7px;
      padding-bottom: 13px;
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: var(--ui-color-gray-medium);
    }
    &__day-names {
      border-bottom: 1px solid var(--ui-color-gray-pale2);
    }
    &__day-name {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: var(--ui-color-primary-green-dark1);
      margin-left: 0px;
      margin-right: 0px;
    }
    &__month {
      margin-top: 10px;
    }
    &__week {
      padding-top: 2px;
    }
    &__day {
      padding-bottom: 7px;
      padding-top: 7px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.03em;
      margin-left: 0px;
      margin-right: 0px;
      color: var(--ui-color-gray-dark1);
      border-radius: 0px;
      &--selected {
        background-color: var(--ui-color-primary-green-dark2) !important;
        color: var(--ui-color-white);
        border-radius: 50% !important;
        font-weight: bold;
      }
      &:hover {
        border-radius: 0px;
        background-color: var(--ui-color-primary-green-pale);
      }
      &--keyboard-selected,
      &--in-selecting-range,
      &--in-range {
        border-radius: 0px;
        background-color: var(--ui-color-primary-green-pale);
        &:hover {
          background-color: var(--ui-color-primary-green-pale);
          border-radius: 0px;
        }
      }
      &--range-start,
      &--range-end {
        border-radius: 50%;
        background-color: var(--ui-color-primary-green-dark2) !important;
        color: var(--ui-color-white) ff;
        font-weight: bold;
        &:hover {
          border-radius: 50% !important;
        }
      }
    }
  }
  &__last-day-container {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    &__last-day {
      font-family: "Roboto";
      // font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.01em;
      text-decoration-line: underline;
      color: var(--ui-color-primary-green-dark2);
      margin-top: 10px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.datePicker {
  display: flex;
  align-items: center;
  outline: none;
  font-size: inherit;
  color: inherit;
  background: var(--ui-color-white) ff;
  box-sizing: border-box;
  border: 1px solid var(--ui-color-gray-pale2);
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 2px 4px;
  margin-top: 4px;
  width: 100%;
}
