.admin-container {
  margin: auto;
  max-width: 750px;

  .card {
    padding-left: 30px;
    max-width: 700px;
  }
  .card-heading {
    padding-left: 0px !important;
  }
  &__fromToDate {
    margin: 20px 0;
    h2 {
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: var(--ui-color-gray-medium);
      margin-bottom: 15px;
    }
  }
  .datepicker-container .date-container {
    top: 50px !important;
  }
  &__stats {
    margin-top: 30px;
    &__heading {
      color: var(--ui-color-gray-dark1);
      font-weight: 400;
      font-size: 17px;
      font-family: "Proxima Nova";
      .strong {
        font-weight: bold !important;
      }
    }
    &__value {
      color: var(--ui-color-primary-orange-dark3);
      font-weight: 500;
      font-size: 17px;
      font-family: "Proxima Nova";
      margin-top: 10px;
    }
    &__list {
      &__view {
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.12em;
        text-transform: lowercase;
        color: var(--ui-color-gray-medium);
        margin-top: 15px;
        cursor: pointer;
        i {
          margin-left: -10px;
        }
      }
      &__data {
        margin-top: 15px;
        &__user {
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.12em;
          color: var(--ui-color-gray-medium);
          margin-top: 5px;
          max-width: 600px;
          padding: 10px 5px 10px 5px;
          font-family: "Proxima Nova";
          &:nth-child(odd) {
            background-color: var(--ui-color-gray-pale1);
          }
        }
      }
    }
  }
  &__email {
    margin-top: 25px;
    .input-div {
      .go-btn {
        position: relative;
        left: 55%;
        top: -51px;
        @media only screen and(max-width:860px) {
          position: relative;
          left: 0px;
          top: 4px;
        }
        @media only screen and(max-width:768px) {
          position: relative;
          left: 55%;
          top: -51px;
        }
        @media only screen and(max-width:670px) {
          position: relative;
          left: 0px;
          top: 4px;
        }
      }
    }
  }
}
