/* GENERAL */
.form_container {
  max-width: 500px;
}

.textbox {
  margin-top: 15px;
}

.save-cancel_button__container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

// target
.target{visibility: hidden;}

/* SECTION */

.section_container {
  margin-top: 9px;
  padding: 30px 0px;
  border-bottom: 1px solid var(--ui-color-gray-pale1);

  &-:first-child {
    border-top: 1px solid var(--ui-color-gray-pale1);
  }
}

.section_title {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: var(--ui-color-gray-dark1);

  &__container {
    position: relative;
    display: flex;
    justify-content: start;
    user-select: none;
  }

  &__icon {
    color: var(--ui-color-primary-green-dark1);
    position: relative;
    padding-right: 10px;
    top: 2px;
    cursor: pointer;
  }
}

.section_content {
  padding-top: 20px;
  padding-left: 26px;
}

.section_description {
  font-size: 13px;
  color: var(--ui-color-gray-medium);
}

.section_question_input {
  padding: 10px;
}

/* QUESTIONS */
.question {
  &__container {
    margin-bottom: 50px;
  }

  &__title {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: .03em;
    color: var(--ui-color-gray-dark1);
  }

  &__description {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: var(--ui-color-gray-medium);
    padding-top: 5px;
  }

  &__options {
    font-family: "Proxima Nova";
    font-size: 12px;
    color: var(--ui-color-gray-dark1);
    line-height: 30px;

    & label {
      cursor: pointer;
      user-select: none;
    }
  }
}

/* COMMENTS */
.add-comment {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-top: 15px;
  color: var(--ui-color-gray-medium);
  padding-right: 0px;
  padding-left: 0;

  .textbox {
    input,
    textarea {
      background: var(--ui-color-white);
      border: 1px solid var(--ui-color-gray-pale2);
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      max-width: 400px;
      padding-left: 10px;
      font-family: "Proxima Nova" !important;
      font-size: 14px;
      line-height: 17px;
    }
    textarea {
      height: 50px;
      padding-top: 10px;
    }
  }
}

.comments {
  &__container {
    padding-bottom: 0px;
  }

  &__label {
    position: absolute;
    top: -21px;
    left: 0;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--color-gray-medium);
  }

  &__list {
    border-top: 1px solid var(--color-gray-light);
    padding-top: 14px;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    margin-top: 15px;
  }

  &__item {
    background: var(--color-message-bg);
    display: block;
    padding: 4px 12px;
    border-radius: 7px;
    margin-bottom: 2px;
    white-space: pre-wrap;
  }
  &__icon {
    color: var(--ui-color-primary-green-dark1);
    cursor: pointer;
  }
}
