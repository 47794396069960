.content {
  &__container {
    margin: auto;
    margin-top: 50px;
  }
  &__center_contents {
    display: flex;
    min-height: 100vh;
    margin: auto;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -50px;
    padding: 0px 15px;
  }
  &__contents {
    width: 100%;
  }
}
