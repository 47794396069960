.pin-authenticator-container{
    display: flex;
    justify-content: center;
    &__content{
        max-width: 500px;
        min-width: 290px;
        width: 100%;
        position: absolute;

        &__header{
            padding-bottom: 18px;
            border-bottom: 1px solid #F0F0F0;
        }
        .card-message{
            padding-right: 0px;
            padding-left: 0px;
        }
        &__pin-container{
            display: flex;
            margin-top: 24px;
            justify-content: space-between;
            grid-gap: 20px;
            &__digit{
                border: 1px solid #CECECE;
                width: 100%;
                height: 85px;
                display: flex;
                justify-content: center;
                vertical-align: middle;
                input{
                    font-size: 32px;
                    //width: 28px;
                    border: none;
                    text-align: center;
                    padding-left: 20px;
                    letter-spacing: 1em;
                }
            }
        }
        &__submit{
            display: flex;
            margin-top: 45px;
            .next_button{
                margin-left: auto;
                border-radius: 20px;
            }
        }
        &__member-detail-form{
            margin-top:10px;
            &__field{
                margin-top: 26px;
                &__label{
                    font-family: "Roboto";
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    color: #969BA3;
                    margin-bottom: 8px;
                }
            }
        }
    }
}