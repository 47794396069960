.question-option-show {
  @media only screen and(min-width:768px) {
    margin-top: 70px;
  }
}
.add-section-conatiners {
  display: flex;
  justify-content: center;
  min-height: 90vh;
  margin-bottom: 5rem;
}
.add-section-conatiner {
  //padding: 1rem 2rem 2rem 2rem;
  margin-top: 1rem;
  margin-bottom: 7rem;

  @media only screen and(min-width:900px) {
    // position: absolute;
    // left: calc(calc(100% - 700px) / 2);
    // width: 700px;
    position: absolute;
    left: calc(50% - 350px);
    width: 700px;
  }

  .templateform {
    position: relative;
    margin-top: -25px;
  }

  &__back-template {
    color: var(--ui-color-primary-green-dark2);
    transform: scale(1.4);
    cursor: "pointer";
    left: -15px;
    top: 15px;
    position: absolute;
  }
  .text-container {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
  }
  .text-container1 {
    padding-left: 35px;
  }
  .text-container,
  .text-container1 {
    @media only screen and(max-width:768px) {
      flex-direction: column;
    }

    i {
      position: relative;
      left: -20px;
      top: 32px;

      @media only screen and(max-width:500px) {
      }
    }
    .text-box {
      height: 30px;
      position: relative;
      top: -5px;
      max-width: 100%;
    }
    textarea {
      width: 100%;
      border: 1px solid var(--ui-color-gray-pale2);
      box-sizing: border-box;
      border-radius: 8px;
      background-color: var(--ui-color-white);
      resize: none;
    }
  }
  .question-card-container {
    position: relative;
  }
  .drag-indicator {
    transform: rotate(-90deg);
    color: var(--ui-color-gray-pale2);
    margin-top: 10px;
    cursor: pointer;
  }
  .browse-card {
    position: absolute;
    right: 30px;
    top: 10px;
    color: var(--ui-color-gray-medium);
    cursor: pointer;

    @media only screen and(min-width:768px) {
      display: none;
    }
    &__drop-dwon {
      @media only screen and(min-width:768px) {
        display: none;
      }
      position: absolute;
      background: var(--ui-color-white);
      box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
      border-radius: 5px;
      padding: 17px 25px;
      z-index: 999;
      margin-top: 0px;
      text-align: initial;
      right: 25px;
      min-width: 149px;
      &__item {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        padding-top: 8px;
        padding-bottom: 8px;
        color: var(--ui-color-gray-dark1);
        cursor: pointer;
        .settings,
        .leave {
          display: inline;
          font-size: 12px;
          margin-right: 10px;
        }
        .delete-text {
          color: var(--ui-color-red) !important;
        }
      }
      .leave {
        color: var(--ui-color-red) !important;
      }
    }
  }
  .action-container {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    position: absolute;
    top: 27px;
    right: -30px;
    @media only screen and(max-width:900px) {
      display: none;
    }

    & > span,
    i,
    img {
      margin-bottom: 20px;
      font-size: 20px;
      color: var(--ui-color-gray-medium);
      cursor: pointer;
    }

    .delete {
      color: var(--ui-color-primary-orange-dark2);
      //font-family: "FontAwesome";
    }
  }

  .btn-container {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    text-align: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
    .success-message {
      font-size: 12px;
      color: var(--ui-color-primary-green-dark2);
    }
  }

  .question-card {
    background: var(--ui-color-white);
    box-shadow: 0px 3px 12px rgb(41 70 103 / 20%);
    border-radius: 8px;
    padding-bottom: 25px;
    margin-top: 10px;
    background-color: white;
    .question-container {
      // padding: 25px;

      // @media only screen and(max-width:500px) {
      //   padding: 10px;
      // }

      .text-box {
        height: 30px;
        max-width: 100%;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--ui-color-gray-dark1);
        cursor: text;
      }
      &__tabs {
        // @media only screen and (max-width: 768px) {
        //   margin-left: -30px;
        // }
        @media only screen and(min-width:768px) {
          .MuiTabScrollButton-root {
            display: none;
          }
        }
        .MuiTab-root {
          min-width: 115px;
          .MuiTabs-indicator {
            background-color: var(--ui-color-gray-medium);
          }
        }
        .MuiButtonBase-root {
          font-family: "Proxima Nova";
          font-style: normal;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          color: var(--ui-color-gray-medium);
        }
        .Mui-selected {
          font-weight: bolder;
          font-family: "proxima_nova_altbold";
        }
        .invalid_question {
          font-family: "Proxima Nova";
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.03em;
          color: var(--ui-color-red);
          padding-top: 5px;
        }
      }
      .single-answer-option,
      .multiple-answer-option {
        margin-top: 25px;
        display: grid;
        gap: 10px;
        grid-template-columns: 3fr 1fr;

        @media only screen and(max-width:768px) {
          margin-left: 15px;
          display: block;
        }

        &__inputs {
          margin-right: 25px;
          @media only screen and(max-width:768px) {
            margin-left: 15px;
          }
        }

        &__input {
          margin-left: 25px;
        }

        .incre-decre-icons {
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--ui-color-gray-dark3);
          -ms-user-select: none;
          user-select: none;

          @media only screen and(max-width:768px) {
            margin-left: 15px;
          }

          .points {
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            text-transform: capitalize;
            color: var(--ui-color-gray-medium);
            margin-right: 5px;
          }

          .no-of-points {
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: var(--ui-color-gray-dark1);
          }

          .remove,
          .add {
            margin-right: 5px;
            cursor: pointer;
          }

          .cancel {
            color: var(--ui-color-primary-orange-dark1);
            cursor: pointer;
            margin-right: 5px;
          }
        }
      }

      .multiple-answer-option {
        margin-top: 0px;
        .incre-decre-icons {
          margin-top: 20px;
          @media only screen and(max-width:768px) {
            margin-top: 0;
          }
        }
      }

      .add-option-container {
        @media only screen and(max-width:768px) {
          margin-top: 20px;
          margin-left: 30px;
        }
        .add-option-text {
          margin-top: 11px;
          font-family: "Proxima Nova";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          .add-option {
            text-decoration-line: underline;
            color: var(--ui-color-gray-medium);
            cursor: pointer;
          }
          .or {
            color: var(--ui-color-gray-dark1);
            margin-left: 10px;
            margin-right: 10px;
          }
          .add-other {
            text-decoration-line: underline;
            color: var(--ui-color-primary-green-dark2);
            cursor: pointer;
          }
        }
      }
    }
  }
}

.text-lines-conatiner {
  @media only screen and(max-width:768px) {
    padding-left: 30px;
  }
}

.max-width {
  max-width: 900px;
}

.ccard {
  background: var(--ui-color-white);
  box-shadow: 0px 3px 12px rgb(41 70 103 / 20%);
  border-radius: 8px;
}

.btn-container > button {
  margin-left: 15px;
}

.radioPosition {
  position: relative;
  top: 10px;
}
.checkBoxPosition {
  position: relative;
  top: 16px;
  cursor: text;
}
.back {
  position: relative;
  left: 14.5px;
}
@media only screen and(min-width:768px) {
  .text-container {
    margin: auto;
  }
}
