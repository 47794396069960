// MANAGE TEMPLATES MODALS
.manage-template-container {
  @media only screen and(max-width:768px) {
    // margin-top: 100px;
  }
  .selected-templates {
    @media only screen and(max-width:768px) {
      margin-top: 100px;
    }
  }
  .add-tenmplate {
    @media only screen and(max-width:768px) {
      position: absolute;
      max-width: 300px;
      left: 0px;
    }
    @media only screen and(max-width:350px) {
      position: absolute;
      max-width: 255px;
      left: 13px;
    }
  }
  .heading {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--ui-color-gray-medium);
    margin-top: 25px;
    margin-bottom: 20px;
    @media screen and (max-width:350px) {
      margin-left: 5px;
    }
  }
  .group-templ{
    @media screen and (max-width:350px) {
      margin-left: 5px;
    }
  }
  .add-head{
    margin-left: 5px;

  }
  .group-template {
    margin-top: 15px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: var(--ui-color-gray-dark1);
    .fa-times {
      background-color: var(--ui-color-primary-orange-dark1);
      border-radius: 50%;
      height: 18px;
      width: 18px;
      text-align: center;
      font-size: 10px;
      padding-top: 2.5px;
      margin-right: 10px;
      color: var(--ui-color-white);
      cursor: pointer;
      font-size: 13px;
    }
    .fa-spin {
      color:var(--ui-color-primary-orange-dark1);
      margin-top: 3px;
      margin-right: 10px;
    }
  }
}